<template>
<div>
    <form @submit.prevent="handleFilter">
      <!-- #inline style -->
      <select style="font-size: 18px;" v-model="filter">
        <option value="title">Title</option>
        <option value="date">Recent</option>
        <option value="length short">Short Video Length</option>
        <option value="length long">Long Video Length</option>
      </select>
    </form>
   <table>
       
  <thead>
      {{ links }}
    <tr>
      <th scope="col">Ürünün</th>
      <th scope="col">Tıklanma Sayısı</th>
      <th scope="col">Kazandığın Tutar</th>
      <th scope="col">Dönüşüm Oranın</th>
      <th scope="col">TARİH</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-label="Account">
          <div class="col-12">
            <p style="font-size: 10px; margin-bottom: 0px;"><b>Mozaik Nakışlı Havlu 30*50</b></p>
          </div>
          <div class="col-12">
            <img v-bind:src= "links.image_src" width="75" height="75"/>
          </div>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="75" height="75"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>
    <tr>
      <td data-label="Account">
          <img v-bind:src= "links.image_src" width="100" height="100"/>
      </td>
      <td data-label="Due Date">{{ links.click_count }}</td>
      <td data-label="Amount">{{ links.earned_amount }} ₺</td>
      <td data-label="Period">{{ links.conversion_rate }} %</td>
      <td data-label="Period">{{ links.create_date.getDate() + '/' + links.create_date.getMonth() + '/' + links.create_date.getFullYear() }} </td>
    </tr>

  </tbody>
</table>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: "LinkTable",
  components: {

  }, 

data(){
      return {
          links: ''
      }
  },
async created() {
      try{
        if(localStorage.user)
        {
            var model = {}
            model.aff_id = "1"
          //var userID = JSON.parse(localStorage.user);
          //var aff_id = userID.aff_id;//await userService.getWallet(userID.aff_id);
          //var wallet = userID.wallet;
          axios({ method: "GET", "url": "https://ulan-gitlab-heroku-env-staging.herokuapp.com/affwallet/getLinks",
          "params":model, "baseURL": "", "headers": {"content-type": "application/json"} }).then( //
          res => { //  
            this.links = res.data[0]
            this.links.create_date = new Date(this.links.create_date)
            //const dateTimeFormat = new Intl.DateTimeFormat('tr', { year: 'numeric', month: 'short', day: '2-digit' }) 
            //const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat .formatToParts(this.links.create_date)
            //this.links.create_date.month = 

          }
          )
        }
      }catch(err) {
        this.error = err.message;
      }
  },
  
}
</script>

<style scoped>
body {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.content {
  padding: 0 30px 30px;
  min-height: calc(100vh - 123px);
  margin-top: 0px;
}

.content .row-container{
    padding-top: 10px;
}

.card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.75);
  background-color: #FFFFFF;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}


.card .card-body {
  padding: 15px 15px 10px 15px;
}

.card .numbers {
  font-size: 0.8em;
}

.card .card-body.table-full-width {
  padding-left: 0;
  padding-right: 0;
}

.card .card-footer {
  background-color: transparent;
  border: 0;
}

.card[data-background-color="orange"] .card-footer .stats {
  color: #FFFFFF;
}

.card .card-footer .stats i {
  margin-right: 5px;
  position: relative;
  top: 0px;
  color: #66615B;
}


.card-stats .card-body {
  padding: 15px 15px 0px;
}

.card-stats .card-body .numbers {
  text-align: right;
  font-size: 2em;

}

.card-stats .card-body .numbers p {
  margin-bottom: 0;
}

.card-stats .card-body .numbers .card-category {
  color: #9A9A9A;
  font-size: 0.4em;
  line-height: 1.4em;
  font-family: 'Muli', "Helvetica", Arial, sans-serif;
}

.card-stats .card-body .numbers .card-title{
  font-size: 0.8em !important;
  line-height: 1.4em;
  padding-top: 14px;
}


.card-stats .card-footer {
  padding: 0px 15px 15px;
}
 
.card-stats .card-footer .stats {
  color: #9A9A9A;
}

.card-stats .card-footer hr {
  margin-top: 10px;
  margin-bottom: 15px;
}

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px;
}

.card-stats .icon-big i {
  line-height: 59px;
}

.card-category {
    text-transform: capitalize;
    font-weight: 400;
    color: #9A9A9A;
    font-size: 0.7142em;
}

/*body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}*/

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
</style>