 <template>
    <div class="container login_container">
        <div class="section-header">
            <router-link to="/" class="navbar-brand" style="margin-right: 0px; display: flex; align-items: center; justify-content: center;">
                <img src="../assets/linkadoo_logo_mini.png" style="width: 40px; height: 40px; margin-top: 20px;"/>
            </router-link> 
            <div style="text-align: center;">
            <span class="dot red" @click="step=1"></span>
            <span v-if="step < 2" class="dot"></span>
            <span v-else class="dot red" @click="step=2"></span>
            <span v-if="step < 3" class="dot"></span>
            <span v-else class="dot red" @click="step=3"></span>
            </div>
            <h2 v-if="step<3" style="text-align: center; padding-left: 2%;">Linkadoo'ya Kaydol</h2>
            <h2 v-if="step== 3" style="text-align: center; padding-left: 2%;">İlgilendiğin Alanlar</h2>
            <!--<button type= "button" style="display: inline-block; " class="offset-lg-3" v-if="step != 1" @click="prevStep(); return false"> Geri </button>-->

            <p v-if="step== 3" style="text-align: center; padding-left: 2%;">(En çok 3 kategori seçebilirsin.)</p>

        </div>
        <form @submit.prevent="handleSubmit">
            <div style="height:100%;" class="form-group  self-align-right form-container ">
            <section v-if="step == 1">
            <div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <!--<label for="username">Kullanıcı Adı</label>-->
                <input style="text-transform: lowercase" placeholder="Kullanıcı Adı" type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                <div v-show="submitted && !username" class="invalid-feedback">Kullanıcı Adı gerekli</div>
                <div v-if="!username_is_ok" class="invalid-feedback error-div">Bu kullanıcı adı alınmış</div>
            </div>

            
            <div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <!--<label for="email">Email</label>-->
                <ValidationProvider rules="email" v-slot="{ errors }">
                <input placeholder="email" type="email" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" />
                <span style="font-size: 80%; color: #dc3545;">{{ errors[0] }}</span>
                <div v-show="submitted && !email" class="invalid-feedback">Email adresi gerekli</div>
                <div  v-if="!email_is_ok" class="invalid-feedback error-div">E-mail adresi zaten kullanılmakta</div>
                </ValidationProvider>
            </div>

            <div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <!--<label htmlFor="password">Şifre</label>-->
                <ValidationProvider rules="min:6" v-slot="{ errors }">
                <input placeholder="şifre" type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                <span style="font-size: 80%; color: #dc3545;">{{ errors[0] }}</span>
                <div v-show="submitted && !password" class="invalid-feedback">Şifre girmen gerekir</div>
                </ValidationProvider>
            </div>
            </section>

            <section v-if="step == 2">
            <div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <!--<label for="First Name">İsim</label>-->
                <input type="text" placeholder="isim" v-model="firstName" name="firstName" class="form-control" :class="{ 'is-invalid': submitted && !firstName }" />
                <div v-show="submitted && !firstName" class="invalid-feedback">İsim gerekli</div>
            </div>

            <div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <!--<label for="Last Name">Soyisim</label>-->
                <input type="text" placeholder="soyisim" v-model="lastName" name="lastName" class="form-control" :class="{ 'is-invalid': submitted && !lastName }" />
                <div v-show="submitted && !lastName" class="invalid-feedback">Soyisim gerekli</div>
            </div>

            <div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <!--<label for="Last Name">Soyisim</label>-->
                <input type="text" placeholder="Instagram kullanıcı adı" v-model="socialaccount" name="socialaccount" class="form-control" :class="{ 'is-invalid': submitted && !socialaccount }" />
                <div v-show="submitted && !socialaccount" class="invalid-feedback">Sosyal Medya Hesabın gerekli</div>
            </div>
            <div style=" padding-bottom: 8px;" class="col-12 offset-lg-3 col-lg-6 btn-next-wrapper justify-content-start">
                <input type="checkbox" style="background-color: color: rgb(7, 164, 175);" id="consent" v-model="consent" name="consent" value="Consent">
                <label for="consent" style="display: inline; font-size: 12px; padding-left: 5px;"> Duyurulardan haberdar olmak için <router-link target="_blank" to="/iletisim-metni" style="color: rgb(7, 164, 175); cursor: pointer;"> <u> elektronik ileti</u></router-link>  almak istiyorum. </label><br>
            </div>
            </section>

            <section v-if="step == 3">
                <div style="text-align: center; overflow-y: auto; height: 350px;" class="form-group col-12 scrollable">
                    <div v-for="category in categories" v-bind:item = "category" v-bind:index = "category" v-bind:key = "category._id" class="pinterest1">
                        <div class="pinterest2">
                            <div class="pinterest3">
                                <div class="pinterest4">
                                <!--<img style="max-width: 100%;" src="../assets/card_background/home/a.png">-->
                                    <button type= "button" v-on:click="clickCategory(category)" class="pinterest-button">
                                        <div class="category-name">
                                            <div class="category-name1">
                                                <div class="category-name-text-container">
                                                    {{ category.category_name }}
                                                </div>
                                            </div>
                                            <div v-if="category.isClicked" class="check-icon-layer">
                                                <div class="check-icon-container">
                                                    <svg height="24" width="24" class="pinterest-check" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 52 52" xml:space="preserve">
                                                        <path d="M40.495,17.329l-16,18
                                                            C24.101,35.772,23.552,36,22.999,36c-0.439,0-0.88-0.144-1.249-0.438l-10-8c-0.862-0.689-1.002-1.948-0.312-2.811
                                                            c0.689-0.863,1.949-1.003,2.811-0.313l8.517,6.813l14.739-16.581c0.732-0.826,1.998-0.9,2.823-0.166
                                                            C41.154,15.239,41.229,16.503,40.495,17.329z"/>   
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div :style = "{ backgroundImage: 'url(\'' + category.banner_url + '\')' }" class="pinterest5"> <!-- -->
                                            <div v-if="!category.isClicked" class="pinterest-inner">
                                            </div>
                                            <div v-if="category.isClicked" class="pinterest-inner_hover">
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="form-group offset-lg-3 col-12 col-lg-6" style="font-size: 12px;">
            </div>
            </section>
            

            <div class="col-12 offset-lg-3 col-lg-6 btn-next-wrapper justify-content-start" style="text-align: center;">
                <button style="width: 100%;" type= "button" class="btn btn-next" v-if="step != totalsteps" @click="nextStep(); return false"> İlerle</button>
                <p v-if="step == totalsteps" style="text-align: left; font-size: 10px; margin-bottom: 8px">Kayıt olarak <router-link target="_blank" to="/kullanici-sozlesmesi" style="color: rgb(7, 164, 175); cursor: pointer;"> <u> Üyelik Koşullarımızı </u> </router-link> ve <router-link target="_blank" to="/veri-politikasi" style="color: rgb(7, 164, 175); cursor: pointer;"> <u> Veri Politikamızı </u></router-link>kabul etmiş sayılırsınız.</p>
                <button style="width: 100%;" type= "button" class="btn btn-next" v-if="step == totalsteps" @click="handleSubmit"> Bitir </button>
                <img v-show="status.registering" src="../assets/loading.svg"/>
            </div>

            <!--
            <div class="form-group col-12">
                <button v-if="step == totalsteps" class="btn btn-primary kaydol" :disabled="false">Kaydol</button>
                
            </div>-->
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ValidationProvider, extend } from 'vee-validate';
import { min, email } from 'vee-validate/dist/rules';
import axios from 'axios';
//import { userService } from '../_services';
//import { router } from '../router.js';

// Override the default message.
extend('min', {
  ...min,
  message: 'Şifren en az 6 karakter içermeli'
});

extend('email', {
  ...email,
  message: 'Lütfen geçerli bir email giriniz'
});

export default {
    data () {
        return {
            categories: [
                {
                    category_id: 1,
                    category_name: 'Kozmetik & Kişisel Bakım',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fcosmetics.jpg?alt=media&token=ec6aaddc-df96-4c14-9cbe-19576165a662',
                    isClicked: false, 
                },
                {
                    category_id: 2,
                    category_name: 'Moda',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Ffashion.jpg?alt=media&token=86c4194f-2e02-477b-9f00-57bcbe1d0e88',
                    isClicked: false,
                },
                {
                    category_id: 3,
                    category_name: 'Ayakkabı & Çanta',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fshoes_bags.jpg?alt=media&token=fde5b01d-71fb-4811-b7cb-1348d1ed69e9',
                    isClicked: false,
                },
                {
                    category_id: 4,
                    category_name: 'Saat & Takı & Aksesuar',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fwatch_accessories.jpg?alt=media&token=d9f2bc57-e29e-4ff7-a9d6-6ce0f688310d',
                    isClicked: false, 
                },
                {
                    category_id: 5,
                    category_name: 'Elektronik',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Felektronik.jpg?alt=media&token=1aaea9fa-7b9f-44ab-b747-c07262d05e95',
                    isClicked: false, 
                },
                {
                    category_id: 6,
                    category_name: 'Ev & Yaşam',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fhome.jpg?alt=media&token=4e933df7-2506-4063-ac4c-c917f319f607',
                    isClicked: false, 
                },
                {
                    category_id: 7,
                    category_name: 'Spor & Outdoor',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fgas.jpg?alt=media&token=5d15f93e-8edf-41bd-b691-40f5589035ef',
                    isClicked: false, 
                },
                {
                    category_id: 8,
                    category_name: 'Anne & Bebek',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fmom.jpg?alt=media&token=35413602-2fdd-41ff-a421-dd12e6d0c9bb',
                    isClicked: false, 
                },
                {
                    category_id: 9,
                    category_name: 'Kitap & Kırtasiye',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fbooks.jpg?alt=media&token=8d449dc1-4e2b-4aaa-8ccf-09822a54d19a',
                    isClicked: false,
                },
                {
                    category_id: 10,
                    category_name: 'Yapı Market & Bahçe',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fyapi_market_bahce.jpg?alt=media&token=4c8d2ff4-f8d1-4eee-a82e-066eb068c594',
                    isClicked: false,
                },
                {
                    category_id: 11,
                    category_name: 'Ulaşım & Seyahat',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Ftravel.jpg?alt=media&token=ccbd93bd-e24e-4673-be55-e48f1e1b85c9',
                    isClicked: false,
                },
                {
                    category_id: 12,
                    category_name: 'Yemek & Süpermarket',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Ffood.jpg?alt=media&token=7e90803f-f3e6-4b2f-b311-d1cd4f5200ac',
                    isClicked: false,
                },
                {
                    category_id: 13,
                    category_name: 'Evcil Hayvan Ürünleri',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fpet.jpg?alt=media&token=b55b465d-c249-4de9-ac93-1892f2932438',
                    isClicked: false,
                },
                {
                    category_id: 14,
                    category_name: 'Çiçek',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fflower.jpg?alt=media&token=bb18149f-d2e2-4379-8ffa-20c06adcff81',
                    isClicked: false,
                },
                {
                    category_id: 15,
                    category_name: 'Hobi & Tasarım',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fdiy.jpg?alt=media&token=15fcbd2e-e526-4896-82a4-58dc42823769',
                    isClicked: false,
                },
                {
                    category_id: 16,
                    category_name: 'Yurt Dışı Alışveriş',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fabroad.jpg?alt=media&token=2e20ffd5-f048-4d17-899f-ce0c6fd0a8b0',
                    isClicked: false,
                },
                {
                    category_id: 17,
                    category_name: 'Otomotiv',
                    banner_url: 'https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/signup_categories%2Fcar.jpg?alt=media&token=55ed84a7-203d-4560-96e7-0de69a91060d',
                    isClicked: false,
                },
            ],
            
            step: 1,
            totalsteps: 3,
            username: '',
            password: '',
            firstName: '',
            lastName: '',
            socialaccount: '',
            email: '',
            submitted: false,
            category_counter: 0,
            selected_categories: [],
            consent: false,
            agreed_user_agreement: true,
            social_media_accounts: [],
            username_is_ok: true,
            email_is_ok: true,
            referrer_id: "-1",


        }
    },
    components: {
        ValidationProvider
    },
    computed: {
        ...mapState('account', ['status']),
        ...mapState({
            alert: state => state.alert
        })
    },
    created () {      
        this.logout();
    },
    methods: {
        ...mapActions('account', ['register', 'logout']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        handleSubmit () {
            this.submitted = true;
            this.social_media_accounts = [{"domain": "Instagram", "username": this.socialaccount}]
             this.referrer_id = this.$route.query.referrer_id;

            var { username, password, firstName, lastName, email, selected_categories, consent, social_media_accounts, referrer_id} = this;
            username = username.toLowerCase().replace('i̇', 'i'); 

            if (username && password && firstName && lastName && email) {
                this.register({ username, password, firstName, lastName, email, selected_categories, consent, social_media_accounts , referrer_id})
            }
        },
        async nextStep(){
            this.submitted = true;
            var first_page_not_ok = !this.username || !this.email || !this.password
            var second_page_not_ok = !this.firstName || !this.lastName || !this.socialaccount
           
            
            if (this.step == 1 && !first_page_not_ok)
            {

                const url = "https://ulan-gitlab-heroku-env-staging.herokuapp.com"
                let res = await axios.post(url + '/affiliaters/exists', {
                    username: this.username,
                    email: this.email,
                    })
                this.username_is_ok = !JSON.parse(JSON.stringify(res.data)).username
                this.email_is_ok = !JSON.parse(JSON.stringify(res.data)).email

                if (this.username_is_ok && this.email_is_ok){
                    this.submitted = false;
                    this.step++;
                }
            }
            else if (this.step == 2 && !second_page_not_ok)
            {
                this.submitted = false;
                this.step++;
            }

        },
        prevStep: function(){
            this.step--;
        },
        clickCategory: function(category){
            
            if (this.category_counter <= 2 || category.isClicked ){
                    category.isClicked = !category.isClicked;
                if (category.isClicked){
                    this.category_counter++;
                    this.selected_categories.push(category.category_name)
                }
                else{
                    this.category_counter--;
                    var index = this.selected_categories.indexOf(category.category_name);
                    this.selected_categories.splice(index, 1);
                }
            }

        },
        
    }
};
</script>

<style scoped>
.btn-next{
    text-align: center;
    color: #fff;
    background-color: rgb(7, 164, 175);
    border-color: rgb(7, 164, 175);
    border-radius: 30px;

}

.red{
    background-color: rgb(230, 71, 105)!important;
}
.dot{
    margin: 2px;
    height: 8px;
    width: 8px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
.circular-progress{
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

svg {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
          filter: invert(100%);
}
/* signup card text'te z-index=9999 denebilir. text'in tek satır olması için width:100% yapılabilir. */
.check-icon-layer{
    height: 100%;
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 1;

}

.check-icon-container{
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 8px;

}

.pinterest-check{
    display: block;
}

.category-name{
    height: 100%;
    position: absolute;
    width: 100%;
}



.category-name1{
    bottom: 0;
    position: absolute;
    margin: 8px;
    box-sizing: border-box;
    z-index: 1;

}

.category-name-text-container{
    text-align: left;
    font-size: 16px;
    word-wrap: break-word;
    font-weight: 700;
    color: rgba(255,255,255, 1) !important;
}

.pinterest1{
    padding: 4px;
    display: inline-block;
}

.pinterest2{
    border-style: none;
    width: 144px;
    height: 144px;
}

.pinterest3{
    border-radius: 16px;
    width: 144px;
    height: 144px;
    overflow: hidden;
}

.pinterest4{
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 16px;
    

}

.pinterest-button{
    /*background: transparent;*/
    height: 144px;
    width: 144px;
    border: none;
    padding: 0;
    position: relative;
}

.pinterest5{
    /*background-color: rgb(43, 43, 43);*/
    border-radius: 16px;
    width: 144px;
    height: 144px;
    overflow: hidden;
    /*background-image: url('../assets/category_signup/fashion.jpg');*/
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.pinterest-inner{
    background-color: black;
    opacity: .3;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
}

.pinterest-inner:hover{
    opacity: .6;
}

.pinterest-inner_hover{
    background-color: black;
    opacity: .8;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
}


.error-div{
    display: block; 
    padding-left: 10px
}

.form-group{
  padding: 10px;
  padding: 10px;
  margin-bottom: 10px;
}


.container{
    margin-top: 0px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

.kaydol{
    background-color: rgb(7, 164, 175);
    border-color: rgb(7, 164, 175);
    
}

.kaydol:hover, .kaydol:focus, .kaydol:active{
   outline: none !important;
   box-shadow: none;

}
@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>