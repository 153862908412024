<template>
<section class="story-2">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-10 col-sm-6 col-xl-5 offset-xl-1 order-sm-2">
                <div class="content-img">
                    <img src="../../assets/story-2.png">
                </div>
            </div>
            <div class="col-sm-6 col-xl-5 order-sm-1">
                <div class="content-left-content"> 
                    <h2>Share it on any social platform</h2>
                    <p>You can share your dedicated links on any social platform. You can share your links on social media, instant messaging apps or even via e-mail! Your links can be shared on any platform you come into contact with your social circle.</p>
                </div>
            </div>
        </div>
    </div>
</section>
    
</template>

<script>
export default {
    name: "LinkadooStorySecond"
}
</script>

<style scoped>
.story-2{
    background-color: #f8f8f8;
    padding: 30px 0 30px;
}

.content-img{
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-img > img{
    width: 100%;
}
img{
    max-width: 100%;
} 
.content-left-content{
    padding-top: 30px;
    text-align: left;
}
.content-left-content > h2{
        padding-bottom: 25px;
        font-weight: 600;
        letter-spacing: 0px;
        font-size: 30px;
        line-height: 40px;
        font-family: 'Montserrat', sans-serif; 
}
.content-left-content > p{
        font-weight: 500;
        letter-spacing: -0.4px;
        font-size: 18px;
        line-height: 30px;
        font-family: 'Montserrat', sans-serif; 
}
@media (min-width: 576px){
    .content-left-content{
        padding-top: 0px;
    }
    .content-left-content > h2{
        font-size: 26px;
        line-height: 36px;
    }

    .content-left-content > p{
        font-size: 17px;
        line-height: 24px;
    }
}
@media (min-width: 768px){
    .content-left-content > h2{
        font-size: 30px;
        line-height: 44px;
    }

    .content-left-content > p{
        font-size: 21px;
        line-height: 36px;
    }
}
@media (min-width: 992px) {
    .content-left-content{
        padding-top: 60px;
    }
    .content-img{
        padding-bottom: 0px;
    }

    .content-left-content > h2{
        font-size: 38px;
        line-height: 60px;
    }

    .content-left-content > p{
        font-size: 21px;
        line-height: 36px;
    }
}
</style>