<template>
    <div class="container login_container">
        <form @submit.prevent="handleSubmit">
        <div style="height:100%;" class="form-group  self-align-right form-container ">
            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="username">Kullanıcı Adı:</label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="username"><b>Kullanıcı Adı</b></label>

                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                    <input style="text-transform: lowercase; background-color: white;" placeholder="Kullanıcı Adı" type="text" v-model="username" name="username" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !username }" readonly/>
                    <div v-show="submitted && !username" class="invalid-feedback">Kullanıcı Adı gerekli</div>
                    <div v-if="!username_is_ok" class="invalid-feedback error-div">Bu kullanıcı adı alınmış</div>
                </div>
            </div>

            <div class="form-group">
            <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="username">Email:</label>
            <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="username"><b>Email</b></label>
 
                <ValidationProvider rules="email" v-slot="{ errors }">
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                <input placeholder="email" type="email" v-model="email" name="email" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !email }" />
                <span style="font-size: 80%; color: #dc3545;">{{ errors[0] }}</span>
                <div v-show="submitted && !email" class="invalid-feedback">Email adresi gerekli</div>
                <div  v-if="!email_is_ok" class="invalid-feedback error-div">E-mail adresi zaten kullanılmakta</div>
                </div>
                </ValidationProvider>
            </div>

            <!--<div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <label htmlFor="password">Şifre</label>
                <ValidationProvider rules="min:6" v-slot="{ errors }">
                <input placeholder="şifre" type="password" v-model="password" name="password" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !password }" />
                <span style="font-size: 80%; color: #dc3545;">{{ errors[0] }}</span>
                <div v-show="submitted && !password" class="invalid-feedback">Şifre girmen gerekir</div>
                </ValidationProvider>
            </div>-->

            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="username">İsim:</label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="username"><b>İsim</b></label>
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                <input type="text" placeholder="isim" v-model="firstName" name="firstName" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !firstName }" />
                <div v-show="submitted && !firstName" class="invalid-feedback">İsim gerekli</div>
                </div>
            </div>

            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="username">Soyisim:</label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="username"><b>Soyisim</b></label>
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                <input type="text" placeholder="soyisim" v-model="lastName" name="lastName" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !lastName }" />
                <div v-show="submitted && !lastName" class="invalid-feedback">Soyisim gerekli</div>
                </div>
            </div>

            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="username">Instagram Kullanıcı Adı:</label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="username"><b>Instagram Kullanıcı Adı</b></label>
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                <input type="text" placeholder="Instagram kullanıcı adı" v-model="socialaccount" name="socialaccount" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !socialaccount }" />
                <div v-show="submitted && !socialaccount" class="invalid-feedback">Sosyal Medya Hesabın gerekli</div>
                </div>
            </div>

            <div style="padding-bottom: 8px; " class="col-12 offset-lg-3 col-lg-6 btn-next-wrapper justify-content-start">
                <input type="checkbox" style="background-color: color: rgb(7, 164, 175);" id="consent" v-model="consent" name="consent" value="Consent">
                <label for="consent" style="display: inline; font-size: 8px; padding-left: 5px;"> Duyurulardan haberdar olmak için <router-link target="_blank" to="/iletisim-metni" style="color: rgb(7, 164, 175); cursor: pointer;"> <u> elektronik ileti</u></router-link>  almak istiyorum. </label><br>
            </div>
            <div style="height: 100%; display: flex; align-items: center; justify-content: center;">
                <div class="col-6 col-lg-3">
                    <button style="width: 100%; font-size: 12px;" type= "button" class="btn btn-next" @click="handleSubmit">Güncelle</button>
                </div>
            </div>
        </div>
        </form>
        <hr>
        
        
        <section v-if="step == 1">
        <form @submit.prevent="handleSubmitPassword">
        <div style="height:100%;" class="form-group  self-align-right form-container ">
            <div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <!--<label htmlFor="password">Şifre</label>-->
                <ValidationProvider rules="min:6" v-slot="{ errors }">
                <input placeholder="eski şifren" type="password" v-model="password" name="password" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !password }" />
                <span style="font-size: 80%; color: #dc3545;">{{ errors[0] }}</span>
                <div v-show="submitted && !password" class="invalid-feedback">Şifre girmen gerekir</div>
                <div  v-if="!password_is_ok" class="invalid-feedback error-div">Yanlış şifre girdin</div>

                </ValidationProvider>
            </div>
            <div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <ValidationProvider rules="min:6|confirmed:confirmation" v-slot="{ errors }">
                    <input placeholder="yeni şifren" type="password" v-model="new_password" name="new_password" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !password }" />
                    <span style="font-size: 80%; color: #dc3545;">{{ errors[0] }}</span>
                    <span style="font-size: 80%; color: #dc3545;">{{ errors[1] }}</span>

                    <div v-show="submitted && !password" class="invalid-feedback">Şifre girmen gerekir</div>
                </ValidationProvider>
            </div>
            <div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <ValidationProvider vid="confirmation" v-slot="{ errors }">
                    <input placeholder="şifre tekrarı" type="password"  v-model="confirmation" name="confirmation" class="form-control account-update-form"/>
                </ValidationProvider>
            </div>
            <div style="height: 100%; display: flex; align-items: center; justify-content: center;">
            <div class="col-6 col-lg-3">
                <button style="width: 100%; font-size: 12px" type= "button" class="btn btn-next" @click="handleSubmitPassword">Şifremi Güncelle</button>
            </div>
            </div>
        </div>
        
        </form>
        </section>
 
<div id="appModal">
        <!--</section>-->
        <transition name="slide-fade" mode="in-out">
        <div style="display: block;" class="modal fade show" role="dialog" v-if="showModal">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content header-modal">
                    <div class="modal-header ">
                        <p class="modal-title center-text">Şifreni Başarıyla Değiştirdin!</p>
                        <button type="button" class="close shadow-none" @click="showModal = false">&times;</button>
                    </div>
                    <!--<div class="modal-body">
                        <p> Bu ürün linkini tüm çevrenle paylaş, paylaştıkça kazan!!</p>
                    </div>
                    <div class="modal-footer d-flex justify-content-between"> 
                    </div>-->
                </div>
            </div>
        </div>
        </transition>
</div>
        <transition name="slide-fade">
        <div v-if="showModal" class="modal-backdrop fade show" ></div>
        </transition>
        
    
    </div>
</template>

<script>
import axios from 'axios';
import { ValidationProvider, extend } from 'vee-validate';
import { min, email, confirmed } from 'vee-validate/dist/rules';

extend('min', {
  ...min,
  message: 'Şifren en az 6 karakter içermeli'
});

extend('email', {
  ...email,
  message: 'Lütfen geçerli bir email giriniz'
});

extend('confirmed', {
  ...confirmed,
  message: 'Lütfen yukarıda girmiş olduğun şifreyi tekrarla.'
});

export default {
    components: {
        ValidationProvider,
        
    },
    data(){
        return {
            step: 1,
            username: '',
            url: '',
            email: '',
            firstName: '',
            lastName: '',
            socialaccount: '',
            consent: false,
            submitted: false,
            social_media_accounts: [],
            username_is_ok: true,
            email_is_ok: true,
            res: '',
            password: '',
            new_password: '',
            password_is_ok: true,
            showModal: false,
            confirmation: ''
        }
    },
    async created() {
    var userID = JSON.parse(localStorage.user).aff_id;

    this.url = 'https://ulan-gitlab-heroku-env-staging.herokuapp.com/affiliaters/' + userID
    //this.info = await axios.get(this.url + userID)
    
    axios.get(this.url).then(res => { 
        this.res = res.data
        this.username = this.res.username
        this.email = this.res.email 
        this.firstName = this.res.firstName 
        this.lastName = this.res.lastName
        if(this.res.social_media_accounts){ 
            this.socialaccount = this.res.social_media_accounts[0].username
        }
        this.consent = this.res.agreed_mailings
        })
      },
    methods: {
        changeHandler(key, value){
            this.changedict[key] = value
            //this.change_list.push(change_dict)
            //this.change_list.push(this.changedict)
        },
        async handleSubmit () {
            
            const url = "https://ulan-gitlab-heroku-env-staging.herokuapp.com"
            
            if(this.email != this.res.email || this.firstName != this.res.firstName 
            || this.lastName != this.res.lastName || !this.res.social_media_accounts || this.socialaccount != this.res.social_media_accounts[0].username
            ||  this.consent != this.res.agreed_mailings)
            {
                var input_not_ok = !this.username || !this.email || !this.firstName || !this.lastName

                if (this.email != this.res.email){
            let ress= await axios.post(url + '/affiliaters/exists', {
                username: this.username,
                email: this.email,
                })

            this.email_is_ok = !JSON.parse(JSON.stringify(ress.data)).email
                }

            if(!input_not_ok && this.email_is_ok){            
                axios.patch(this.url, {
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                social_media_accounts: [{'domain': 'Instagram', 'username': this.socialaccount}],
                agreed_mailings: this.consent
                }).then(() => { 
                     
                })
            }
            
               }
            
        },
        async handleSubmitPassword (){
            var userID = JSON.parse(localStorage.user).aff_id;
            var check_url = 'https://ulan-gitlab-heroku-env-staging.herokuapp.com/affiliaters/checkPassword'
            axios.post(check_url, {
                aff_id: userID,
                password: this.password,
                }).then(res => {
                    if(res.data.result){
                        axios.post('https://ulan-gitlab-heroku-env-staging.herokuapp.com/affiliaters/password', 
                        {aff_id: userID, 
                        password: this.new_password }).then(() =>{} )
                        this.password_is_ok = true
                        this.showModal = true  
                        this.password = ''
                        this.new_password=''
                        this.confirmation=''
                    }
                    else{
                        this.password_is_ok = false
                    }
                })
        }
    }
    
}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: opacity 0.15s linear;

}
.slide-fade-leave-active {
  transition: opacity 0.15s linear;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {

  opacity: 0;
}
.slide-fade-leave-to{
    opacity: 0;
}


.header-modal{
    background-color: white;
    border-radius: 10px;
    color: #E64769;
}

.modal-header{
    padding: 1.5rem 2.5rem;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    padding-left: 2.5rem;
    border-bottom: 0 none;
}

.modal-footer{
    border-top: 0 none;
    padding-top: 0.5%;
}

.modal-title{
    padding-top: 1rem;
}

.error-div{
    display: block; 
    padding-left: 10px
}

.btn-next{
    text-align: center;
    color: #fff;
    background-color: rgb(7, 164, 175);
    border-color: rgb(7, 164, 175);
    border-radius: 30px;
    line-height: 1;

}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

.account-update-form{
    font-size: 12px;
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-bottom: 0px;
}

</style>