<template>
  <!--<table class="table">
    <thead class="table table-striped">
    <tr>
      <th scope="col">Marka</th>
      <th scope="col">Link</th>
    <th scope="col">Tıklanma <span v-on:click="sort('click')">AAAA</span></th> 
      <th  scope="col">Satış</th>
      <th scope="col">Kazanç</th>
    </tr>
  </thead>

    <tbody>
        <tr v-for="(element, index) in result"
                    v-bind:item = "element['link']"
                    v-bind:index = "index"
                    v-bind:key = "index"
                    >
                    <span v-on:click="bringChild( element['brand'] )">
                        <td>{{ element['brand'] }}</td>
                        <td width="100">{{ element['link'] }}</td>
                        <td>{{ element['click'] }}</td>
                        <td>{{ element['purchase'] }}</td>
                        <td>{{ element['earning'] }}</td>
                    </span>
                           
        </tr>
    </tbody>

    {{ child_is_open }}
</table>-->
  <div
    class="container"
    style="padding-left: 0px; padding-right: 0px; padding-top: 10px"
  >
    <div
      class="col-6 d-md-block"
      style="padding-left: 0px; padding-bottom: 10px"
    >
      <multiselect
        v-model="selected_date_option"
        :searchable="false"
        :close-on-select="true"
        :show-labels="false"
        placeholder="Süre Seçin"
        :options="date_options"
      >
      </multiselect>
    </div>

    <div class="container" style="overflow: auto; font-size: 18px">
      <div
        class="row table-container"
        style="background-color: whitesmoke; border: 1px solid #dcdfe6"
      >
        <div
          class="container"
          style="
            display: flex;
            padding-left: 0px;
            justify-content: space-between;
            align-items: center;
            position: relative;
          "
        >
          <div
            class="col"
            style="
              background-color: whitesmoke;
              padding: 0px;
              position: -webkit-sticky;
              position: sticky;
              left: -15px;
              z-index: 1;
            "
          >
            <span style="padding-left: 5px"> Marka </span>
          </div>
          <div class="col" style="padding: 0px">Link</div>
          <div class="col" style="padding: 0px">
            <span v-on:click="sort('click')" style="cursor: pointer">
              Tıklanma
              <a href="#" class="sort-by"> </a>
            </span>
          </div>
          <div class="col" style="padding: 0px">
            <span v-on:click="sort('click')" style="cursor: pointer">
              Alışveriş
              <a href="#" class="sort-by"> </a>
            </span>
          </div>
          <div class="col" style="padding: 0px">
            <span v-on:click="sort('click')" style="cursor: pointer">
              Kazanç
              <a href="#" class="sort-by"> </a>
            </span>
          </div>
        </div>
      </div>
      <div
        class="row border table-container"
        v-for="(group, index) in result"
        v-bind:item="group"
        v-bind:index="index"
        v-bind:key="index"
      >
        <div
          class="container"
          style="
            display: flex;
            padding-left: 5px;
            justify-content: space-between;
            align-items: center;
            position: relative;
            background-color: white;
          "
          v-if="index % 2 == 0"
          v-on:click="group.ishidden = !group.ishidden"
        >
          <div
            class="col"
            style="
              padding-left: 0px;
              background-color: white;
              font-size: 13px;
              position: -webkit-sticky;
              position: sticky;
              left: -15px;
              z-index: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            <span style="padding-left: 5px">
              {{ group["brand"] }}
            </span>
          </div>
          <div class="col" style="cursor: pointer">
            {{ group["link"] }}
            <i
              v-if="group.ishidden"
              style="font-size: 8px; color: rgb(7, 164, 175)"
              class="fa"
              >&#xf103;</i
            >
            <i v-else style="font-size: 8px; color: rgb(7, 164, 175)" class="fa"
              >&#xf102;</i
            >
          </div>
          <div class="col">
            {{ group["click"] }}
          </div>
          <div class="col">
            {{ group["purchase"] }}
          </div>
          <div class="col">
            {{ group["earning"].toFixed(2) }}
          </div>
        </div>
        <div
          class="container"
          style="
            display: flex;
            padding-left: 5px;
            justify-content: space-between;
            align-items: center;
            position: relative;
            background-color: #eef0f1;
          "
          v-else
          v-on:click="group.ishidden = !group.ishidden"
        >
          <div
            class="col"
            style="
              padding-left: 0px;
              background-color: #eef0f1;
              font-size: 13px;
              position: -webkit-sticky;
              position: sticky;
              left: -15px;
              z-index: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            <span style="padding-left: 5px">
              {{ group["brand"] }}
            </span>
          </div>
          <div class="col" style="cursor: pointer">
            {{ group["link"] }}
            <i
              v-if="group.ishidden"
              style="font-size: 8px; color: rgb(7, 164, 175)"
              class="fa"
              >&#xf103;</i
            >
            <i v-else style="font-size: 8px; color: rgb(7, 164, 175)" class="fa"
              >&#xf102;</i
            >
          </div>

          <div class="col">
            {{ group["click"] }}
          </div>
          <div class="col">
            {{ group["purchase"] }}
          </div>
          <div class="col">
            {{ group["earning"].toFixed(2) }}
          </div>
        </div>
        <div class="container" style="padding: 0px" v-if="!group.ishidden">
          <div
            class="row"
            style="
              margin: 0px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
            "
            v-for="(url, url_index) in children[group['brand']]"
            v-bind:item="url"
            v-bind:index="url_index"
            v-bind:key="url_index"
          >
            <div
              v-if="(index + url_index) % 2 == 1"
              class="container"
              style="margin: 0px; padding-left: 5px; background-color: white"
            >
              <div class="row" style="margin: 0px">
                <div
                  class="col"
                  style="
                    background-color: white;
                    position: -webkit-sticky;
                    position: sticky;
                    left: -15px;
                    z-index: 1;
                  "
                ></div>
                <div
                  class="col"
                  style="
                    padding-left: 0px;
                    font-size: 13px;
                    line-height: 2.5;
                    color: rgb(7, 164, 175);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  <a
                    v-bind:href="url['link']"
                    target="_blank"
                    style="color: rgb(7, 164, 175)"
                  >
                    {{ url["href_link"] }}
                  </a>
                </div>
                <div class="col" style="">
                  {{ url["click"] }}
                </div>
                <div class="col" style="">
                  {{ url["purchase"] }}
                </div>
                <div class="col" style="">
                  {{ url["earning"].toFixed(2) }}
                </div>
              </div>
            </div>
            <div
              class="container"
              v-else
              style="margin: 0px; padding-left: 5px; background-color: #eef0f1"
            >
              <div class="row" style="margin: 0px">
                <div
                  class="col"
                  style="
                    background-color: #eef0f1;
                    position: -webkit-sticky;
                    position: sticky;
                    left: -15px;
                    z-index: 1;
                  "
                ></div>
                <div
                  class="col"
                  style="
                    padding-left: 0px;
                    font-size: 13px;
                    line-height: 2.5;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  <a
                    v-bind:href="url['link']"
                    target="_blank"
                    style="color: rgb(7, 164, 175)"
                  >
                    {{ url["href_link"] }}
                  </a>
                </div>
                <div class="col" style="">
                  {{ url["click"] }}
                </div>
                <div class="col" style="">
                  {{ url["purchase"] }}
                </div>
                <div class="col" style="">
                  {{ url["earning"].toFixed(2) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Multiselect from "vue-multiselect";

var _ = require("lodash");

export default {
  name: "LinkStatistics",
  components: {
    Multiselect,
  },
  data() {
    return {
      date_options: [
        "Son Gün",
        "Son 3 Gün",
        "Son Hafta",
        "Son 2 Hafta",
        "Son Ay",
      ],
      selected_date_option: "Son Hafta",
      days: 0,
      weeks: 1,
      months: 0,
      tabledata: "",
      currentSort: "click",
      currentSortDir: "desc",
      result: {},
      children: {},
      child_is_open: {},
      query: "",
    };
  },
  async created() {
    if (localStorage.user) {
      var userID = JSON.parse(localStorage.user);
      var aff_id = userID.aff_id;

      var linkQuery =
        "?days=" +
        this.days +
        "&weeks=" +
        this.weeks +
        "&months=" +
        this.months +
        "&aff_id=" +
        aff_id;
      this.query = linkQuery;
      const resp = await Axios.get(
        "https://www.yukarikaydir.com/api/v1/linkStatistics/query" + linkQuery
      );
      this.tabledata = resp.data;

      var data = this.tabledata.slice(1);
      var dict_click = {},
        dict_purchase = {},
        dict_earning = {},
        result = [];

      if (data !== "") {
        data.forEach((element) => {
          var new_element = {};
          if (
            typeof dict_click[element[0]] === "undefined" ||
            typeof dict_purchase[element[0]] === "undefined" ||
            typeof dict_earning[element[0]] === "undefined"
          ) {
            this.children[element[0]] = [];
            dict_click[element[0]] = element[2];
            dict_purchase[element[0]] = element[3];
            dict_earning[element[0]] = element[4];
            //this.children[element[0]]= [element]
          } else {
            dict_click[element[0]] += element[2];
            dict_purchase[element[0]] += element[3];
            dict_earning[element[0]] += element[4];
            //this.children[element[0]].push(element)
          }

          new_element["brand"] = element[0];
          new_element["link"] = element[1];
          new_element["click"] = element[2];
          new_element["purchase"] = element[3];
          new_element["earning"] = element[4];

          if (element[0] == "thebodyshop") {
            var tbs_link = element[1].split("&url=")[1];
            var tbs_decoded = decodeURIComponent(tbs_link);
            var tbs_url_decoded = "";
            if (tbs_decoded.includes("https://") == false) {
              tbs_decoded.replace("http://", "");
              tbs_url_decoded = "https://".concat(tbs_decoded);
              tbs_decoded = tbs_url_decoded;
            }
            var tbs_url = new URL(tbs_decoded);
            tbs_url = tbs_decoded.split("utm_source=")[0];
            tbs_url = tbs_url.substring(0, tbs_url.length - 1);

            new_element["link"] = tbs_url;
          }
          if (typeof new_element["link"] !== "undefined") {
            new_element["link"] = new_element["link"].toLowerCase();
            new_element["href_link"] = new_element["link"]
              .replace("http://www.", "")
              .replace("https://www.", "")
              .replace("http://", "")
              .replace("https://", "")
              .replace("www.", "");
          }

          if (typeof this.children[element[0]] === "undefined") {
            this.children[element[0]] = new_element;
          } else {
            this.children[element[0]].push(new_element);
          }
        });
      }

      for (var key in dict_click) {
        result.push({
          brand: key,
          link: "",
          click: dict_click[key],
          purchase: dict_purchase[key],
          earning: dict_earning[key],
          ishidden: true,
        });
        this.child_is_open[key] = false;
      }
      this.result = _.orderBy(
        result,
        ["brand", "click", "link"],
        ["asc", "desc", "asc"]
      );
    }
  },

  computed: {
    groupedCampaigns() {
      //var headers = this.tabledata[0]
      return 0;
    },
  },
  watch: {
    selected_date_option: async function () {
      this.selectDate(this.selected_date_option);
    },
  },
  methods: {
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
        this.result = _.orderBy(this.result, [s], [this.currentSortDir]); // ['brand', 'click', 'link'] ['asc', 'desc', 'asc']
        for (var key in this.children) {
          this.children[key] = _.orderBy(
            this.children[key],
            [s],
            [this.currentSortDir]
          );
        }
      }
      this.currentSort = s;
    },
    async selectDate(payload) {
      switch (payload) {
        case "Son Gün":
          this.days = 1;
          this.weeks = 0;
          this.months = 0;
          break;
        case "Son 3 Gün":
          this.days = 3;
          this.weeks = 0;
          this.months = 0;
          break;
        case "Son Hafta":
          this.days = 0;
          this.weeks = 1;
          this.months = 0;
          break;
        case "Son 2 Hafta":
          this.days = 0;
          this.weeks = 2;
          this.months = 0;
          break;
        case "Son Ay":
          this.days = 0;
          this.weeks = 0;
          this.months = 1;
          break;
        default:
          this.days = 0;
          this.weeks = 1;
          this.months = 0;
          break;
      }
      try {
        if (localStorage.user) {
          var userID = JSON.parse(localStorage.user);
          var aff_id = userID.aff_id;

          var linkQuery =
            "?days=" +
            this.days +
            "&weeks=" +
            this.weeks +
            "&months=" +
            this.months +
            "&aff_id=" +
            aff_id;
          this.query = linkQuery;
          const resp2 = await Axios.get(
            "https://www.yukarikaydir.com/api/v1/linkStatistics/query" +
              linkQuery
          );
          this.tabledata = resp2.data;
          this.table_loaded = true;
          var data = this.tabledata.slice(1);
          var dict_click = {},
            dict_purchase = {},
            dict_earning = {},
            result = [];

          if (data !== "") {
            data.forEach((element) => {
              var new_element = {};
              if (
                typeof dict_click[element[0]] === "undefined" ||
                typeof dict_purchase[element[0]] === "undefined" ||
                typeof dict_earning[element[0]] === "undefined"
              ) {
                this.children[element[0]] = [];
                dict_click[element[0]] = element[2];
                dict_purchase[element[0]] = element[3];
                dict_earning[element[0]] = element[4];
                //this.children[element[0]]= [element]
              } else {
                dict_click[element[0]] += element[2];
                dict_purchase[element[0]] += element[3];
                dict_earning[element[0]] += element[4];
                //this.children[element[0]].push(element)
              }

              new_element["brand"] = element[0];
              new_element["link"] = element[1];
              new_element["click"] = element[2];
              new_element["purchase"] = element[3];
              new_element["earning"] = element[4];

              if (element[0] == "thebodyshop") {
                var tbs_link = element[1].split("&url=")[1];
                var tbs_decoded = decodeURIComponent(tbs_link);
                var tbs_url_decoded = "";
                if (tbs_decoded.includes("https://") == false) {
                  tbs_decoded.replace("http://", "");
                  tbs_url_decoded = "https://".concat(tbs_decoded);
                  tbs_decoded = tbs_url_decoded;
                }
                var tbs_url = new URL(tbs_decoded);
                tbs_url = tbs_decoded.split("utm_source=")[0];
                tbs_url = tbs_url.substring(0, tbs_url.length - 1);

                new_element["link"] = tbs_url;
              }
              if (typeof new_element["link"] !== "undefined") {
                new_element["link"] = new_element["link"].toLowerCase();
                new_element["href_link"] = new_element["link"]
                  .replace("http://www.", "")
                  .replace("https://www.", "")
                  .replace("http://", "")
                  .replace("https://", "")
                  .replace("www.", "");
              }

              if (typeof this.children[element[0]] === "undefined") {
                this.children[element[0]] = new_element;
              } else {
                this.children[element[0]].push(new_element);
              }
            });
          }
          for (var key in dict_click) {
            result.push({
              brand: key,
              link: "",
              click: dict_click[key],
              purchase: dict_purchase[key],
              earning: dict_earning[key],
              ishidden: true,
            });
            this.child_is_open[key] = false;
          }
          this.result = _.orderBy(
            result,
            ["brand", "click", "link"],
            ["asc", "desc", "asc"]
          );
        }
      } catch (err) {
        this.error = err.message;
      }
    },

    /*bringChild: function(brand){
        if (this.child_is_open[brand] === false){
            this.children[brand].forEach( element => {
                this.result.push(element)
            })  
            this.child_is_open[brand] = true      
        }
        else{
            this.children[brand].forEach( element => {
                let i = this.result.indexOf(element)
                if(i !== -1){
                    this.result.splice(i, 1)
                //this.result.pop(element)
                }
            })
            this.child_is_open[brand] = false
        }
        this.result = _.orderBy(this.result, ['brand', this.currentSort, 'link'] ,  ['asc', this.currentSortDir, 'asc'])
  },*/
  },
};
</script>

<style scoped>
a.sort-by {
  padding-right: 18px;
  position: relative;
}
a.sort-by:before,
a.sort-by:after {
  border: 4px solid transparent;
  content: "";
  display: block;
  height: 0;
  right: 5px;
  top: 50%;
  position: absolute;
  width: 0;
}
a.sort-by:before {
  border-bottom-color: #666;
  margin-top: -9px;
}
a.sort-by:after {
  border-top-color: #666;
  margin-top: 1px;
}
.descending-button {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: 6px;
  top: 50%;
  margin-top: -7px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.table-container {
  max-width: 100%;
}

.resp-table {
  width: 100%;
  display: table;
}

#resp-table-caption {
  display: table-caption;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.table-header-cell {
  display: table-cell;
  padding: 10px;
  text-align: justify;
  border-bottom: 1px solid black;
}

#resp-table-body {
  display: table-row-group;
}

.resp-table-row {
  display: table-row;
}

.table-body-cell {
  display: table-cell;
}

@media screen and (max-width: 768px) {
  .table-container {
    min-width: 160vw;
  }
}
</style>
