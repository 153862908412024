import { render, staticRenderFns } from "./PeraBulvariDetail.vue?vue&type=template&id=c3f33a80&scoped=true&"
import script from "./PeraBulvariDetail.vue?vue&type=script&lang=js&"
export * from "./PeraBulvariDetail.vue?vue&type=script&lang=js&"
import style0 from "./PeraBulvariDetail.vue?vue&type=style&index=0&id=c3f33a80&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3f33a80",
  null
  
)

export default component.exports