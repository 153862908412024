<template> 
<section id="what-is-Linkadoo" class="linkadoo-features d-flex justify-content-center">
<div class="col-12 col-md-8 mt-4">
    <h3 style="text-align: center">Start Earning Today!</h3>
    <div class="row mt-4">
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/onerilerinle_kazan.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title"><b>Earn with your recommendation</b></h5>
                    <p class="card-text">You can earn money when the products you've shared with your friends and family are purchased, you can add monetary value to your recommendations.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/share_link.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title">Create Links Easily</h5>
                    <p class="card-text">You can convert product links to links special to you with just one click on Linkadoo and share those links easily.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/social_sharing1.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title">Share on Any Platform</h5>
                    <p class="card-text">With Linkadoo links you are not limited to social media platforms and can also share your links with your social circle on any platform including instant messaging apps.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/social_interaction.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title">Reach Your Favorite Brands with a single click</h5>
                    <p class="card-text">You can earn money through Linkadoo by sharing campaigns by brands you like without having to do individual deals with them.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/post_analysis2.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title">Track & Analyse Your Posts</h5>
                    <p class="card-text">You can track and analyse your posts for  number of clicks and sales and optimize your posts to share the correct products for your target audience in order to increase your earnings.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/social_interaction.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title">Earn through engagement, not number of followers</h5>
                    <p class="card-text">Remind yourself that the important thing is the number of people that engage with your posts and not your number of followers! Your earnings at Linkadoo depend on the amount of engagements your posts get and not how many people they've reached. You are also not limited to social media platforms and can share brands with your social circle via instant messaging apps as well.</p>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
</template>

<script>
export default {
    name: "LinkadooFeatures",
}
</script>

<style scoped>

.linkadoo-features{
    background-color: #eef4f5;
}

.border-light{
    border-radius: 5px;
    border: 1px solid #d9e4e6 !important;
}

img{
    background-color: RGBA(230,71,105,0.4);
    border-radius: 80%;
    z-index: 10;

}

.card-columns{
    column-count: 3;
}

@media screen and (min-width: 768px) {
}

@media screen and (max-width: 767px) {
    .card-columns{
        column-count: 1;
    }
}
</style>