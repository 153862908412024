<template>
    <div class="container">
        <form @submit.prevent="handleSubmit">
            <div class="form-group col-lg-8 offset-lg-2 self-align-right login_container">
                <router-link to="/" class="navbar-brand offset-5">
                    <img src="../assets/linkadoo_logo_mini.png" style="width: 40px; height: 40px; margin-bottom: 40px; margin-top: 20px;"/>
                </router-link>            
                <h2 v-if="emailnotfound" class="offset-lg-2" style="padding-left: 2%; color: #c33;">E-mailini Bulamadık</h2>
                <h2 v-else class="offset-lg-2" style="padding-left: 2%;">Linkadoo Şifreni Yenile</h2>
                <div class="form-group col-lg-6 offset-lg-2 col-sm-12">
                    <label v-if="emailnotfound" for="email" style="font-size: 14px;">Lütfen, başka bir email adresi dene. </label>
                    <label v-else for="email" style="font-size: 14px;">Kayıtlı email adresini gir.</label>
                    <input type="email" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" />
                    <div v-show="submitted && !email" class="invalid-feedback">Email adresi gerekli</div>
                </div>
                
                <div class="form-group col-lg-8 offset-lg-2 col-sm-12">
                    <button class="btn btn-primary kaydol" >Şifremi Gönder</button>
                    <!--<img v-show="status.loggingIn" src="../assets/loading.svg"/>-->
                    <!--<router-link to="/signup" class="btn btn-link" style="color: #E64769;">Şifremi Gönder</router-link>-->
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import { router } from '../router.js';

export default {
    data () {
        return {            
            email: '',
            submitted: false,
            emailnotfound: false,
        }
    },

    methods: {
        handleSubmit () {
            this.submitted = true;
            if (this.email){
            axios.post('https://ulan-gitlab-heroku-env-staging.herokuapp.com/affiliaters/begin-password-reset', 
                {email: this.email })
                    .then(() => { this.emailnotfound=false; 
                    router.push('/send-password-reset') })
                    .catch(()=> this.emailnotfound=true);
            }
        }

    }
    
}
</script>

<style scoped>
.error-div{
    display: block; 
    padding-left: 10px
}

.form-group{
  padding: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.container{
    margin-top: 30px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

.kaydol{
    background-color: rgb(7, 164, 175);
    border-color: rgb(7, 164, 175);
    
}

.kaydol:hover, .kaydol:focus, .kaydol:active{
   outline: none !important;
   box-shadow: none;

}

@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

}

</style>