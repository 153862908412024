<template>
    <div style="padding-left:0px; padding-right:0px;">
        <section id="hero-aff">
            <div class="hero-center">
                <div class='mb-5'> 
                    <p class='lead'></p>
                    <h1 id="header-h1" style="color:white; font-family: 'Montserrat', sans-serif; text-align: left;">Müşterilerinize Ulaşmanın En İyi Yolu</h1>
                    <p class="txt-header-lg" style="color:white; margin-right: -5px;">Linkadoo, müşterilerinize diğer pazarlama ağlarının aksine, yaşayan ve sürekli gelişen bir insan ağı ile ulaşmanızı sağlayan en verimli reklam platformudur.</p>
                </div>
                <div class='mb-5 cta-div'>
                    <!--<button id="kazanmaya_basla_btn" class="btn btn-outline-primary shadow-none btn-lg login-btn" >Giriş Yap</button>-->
                    <a href="#contact-form" id="kazanmaya_basla_btn" class="btn btn-outline-primary btn-lg cta">Bize Ulaşın</a>
                </div>
            </div>
        </section>
        <what-buss-intro />
        <what-buss-second />
        <what-buss-form />
        <footer-s />
    </div>
</template>

<script>

import WhatBussIntro from '../components/layout/WhatBussIntro.vue'
import WhatBussSecond from '../components/layout/WhatBussSecond.vue'
import WhatBussForm from '../components/layout/WhatBussForm.vue'
import FooterS from '../components/layout/Footer.vue'

export default {
    name: "LandingPageBusiness",
    components: {
        WhatBussIntro,
        WhatBussSecond,
        WhatBussForm,
        FooterS,
        
    }, 
    data(){
        return {
            
        }
    },
    
}
</script>

<style scoped>



.main_container{
    padding-left: 0px !important;
}

#hero-aff{
    margin-top: -4% !important;
    margin-left: -21.4% !important;
    background: url("../assets/ulan_lp2_lyesil4_resized_desktop.jpg") no-repeat center center fixed;  /*lp_business_3.jpeg*/
    width: 100vw!important;  
    height: 100vh!important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.hero-center{
    padding: 20% 10%;
}

.cta{
    color: rgb(7, 164, 175) !important;
    border-color: white !important;
    background: white;
}

.cta:hover{
    color: white !important;
    border-color: rgb(7, 164, 175) !important;
    background: rgb(7, 164, 175) !important;
}

.cta-div{
    padding-top: 5%;
}

.login-btn{
    color: #E64769 !important;
    border-color: white !important;
    background: white;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
}

.login-btn:hover{
    color: white !important;
    border-color: #E64769 !important;
    background: #E64769 !important;
}

.signup-btn:hover{
    color: white !important;
    border-color: rgb(7, 164, 175) !important;
    background: rgb(7, 164, 175) !important;
    /*border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;*/
}

.signup-btn{
    color: rgb(7, 164, 175) !important;
    border-color: white !important;
    background: white !important;
}

@media (max-width: 767px) {
    #hero-aff{
        margin-left: 0px !important;
        background: url("../assets/ulan_lp2_lyesil4_resized_mobile.jpg") no-repeat center center;  /*lp_business_3.jpeg*/
        width: 100vw!important;  
        height: 100vh!important;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-attachment: scroll;
        background-size:cover;

    }

    .hero-center{
        padding: 50% 10%;
    }
}

@media (min-width: 1200px){
#header-h1{
    font-size: 3.625em!important;
    }
}

</style>