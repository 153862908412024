<template>
<div class="container">
    <ValidationObserver  v-slot="{ invalid }">           

        <form @submit.prevent="handleSubmit">
            <div class="form-group col-lg-8 offset-lg-2 self-align-right login_container">
                <router-link to="/" class="navbar-brand offset-5">
                    <img src="../assets/linkadoo_logo_mini.png" style="width: 40px; height: 40px; margin-bottom: 40px; margin-top: 20px;"/>
                </router-link> 
                <h2 class="offset-lg-2" style="padding-left: 2%;">Linkadoo Şifreni Yenile</h2>
                <div class="form-group col-lg-8 offset-lg-2 col-sm-12">
                    <label htmlFor="password">Şifre</label>
                    
                        <ValidationProvider rules="min:6|confirmed:confirmation" v-slot="{ errors }">
                            <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                            <span style="font-size: 80%; color: #dc3545;">{{ errors[0] }}</span>
                            <span style="font-size: 80%; color: #dc3545;">{{ errors[1] }}</span>

                            <div v-show="submitted && !password" class="invalid-feedback">Şifre girmen gerekir</div>
                        </ValidationProvider>
                </div>
                <div class="form-group col-lg-8 offset-lg-2 col-sm-12">
                    
                    <ValidationProvider vid="confirmation" v-slot="{ errors }">
                        <input type="password"  v-model="confirmation" name="confirmation" class="form-control"/>
                    </ValidationProvider>
                        
                </div>
                
                <div class="form-group col-lg-8 offset-lg-2 col-sm-12">
                    <button class="btn btn-primary kaydol" :disabled="invalid || !password">Şifremi Gönder</button>
                    <!--<img v-show="status.loggingIn" src="../assets/loading.svg"/>-->
                    <!--<router-link to="/signup" class="btn btn-link" style="color: #E64769;">Şifremi Gönder</router-link>-->
                </div>
            </div>
        </form>
        </ValidationObserver>
    </div>

    
</template>

<script>
import axios from "axios";

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { min, confirmed } from 'vee-validate/dist/rules';
//import { router } from '../router.js';

// Override the default message.
extend('min', {
  ...min,
  message: 'Şifren en az 6 karakter içermeli'
});
extend('confirmed', {
  ...confirmed,
  message: 'Lütfen yukarıda girmiş olduğun şifreyi tekrarla.'
});
export default {
    data () { 
        return {
            email: '',
            submitted: false,
            password: '',
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods: {
        handleSubmit () {
            this.submitted = true;
            if (this.password){
            axios.post('https://ulan-gitlab-heroku-env-staging.herokuapp.com/affiliaters/password', 
                {aff_id: this.$route.params.id, password: this.password })
                    .then(res => { this.emailnotfound=false; 
                    this.$router.push('/success-password-reset')
                    return res })
                    .catch(()=> this.emailnotfound=true);
            }
        },

    }
    
}
</script>

<style scoped>
.error-div{
    display: block; 
    padding-left: 10px
}

.form-group{
  padding: 10px;
  padding: 10px;
  margin-bottom: 10px;
}


.container{
    margin-top: 0px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

.kaydol{
    background-color: rgb(7, 164, 175);
    border-color: rgb(7, 164, 175);
    
}

.kaydol:hover, .kaydol:focus, .kaydol:active{
   outline: none !important;
   box-shadow: none;

}
@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>