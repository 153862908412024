<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      styleClass="vgt-table striped"
      >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'share'">
            <button type="button" class="btn btn-outline-secondary btn-sm btn-block haberver Chakra"
             @click="showModalFn(props.row.redirect_link)"> 
                Paylaş
            </button>
        </span>
        <span v-else-if="props.column.field === 'image_src_new'">
            <a :href='props.row.redirect_link' target="_blank">
            <div class="col-12"><p style="font-size: 10px;">
                <b>Mozaik Nakışlı Havlu 30*50</b>
                </p>
            </div>
            <div class="col-12">
                <img :src='props.row.image_src' width="75" height="75"/>
            </div>
            </a>
            
        </span>
        <span v-else>
            {{props.formattedRow[props.column.field]}}
        </span>
        </template>
    </vue-good-table>

    
      <modal v-if="showModal" @close="showModal = false">
        <!--
      you can use custom content here to overwrite
      default content
    -->
        
        <h5 slot="header">
            <div class="animate-pop-in">
                {{ header_content }}
            </div>
        </h5>
        
        <div slot="body">
            
        <social-sharing v-bind:url="link" quote="deneme" hashtags="Linkadoo"
                v-model="model"
                v-on:click="onCopy"
                network-tag='span'
                title="Heey! Bir Göz at istersen, beğeneceğin şeyler olabilir!"
                description="Heey! Bir Göz at istersen, beğeneceğin şeyler olabilir!"
                inline-template>
            
            <div class="row col-12 social-container">
                <div class="col-3 social-button-div">
                    <button 
                    v-clipboard:copy="url"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    type="button"
                    style="background: transparent; width: 100%; height: 100%; margin: 0px; padding:0px; border: none;"
                    >
                       <img src="../assets/icons/copy_colored.svg">
                    </button>
                </div>
                
                <div class="col-3 social-button-div">
                    <network network="twitter">
                    <img class="social-btn-icon" src="../assets/icons/tw_colored.svg">
                    </network>
                </div>
                <div class="col-3 social-button-div">
                    <network  network="facebook">
                    <img class="social-btn-icon" src="../assets/icons/fb_colored.svg">
                    </network>
                </div>
                <div class="col-3 social-button-div">
                    <network  network="whatsapp">
                    <img class="social-btn-icon" src="../assets/icons/wp_colored.svg">
                    </network>
                </div>
            </div>
    </social-sharing>
        </div>
        <div slot="footer">
        <button class="modal-default-button" v-on:click="closeModal">
                    OKs
        </button>
        </div>

      </modal>
</div>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import ShareModal from '../components/layout/ShareModal.vue';
//import SocialSharing from "vue-social-sharing"

export default {
    name: "LinkTable",
    components:{
        //SocialSharing,
        VueGoodTable,
        modal: ShareModal
    },
    data(){
    return {
        header_content: "Dilediğin gibi Tekrar Paylaş",
        model: {
                product_url: "asda",
                linkadoo_url: ""
            },
      showModal: false,
      link: "",
      columns: [
        {
            label: 'Ürünün',
            field: 'image_src_new',
            html: true,
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
        },
        {
            label: '',
            field: 'share',
            html: true,
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center align-middle',
        },
        {
          label: 'Tıklanma Sayısı',
          field: 'click_count',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Kazandığın Tutar',
          field: 'earned_amount',
          type: 'number',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Dönüşüm Oranın',
          field: 'conversion_rate',
          type: 'percentage',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Tarih',
          field: 'create_date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-center',
          tdClass: 'text-center align-middle',
        },
        
      ],
      rows: [
        
      ],
    };
  },
  async created() {
      try{
        if(localStorage.user)
        {
            var model = {}
            model.aff_id = "1"
          //var userID = JSON.parse(localStorage.user);
          //var aff_id = userID.aff_id;//await userService.getWallet(userID.aff_id);
          //var wallet = userID.wallet;
          axios({ method: "GET", "url": "https://ulan-gitlab-heroku-env-staging.herokuapp.com/affwallet/getLinks",
          "params":model, "baseURL": "", "headers": {"content-type": "application/json"} }).then( //
          res => { //  
            this.rows = res.data
            this.rows[0].create_date = new Date(this.rows[0].create_date)
            this.rows[0].create_date = this.rows[0].create_date.getFullYear() + '-' + this.rows[0].create_date.getMonth() + '-' + this.rows[0].create_date.getDate() 
            this.rows[0].id = 0
          }
          )
        }
      }catch(err) {
        this.error = err.message;
      }
  },
  methods: {
      showModalFn: function(link){
          this.showModal = true
          this.link = link

      },
      onError: function () {
            alert('Failed to copy texts')
        },
      closeModal: function(){
          this.showModal = false
          this.header_content="Dilediğin gibi Tekrar Paylaşs"
        },
        onCopy: function(){
            alert("adasdasd")
            this.header_content="asdadad"
        },    
        say: function (message) {
      alert(message)
    }
  }
    
}
</script>
<style scoped>

.animate-pop-in {
  animation: pop-in .6s ease-out forwards;
}

.social-btn-circle{
    background: transparent !important;
    width: 40px;
    padding: 0px !important;
    margin-top: 15px;
    margin-bottom: 15px;
    border: None;
    text-align: center;
    font-size: 12px;
}

.modal-header-popup{
    text-align: center;
}



.haberver{
    color: rgb(7, 164, 175);
    border-color: rgb(7, 164, 175);
    background-color: white !important;
    transition: none;
}

.haberver:hover, .haberver:focus {
    color: white;
    background-color:rgba(7, 164, 175,0.9) !important;
    text-decoration: none;
    outline-color: none !important;
    /*box-shadow: none;*/
    /*outline-color: linear-gradient(60deg, #ffa726, #fb8c00) !important;
    box-shadow: none;*/
}
.social-container{
    margin-left: 0px;
}
@media screen and (max-width: 767.98px) {
    .social-btn-circle{
            width: 60px !important;
            padding-left: 25% !important;
            margin-right: 5% !important;
        }
    .social-container{
        padding-right: 0px;
        padding-left: 0px;
    }
    
}
</style>