const axios = require("axios");

const url =
  "https://campaign-detail-api-v2-38e3b6518b5b.herokuapp.com/api/campaigns";
//const url = "/campaigns";

class AffOffersService {
  // Get Campaigns
  static getCampaigns() {
    return new Promise(function (resolve, reject) {
      //const res = await axios.get(url);
      axios
        .get(url)
        .then((res) => {
          const data = res.data.data;
          //const data = res.data.data;

          resolve(
            data.map((campaign) => ({
              ...campaign,
              createdAt: new Date(campaign.create_date),
            }))
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default AffOffersService;
