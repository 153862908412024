<template>
<section class="story-3">
    <div class="shape">
        <img src="../../assets/layer.svg">
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-10 col-sm-6 col-md-5 col-lg-6 col-xl-5">
                <div class="content-img">
                    <img src="../../assets/story-3.png">
                </div>
            </div>
            <div class="col-sm-6 col-md-7 col-lg-6 col-xl-5 offset-xl-1">
                <div class="content-left-content">
                    <h2>Convert your shares into earnings</h2>
                    <p>Earn a commission as soon as the product you have shared is purchased. Analyze your shares, learn about your target audience and their interests easily and increase your earnings</p>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "LinkadooStoryThird"
}
</script>
<style scoped>
.story-3{
    padding: 67px 0 93px;
    background-color: #fefdfd;
    position: relative;
}
.story-3 > .shape{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-75%);
}
.shape > img{
    width: 100%;
    max-width: initial;
}
.content-img{
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-img > img{
    width: 100%;
}
img{
    max-width: 100%;
}
.content-left-content{
    padding-top: 30px;
    text-align: left; 
}
.content-left-content > h2{
        padding-bottom: 25px;
        font-weight: 600;
        letter-spacing: 0px;
        font-size: 30px;
        line-height: 40px;
        font-family: 'Montserrat', sans-serif; 
}
.content-left-content > p{
        font-weight: 500;
        letter-spacing: -0.4px;
        font-size: 18px;
        line-height: 30px;
        font-family: 'Montserrat', sans-serif; 
}
@media (min-width: 576px){
    .content-left-content{
        padding-top: 0px;
    }
    .content-left-content > h2{
        font-size: 26px;
        line-height: 36px;
    }

    .content-left-content > p{
        font-size: 17px;
        line-height: 24px;
    }
}
@media (min-width: 768px){
    .content-left-content > h2{
        font-size: 30px;
        line-height: 44px;
    }

    .content-left-content > p{
        font-size: 21px;
        line-height: 36px;
    }
}
@media (min-width: 992px) {
    .story-3{
        padding: 120px 0 215px;
    }
    .content-img{
        padding-bottom: 0px;
    }
    .content-left-content{
        padding-top: 60px;
    }
    .content-left-content > h2{
        font-size: 38px;
        line-height: 60px;
    }
    .content-left-content > p{
        font-size: 21px;
        line-height: 36px;
    }
}
</style>