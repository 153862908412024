<template> 
    <div style="padding-left:0px; padding-right:0px;">
        <section class="col-12" id="hero-aff">
            <picture class="d-block d-md-none">
            <source class="hero-background-mobil" srcset="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/lp_assets%2Fulan_lp4_resized_mobile.webp?alt=media&token=e6495807-7566-48b1-a6e9-16cc7066e0aa" type="image/webp"
            >
            <source class="hero-background-mobil" srcset="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/lp_assets%2Fulan_lp4_resized_mobile.jp2?alt=media&token=9f0f17f1-3e7e-46e4-98b9-866836a31880" type="image/jp2">
            <source class="hero-background-mobil" srcset="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/lp_assets%2Fulan_lp4_resized_mobile.jpeg?alt=media&token=dc8f24c4-2cda-497d-bd74-0708d2106a1e" type="image/jpeg">
            <img class="hero-background-mobil" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/lp_assets%2Fulan_lp4_resized_mobile.jpeg?alt=media&token=dc8f24c4-2cda-497d-bd74-0708d2106a1e"
            >
            </picture>
            <div class="hero-center" style="background: transparent">
                <div class='mb-5'>
                    <p class='lead'></p>
                    <h1 id="header-h1" style="color:white; font-family: 'Montserrat', sans-serif; text-align: left;font-weight: 800;">Seç, Paylaş ve Kazan!</h1>
                    <p class="txt-header-lg">Linkadoo ile artık önerilerin değerleniyor. Beğendiğin ürünleri çevrenle paylaşarak para kazanabilir, önerilerine değer katabilirsin.</p>
                </div>
                <div class='mb-5 cta-div'>
                    <!--<button id="kazanmaya_basla_btn" class="btn btn-outline-primary shadow-none btn-lg login-btn" >Giriş Yap</button>-->
                    <a href="/signup" id="kazanmaya_basla_btn" class="btn btn-outline-primary shadow-none btn-lg signup-btn" >Kayıt Ol</a>

                </div>
            </div>
        </section>
        <!--<how-aff-intro />-->
        <linkadoo-features />
        <linkadoo-story />
        <linkadoo-story-second />
        <linkadoo-story-third />
        <linkadoo-layer />
        <footers />
    </div>
</template>

<script>
import LinkadooFeatures from '../components/layout/LinkadooFeatures.vue'
import LinkadooStory from '../components/layout/LinkadooStory.vue'
import LinkadooStorySecond from '../components/layout/LinkadooStorySecond.vue'
import LinkadooStoryThird from '../components/layout/LinkadooStoryThird.vue'
import LinkadooLayer from '../components/layout/LinkadooLayer.vue'

//import HowAffIntro from '../components/layout/HowAffIntro.vue'
//import HowAffSecond from '../components/layout/HowAffSecond.vue'
import Footers from '../components/layout/Footer.vue'

export default {
    name: "LandingPage",
    components: {
        //HowAffIntro,
        LinkadooFeatures,
        LinkadooStory,
        LinkadooStorySecond,
        LinkadooStoryThird,
        LinkadooLayer,
        //HowAffSecond,
        Footers,
        
    }, 
    data(){
        return {
            
        }
    },
    
}
</script>

<style scoped>


@media (min-width: 768px) {
    #hero-aff{
        padding: 0px !important;
        /*margin-top: -4% !important;
        margin-left: -21.4% !important;*/
        background: url("https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/lp_assets%2Fulan_lp4_resized_desktop.jpg?alt=media&token=5b840c62-94d9-458e-8d53-89240f31074d") no-repeat center center fixed;  /*lp_business_3.jpeg*/
        width: 100vw!important;  
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

.txt-header-lg{
    color:white; 
    margin-right: -5px; 
    font-size: 22px;
}

.main_container{
    padding-left: 0px !important;
}



.hero-center{
    padding: 20% 10%;
}

.cta-div{
    padding-top: 10%;
}

.login-btn{
    color: #E64769 !important;
    border-color: white !important;
    background: white;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
}

.login-btn:hover{
    color: white !important;
    border-color: #E64769 !important;
    background: #E64769 !important;
}

.signup-btn:hover{
    color: white !important;
    border-color: rgb(7, 164, 175) !important;
    background: rgb(7, 164, 175) !important;
    /*border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;*/
}

.signup-btn{
    color: rgb(7, 164, 175) !important;
    border-color: white !important;
    background: white !important;
}

@media (max-width: 767px) {
    .txt-header-lg{
        margin-right: -5px; 
        font-size: 18px;
    }
    #hero-aff{
        padding: 0px !important;
        width: 100vw!important;  
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-attachment: scroll;
        background-size:cover;
        z-index: 1;
        display: inline-block !important;
        overflow: hidden !important;
        position: relative !important;

    }

    .hero-background-mobil{
        pointer-events: none;
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
        
    }
    .hero-center{
        padding: 50% 10%;
    }
}

@media (min-width: 1200px){
#header-h1{
    font-size: 3.625em!important;
    }
}

</style>