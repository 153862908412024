<template>
  <div class="container">
    <div class="panel-body">
      <vue-form-generator :schema="schema" :model="conversion" :options="formOptions">
        <field-object />
      </vue-form-generator>
    </div>
  </div>
</template>

<script>
import {router} from "../router.js";

export default {
  name: "DemoTransactionPage",
  components: {
    },
    data () {
    return {
      conversion: {
        campaign_id: "",
        amount: "",
        aff_id: this.$cookies.get('aff_id')
      },
      response: "",
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'number',
            label: 'Campaign ID',
            model: 'campaign_id',
            //readonly: true,
            //disabled: true,
            featured: true,
            required: true
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Amount',
            model: 'amount',
            //readonly: true,
            //disabled: true,
            featured: true,
            required: true
          },
          {
            type: "submit",
            label: "",
            caption: "Submit form",
            validateBeforeSubmit: true,
            response: "",
            onSubmit(conversion) {           
                  router.push({name: 'DEMOCONVERSION', params: {aff_id: 58, campaign_id: conversion.campaign_id, amount: conversion.amount}});
                }
            }
        ]
      },
      
    }
  },
  
  
}
</script>

<style>
.btn.btn-outline-danger.btn-sm{
  width: 30px!important;
  height: 30px!important;
  padding-right:0px !important;
  padding-left: 10px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  position:absolute;
     top:0;
     right:0;
  
}

.col-sm-12.group-input{
  border: 1px solid gray;
  border-width: .02rem;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
</style>