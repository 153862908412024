<template>
  <div class="container">
    <div
      style="padding-bottom: 100px"
      class="col col-lg-8 offset-lg-2 self-align-right login_container"
    >
      <router-link to="/" class="navbar-brand offset-5">
        <img
          src="../assets/linkadoo_logo_mini.png"
          style="
            width: 40px;
            height: 40px;
            margin-bottom: 40px;
            margin-top: 20px;
          "
        />
      </router-link>
      <div v-if="loading">Yükleniyor.</div>
      <div v-else-if="success">
        <h2 class="offset-lg-2" style="padding-left: 4%">
          Hesabını Başarılı Şekilde Kapadın
        </h2>
        <div
          style="padding-top: 50px"
          class="col col-lg-8 offset-lg-2 col-sm-12"
        >
          Fikrin Değişirse seni tekrar bekleriz.
        </div>
      </div>
      <div v-else-if="error">
        <h2 class="offset-lg-2" style="padding-left: 4%">Bir Hata Oluştu.</h2>
        <div
          style="padding-top: 50px"
          class="col col-lg-8 offset-lg-2 col-sm-12"
        >
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: true,
      success: false,
      error: null,
    };
  },
  mounted() {
    const token = this.$route.params.token;
    axios
      .post(
        "https://ulan-gitlab-heroku-env-staging.herokuapp.com/deleteAffiliaterToken/delete",
        { token: token }
      )
      .then((response) => {
        this.loading = false;
        if (response.data.message) {
          // Error response
          this.error = response.data.message;
        } else {
          // Success response
          this.success = true;
          // You can access the response data using response.data
          console.log(response.data);
        }
      })
      .catch((error) => {
        this.loading = false;
        this.error =
          "Maalesef bilinmeyen bir hata oluştu. İşlemi tekrar başlatabilirsin.";
        console.error(error);
      });
  },
};
</script>

<style scoped>
.error-div {
  display: block;
  padding-left: 10px;
}

.form-group {
  padding: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.container {
  margin-top: 0px;
}

.login_container {
  background: white;
  box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

.kaydol {
  background-color: rgb(7, 164, 175);
  border-color: rgb(7, 164, 175);
}

.kaydol:hover,
.kaydol:focus,
.kaydol:active {
  outline: none !important;
  box-shadow: none;
}
@media screen and (max-width: 860px) {
  .container {
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
