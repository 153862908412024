<template> 
        <nav v-on:scroll.passive="handleScroll()" class="navbar navbar-expand-md navbar-light bg-light fixed-top navbar-transition" style="padding-bottom:0px;">
            <a class="navbar-brand" href="/" style="padding-left:15px;">
            <img class="d-none d-md-block" src="../../assets/logo_beyaz.png" width="140" height="35">
            <img class="d-md-none" style="width:35px; height:35px;" src="../../assets/linkadoo_logo_mini_beyaz.png" width="35" height="35"></a>

            <button @click="togglerClick()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <div class="animated-icon1"><span></span><span></span><span></span></div>
            </button>
 
            <div class="container d-md-none">
                <div class="collapse navbar-collapse d-md-none" id="navbarNavAltMarkup">
                    <ul id="root" @click="isShow = !isShow" class="navbar-nav w-100 justify-content-center">
                        <li>
                        <a class="nav-link pl-3" href="#" id="navbarDropdown" role="button"
                        style="color:white">EARN WITH SUGGESTIONS<span class="sr-only">(current)</span></a> </li>
                            <!--<a class="btn btn-outline-primary shadow-none btn-sm btn-buss" href="/business" role="button">Markalar için</a>-->
                        <transition name="slide">
                        <div class="child" v-if="isShow" style="border:0; padding-top:0px;">
                                <a class="dropdown-item"  href="#features" style="color:white">What is Linkadoo?<span class="sr-only">(current)</span></a>
                                <a class="dropdown-item" href="#nasil-kullanirim-1" style="color:white">How Can I Use?<span class="sr-only">(current)</span></a>
                                <a class="dropdown-item" data-toggle="modal" data-target="#appModal" href="" style="color:white">Download Linkadoo App<span class="sr-only">(current)</span></a>
                        </div>
                        </transition>
                        <li class="nav-item">
                            <a class="nav-link pl-3" href="/login" style="color:white">Log In<span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link pl-3 signup-mobile" href="/signup" style="color:white">Sign Up<span class="sr-only">(current)</span></a>
                        </li>
                        <!--<li class="nav-item">
                            <a class="nav-link pl-3" href="/business" style="color:white">MARKALAR İÇİN<span class="sr-only">(current)</span></a>
                        </li>-->
                    </ul>
                </div>
            </div>

            <div class="collapse navbar-collapse d-none d-md-block" id="navbarNavAltMarkup">
                <ul class="navbar-nav w-100 justify-content-center" style="padding-left:0px!important; ">
                    <li class="nav-item">
                        <a class="nav-link pl-3" href="#what-is-Linkadoo" style="color:white">What is Linkadoo?<span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link pl-3" href="#how-can-i-use" style="color:white">How Can I Use?<span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link pl-3" href="/login" style="color:white">Log In<span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item signup">
                        <a class="nav-link pl-3" href="/signup" style="color:white">Sign Up<span class="sr-only">(current)</span></a>
                    </li>
                </ul>
                <!--<a class="btn btn-outline-primary shadow-none btn-sm aff-butt" href="/business">MARKALAR İÇİN</a>-->
            </div>
            
    </nav> 
</template>

<script>
export default {
    name: "HeaderLanding",
    data () {
        return {
            scrolled: 0,
            toggler_closed : true,
            isShow: false
        };
    },
    methods: { 
        handleScroll(){
            // Get the current scroll position
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop

            var navs = document.getElementsByTagName('nav')
            var nav = navs[0]

            // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
            if (Math.abs(currentScrollPosition) < 100) {
                this.scrolled = false
                nav.className = 'navbar navbar-expand-md navbar-light bg-light fixed-top navbar-transition'
                
            }
            else{
                this.scrolled = true
                nav.className = 'navbar navbar-expand-md navbar-light bg-light fixed-top navbar-transition scrolled'
            }

            // Here we determine whether we need to show or hide the navbar
            //this.scrolled = currentScrollPosition !== this.lastScrollPosition
            // Set the current scroll position as the last scroll position
            this.lastScrollPosition = currentScrollPosition 

            this.currentScrollPosition = currentScrollPosition

    },
    
    togglerClick(){
        var navs = document.getElementsByTagName('nav')
        var nav = navs[0]

        var icon_animateds = document.getElementsByClassName('animated-icon1');
        var icon_animated = icon_animateds[0]

        if(this.toggler_closed){
            nav.classList.add("activated");
            
            this.toggler_closed = false;
            icon_animated.classList.add("open");
 
        }
        else if(this.toggler_closed === false){
            nav.classList.remove("activated");
            
            this.toggler_closed= true;

            icon_animated.classList.remove("open");
        }
        
    }
    },
    
    mounted () {
    document.addEventListener('scroll', this.handleScroll);
    /*var dropdowns = document.getElementsByClassName('dropdown');
    var dropdown = dropdowns[0];
    dropdown.addEventListener('show.bs.dropdown', this.handleShowDrpdwn);
    //dropdown.addEventListener('hide.bs.dropdown', this.handleHideDrpdwn);*/
    },
    destroyed () {
    document.removeEventListener('scroll', this.handleScroll);
    /*var dropdowns = document.getElementsByClassName('dropdown');
    var dropdown = dropdowns[0];
    dropdown.removeEventListener('show.bs.dropdown', this.handleShowDrpdwn);*/
    //dropdown.removeEventListener('hide.bs.dropdown', this.handleHideDrpdwn);
    }
    
}
</script>

<style scoped>



.navbar{
    min-height: 80px;
}

.navbar-toggler{
    border-color: transparent !important;
}

.navbar-toggler:focus, .navbar-toggler:active {
    outline: none !important;
    box-shadow: none;
}

.nav-item {
    color: white !important;
}

.nav-item:hover{
    background-color: rgba(0, 0, 0, 0.08);
}

.signup{
    background-color: rgb(7, 164, 175);
    border-radius: 10%;
    padding-right: 8px;
}

.signup:hover{
    background-color: rgba(7, 164, 175, 0.8);
}

.signup-mobile{
    background-color: rgb(7, 164, 175);
}


a.nav-link:link
{
   color: #000000;
   text-decoration: none;
}
a.nav-link:visited
{
   color: #000000;
   text-decoration: none;
}
a.nav-link:hover
{
   color: #000000;
   text-decoration: none;
}
a.nav-link:active
{
   color: #000000;
   text-decoration: none;
}

.bg-light{
    transition: 300ms ease;
    background-color: transparent !important;
}

.bg-light.scrolled{
    background: rgb(230, 71, 105) !important;
}

.bg-light.activated{
    background: rgb(230, 71, 105) !important;
}

/* Navbar collapse button icons*/
.animated-icon1 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg) !important;
    -moz-transform: rotate(0deg) !important;
    -o-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
    -webkit-transition: .5s ease-in-out !important;
    -moz-transition: .5s ease-in-out !important;
    -o-transition: .5s ease-in-out !important;
    transition: .5s ease-in-out !important;
    cursor: pointer;
}

.animated-icon1 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg) !important;
    -moz-transform: rotate(0deg) !important;
    -o-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
    -webkit-transition: .25s ease-in-out !important;
    -moz-transition: .25s ease-in-out !important;
    -o-transition: .25s ease-in-out !important;
    transition: .25s ease-in-out !important;
    background: #f3e5f5;
}

.animated-icon1 span {
    background: #f3e5f5;
}

.animated-icon1 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center !important;
    -moz-transform-origin: left center !important;
    -o-transform-origin: left center !important;
    transform-origin: left center !important;
}
    
.animated-icon1 span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center !important; 
    -moz-transform-origin: left center !important;
    -o-transform-origin: left center !important;
    transform-origin: left center !important;
}
    
.animated-icon1 span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center !important;
    -moz-transform-origin: left center !important;
    -o-transform-origin: left center !important;
    transform-origin: left center !important;
}
    
.animated-icon1.open span:nth-child(1) {
    -webkit-transform: rotate(45deg) !important;
    -moz-transform: rotate(45deg) !important;
    -o-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
    top: 0px;
    left: 8px;
}

.animated-icon1.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}
    
.animated-icon1.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg) !important;
    top: 21px;
    left: 8px;
}

.aff-butt{
    width: 15% !important;
    color: #E64769 !important;
    border-color: white !important;
    background: white;
}

.aff-butt:hover{
    color: white !important;
    border-color: rgb(7, 164, 175)  !important;
    background: rgb(7, 164, 175)  !important;
}




.dropdown-item:focus, .dropdown-item:active, .dropdown-item:hover{
    background-color: #E64769 !important;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.dropdown-menu{
    background-color: #E64769;
}





@media (min-width: 768px){
#navbarNavAltMarkup{
    display: none;
}
}

@media (max-width: 767px) {
    .navbar-collapse
      {
          background-color:rgb(230, 71, 105) !important;
      }
      

  }


.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: ease-out;
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}

</style>