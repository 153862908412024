<template>
    <div style="padding-left: 0px" class="container">
            <div style="padding-bottom: 100px; " class="col col-lg-12  self-align-right login_container">
                <div style="text-align: center; padding-top: 20px;" class="content-header">
                <h4>Gizlilik ve Kişisel Verilerin Korunması Hakkında Bilgilendirme</h4>
                </div>
                <div style="padding-top: 20px; overflow-y: auto; height: 100vh;" class="col col-lg-12 col-sm-12">   
                    <p>
                       Linkadoo Pazarlama Teknolojileri ve Danışmanlık Anonim Şirketi ("Şirket") olarak; 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun ("KVKK") 10. maddesinden doğan aydınlatma yükümlülüğünü yerine getirmek amacıyla aşağıdaki açıklamaları siz değerli müşterilerimizin ve web-sitemizi kullanan 3. kişilerin dikkatine sunarız.
                    </p>
                    <ol>
                    <li><b> Şirket’in kişisel verileri toplamasının yasal dayanağı nedir?</b>
                       <p> 
                            En başta Türkiye Cumhuriyeti Anayasası ve 6698 sayılı KVKK ile kişisel verilerin korunma esasları belirlenmiştir. Ayrıca 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun da kişisel verilerin korunmasına ilişkin hükümler içermektedir. 5237 Sayılı Türk Ceza Kanunu kişisel verilerin korunmasının sağlanması için bazı hallerde uygulanacak cezai yaptırımlar öngörmektedir. Diğer yandan, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ile Mesafeli Sözleşmeler Yönetmeliği’nden doğan yükümlülüklerimizin ifası için verilerin toplanması ve kullanılması gerekmektedir.
                       </p>
                    </li>
                    <li> <b> Şirket kişisel verilerin toplanmasında hangi yöntemleri kullanıyor? </b>
                        <p>
                            www.linkadoo.me adlı web sitesinden, mobil uygulamalardan, çağrı merkezimizden işlem yapan müşterilerimizin verdikleri veriler, müşterilerimizin rızaları ve mevzuat hükümlerine uygun şekilde Şirket tarafından işlenmektedir.
                            <br><br>
                            Şirket, www.linkadoo.me ziyaretçilerine daha iyi hizmet verebilmek amacıyla ve yasal yükümlülüğü çerçevesinde, işbu Gizlilik ve Kişisel Verilerin Korunması Hakkında Bilgilendirme metninde belirlenen amaçlar ve kapsam dâhilinde, gezinme bilgilerinizi toplayacak, işleyecek, üçüncü kişilerle paylaşacak ve güvenli olarak saklayacaktır.
                            <br><br>
                            Şirket size özel tanıtım yapmak, promosyon ve pazarlama teklifleri sunmak, web sitesinin veya mobil uygulamanın içeriğini size göre iyileştirmek ve/veya tercihlerinizi belirlemek amacıyla; web sitesi üzerinde gezinme bilgilerinizi ve/veya web sitesi üzerindeki kullanım geçmişinizi izleyebilmekte, ziyaretçi hareket ve tercihlerinizi analiz ederek yorumlamaktadır.
                            <br><br>
                            Şirket, ayrıca Sosyal medya platformları (örn. Facebook, Google sosyal paylaşım siteleri) aracılığıyla, www.linkadoo.me web sitesine veya uygulamalarına bağlanmanızı mümkün kılmakta olup böyle bir bağlantı sırasında, sizin ve sosyal medya arkadaşlarınızın, sosyal medya hesaplarında herkese açık olarak paylaştıkları profil bilgilerinizi ve kişisel verilerinizi, web sitelerinde ve uygulamalarında gezinme/trafik bilgileriniz ile birlikte toplayabilmektedir.
                            <br><br>
                            Şirket çevrimiçi ve çevrimdışı olarak toplanan bilgiler ile farklı yöntemlerle veya farklı zamanlarda site üzerinde sizden toplanan bilgileri eşleştirebilir ve bu bilgileri üçüncü taraflar gibi başka kaynaklardan alınan bilgilerle birlikte kullanabilir.
                            <br><br>
                            Belirtmek isteriz ki, www.linkadoo.me web sitesi, çerez (cookie) kullanan bir sitedir. Çerez; kullanılmakta olan cihazın internet tarayıcısına ya da sabit diskine depolanarak söz konusu cihazın tespit edilmesine olanak tanıyan, çoğunlukla harf ve sayılardan oluşan bir dosyadır.
                            <br><br>
                            www.linkadoo.me çerezleri; yaptığınız tercihleri hatırlamak ve web sitesi/mobil uygulama kullanımınızı kişiselleştirmek için kullanır. Bu kullanım parolanızı kaydeden ve web sitesi/mobil uygulama oturumunuzun sürekli açık kalmasını sağlayan, böylece her ziyaretinizde birden fazla kez parola girme zahmetinden sizi kurtaran çerezleri ve web sitesi/mobil uygulamaya daha sonraki ziyaretlerinizde sizi hatırlayan ve tanıyan çerezleri içerir.
                            <br><br>
                            www.linkadoo.me çerezleri; web sitesine nereden bağlandığınız, web sitesi/mobil uygulama üzerinde hangi içeriği görüntülediğiniz ve ziyaretinizin süresi gibi web sitesini/mobil uygulamayı nasıl kullandığınızın izlenmesi dahil olmak üzere; web sitesini/mobil uygulamayı nasıl kullandığınızı belirlemek için kullanır.
                            <br><br>
                            www.linkadoo.me web sitesindeki çerezler ilgi alanlarınıza göre, size daha uygun içerik ve reklamları sunmak için reklam/tanıtım amacıyla kullanılır. Bu şekilde, web sitesini/mobil uygulamasını kullandığınızda size daha uygun içerikleri, kişiye özel kampanya ve ürünleri sunar ve daha önceden istemediğinizi belirttiğiniz içerik veya fırsatları bir daha sunmaz.
                            <br><br>
                            www.linkadoo.me web sitesi çerezleri ayrıca; arama motorlarını, web sitesi, mobil uygulamasını ve/veya web sitesinin reklam verdiği internet sitelerini ziyaret ettiğinizde ilginizi çekebileceğini düşündüğü reklamları size sunabilmek için “reklam teknolojisini” devreye sokmak amacıyla kullanabilir. Reklam teknolojisi, size özel reklamlar sunabilmek için web sitesine/mobil uygulamaya ve web sitesinin reklam verdiği web sitelerine/mobil uygulamalarına yaptığınız önceki ziyaretlerle ilgili bilgileri kullanır.
                        </p>
                    </li> 
                    <li> <b>  Şirket kişisel verileri hangi amaçlarla kullanıyor? </b>
                        <p> 
                            Şirket, mevzuatın izin verdiği durumlarda ve ölçüde kişisel verilerinizi kaydedebilecek, saklayabilecek, güncelleyebilecek, üçüncü kişilere açıklayabilecek, devredebilecek, sınıflandırabilecek ve işleyebilecektir. Kişisel verilerinizin, tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla, otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hale getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlem "kişisel verilerin işlenmesi" olarak kabul edilmektedir. Kişisel verileriniz şu amaçlarla işlenmektedir:
                       </p>    
                       <ul>
                           <li>
                               İşlem yapanın/yaptıranın kimlik bilgilerini teyit etmek, 
                           </li>
                           <li>
                               İletişim için adres, telefon numarası, mail adresi ve diğer gerekli bilgileri kaydetmek,
                           </li>
                           <li>
                               Elektronik (internet / mobil vs.) veya kağıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri düzenleyebilmek,
                           </li>
                           <li>
                               Banka hesap bilgilerini alarak yapılacak ödeme işlemleri için kullanmak,
                           </li>
                           <li>
                               Siteye kaydolacak tüzel kişilerden şirkete ilişkin bilgileri alarak ödeme işlemlerinde kullanmak, 
                           </li>
                           <li>
                               IP bilgisini alarak hile, dolandırıcılık, sahtecilik işlemlerini anlayabilmek ve sipariş kodu sorunlarında hizmet neticesinde yapılacak ödemelerin hangi kullanıcıya yapılması gerektiğini anlamak, 
                           </li>
                           <li>
                               Cihaz ID bilgilerini alarak hile, dolandırıcılık, sahtecilik işlemlerini anlayabilmek ve sipariş kodu sorunlarında hizmet neticesinde yapılacak ödemelerin hangi kullanıcıya yapılması gerektiğini anlamak,
                           </li>
                           <li>
                               Kullanılan cihaz bilgilerini alarak kullanıcı analizi ve kullanıcı aktivitelerine ilişkin istatistikler oluşturmak,
                           </li>
                           <li>
                               Tıklanan link bilgisi ile kullanıcı analizi ve kullanıcı aktivitelerine ilişkin istatistikler oluşturmak,
                           </li>
                           <li>
                               Hizmetlerimiz ile ilgili müşteri şikâyet ve önerilerini değerlendirebilmek,
                           </li>
                           <li>
                               KVKK’dan doğan yükümlülüklerimizi yerine getirebilmek ve mevzuattan doğan haklarımızı kullanabilmek.
                           </li>
                       </ul>                
                    </li>
                    <br>
                    <li> <b> Şirket kişisel verilerinizi nasıl koruyor?</b>
                        <p> 
                        Paylaşmış olduğunuz tüm kişisel veriler, 6698 sayılı KVKK kapsamında “Veri Sorumlusu” sıfatına sahip Şirket’in gözetimi ve kontrolü altındadır. Şirket, yürürlükteki ilgili mevzuat hükümleri gereğince; bilginin gizliliğinin ve bütünlüğünün korunması amacıyla, gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak konusunda “Veri Sorumlusu” sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak, veri gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette veri işleme politikalarımızı her zaman güncellediğimizi bilginize sunarız.
                       </p>                    
                    </li>
                    <li> <b> Şirket kişisel verilerinizi paylaşıyor mu? </b>
                        <p>
                            Kişisel verilerinizin üçüncü kişiler ile paylaşımı, vermiş olduğunuz izin çerçevesinde gerçekleşmekte ve kural olarak, müşterimizin onayı olmaksızın kişisel verileri üçüncü kişilerle paylaşılmamaktadır. Bununla birlikte, yasal yükümlülüklerimiz nedeniyle ve bunlarla sınırlı olmak üzere, mahkemeler ve diğer kamu kurumları ile kişisel verileriniz paylaşılabilir. Ayrıca, taahhüt ettiğimiz hizmetleri sağlayabilmek ve verilen hizmetlerin kalite kontrolünü gerçekleştirebilmek için anlaşmalı olduğumuz üçüncü kişilere kişisel veri aktarımı yapılmaktadır. Üçüncü kişilere veri aktarımı sırasında hak ihlallerini önlemek için gerekli teknik ve hukuki önlemler alınmaktadır. Bununla birlikte, kişisel verileri alan üçüncü kişinin veri koruma politikalarından kaynaklanan ve üçüncü kişinin sorumluluğunda meydana gelen ihlallerden Şirket sorumlu değildir.
                        </p>            
                    </li>
                    <li> <b> Şirket kişisel verilerinizi yurtdışındaki 3. Kişilerle paylaşıyor mu? </b>
                        <p>
                            Kişisel verileriniz faaliyetlerimizi yürütebilmek için işbirliği yaptığımız program ortağı kurum, kuruluşlarla, verilerin bulut ortamında saklanması hizmeti aldığımız yurtiçi/yurtdışı kişi ve kurumlarla, web sitesi ve mobil uygulama konusunda altyapı hizmeti aldığımız firmalarla, onayınız olması halinde tarafınıza göndereceğimiz ticari elektronik iletilerin iletilmesi konusunda anlaşmalı olduğumuz yurtiçi/yurtdışındaki kuruluşlarla, Bankalar arası Kart Merkeziyle, anlaşmalı olduğumuz bankalarla ve sizlere daha iyi hizmet sunabilmek ve müşteri memnuniyetini sağlayabilmek için çeşitli pazarlama faaliyetleri kapsamında çalışmakta olduğumuz anket şirketleriyle ve yurtiçi/yurtdışı diğer üçüncü kişiler ve ilgili iş ortaklarımızla paylaşılabilmektedir.                        
                        </p>            
                    </li>
                    
                    <!--<li>
                        Paylaşım yaptığınız linkten gelen kullanıcının 5 gün içerisinde yaptığı alışverişlerden komisyon kazanabilirsiniz.
                    </li>-->
                    <li><b>Kişisel Verilerin Korunması Kanunu’ndan doğan haklarınız nelerdir?</b>
                    <p>
                        KVKK uyarınca kişisel verilerinizin;
                    </p>
                        <ol>
                            <li>
                                İşlenip işlenmediğini öğrenme,
                            </li>
                            <li>
                                İşlenmişse bilgi talep etme, 
                            </li>
                            <li>
                                İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,
                            </li>
                            <li>
                                Yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme,
                            </li>
                            <li>
                                Eksik / yanlış işlenmişse düzeltilmesini isteme,
                            </li>
                            <li>
                                KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,
                            </li>
                            <li>
                                KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,
                            </li>
                            <li>
                                KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,
                            </li>
                            <li>
                                Aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme,
                            </li>
                            <li>
                                Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
                            </li>
                            <li>
                                KVKK’ya aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme,
                            </li>
                            
                        </ol>
                        <br>
                        <p>
                            haklarına sahip olduğunuzu hatırlatmak isteriz.
                        </p>
                    </li>
                    <li><b>Kişisel verilerle ilgili mevzuat değişikliklerinden nasıl haberdar olabilirim</b>
                        <p>
                            Kişisel veriler ile ilgili mevzuatta değişiklik olması halinde, sayfamızda yer alan bilgileri yeni mevzuata uygun şekilde güncelleyeceğimizi, yapılan güncellemeleri de bu sayfa üzerinden her zaman kolaylıkla takip edebileceğinizi bildirmek isteriz.
                        </p>
                    </li>             
                    <li><b>Verinin güncel ve doğru tutulduğundan nasıl emin olabilirim?</b>
                        <p>
                            KVKK’nın 4. maddesi uyarınca Şirket’in kişisel verilerinizi doğru ve güncel olarak tutma yükümlülüğü bulunmaktadır. Bu kapsamda Şirket’in yürürlükteki mevzuattan doğan yükümlülüklerini yerine getirebilmesi için müşterilerimizin Şirket’le doğru ve güncel verilerini paylaşması gerekmektedir. Verilerinizin herhangi bir surette değişikliğe uğraması halinde aşağıda belirtilen iletişim kanallarından bizimle iletişime geçerek verilerinizi güncellemenizi rica ederiz.
                        </p>
                    </li>
                    <li><b>Şirket’e kişisel verilerinizle ilgili soru sormak ister misiniz?</b>
                        <p>
                            Veri sahibi olarak KVKK kapsamındaki haklarınıza ilişkin taleplerinizi www.linkadoo.me  web sitesinde yer alan mesaj butonuna tıklayarak veya “İletişim” kısmında yer alan formu doldurarak bize bildirebilirsiniz. Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, işbu Gizlilik ve Kişisel Verilerin Korunması Hakkında Bilgilendirme metninde belirtilen yöntemlerle Şirket’e iletmeniz durumunda Şirket talebinizi en geç 30 (otuz) gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, Kişisel Verileri Koruma Kurulu tarafından bir ücret öngörülmesi halinde, Şirket tarafından belirlenen tarifedeki ücret talep edilebilecektir.
                        </p>
                    </li>
                    </ol> 
                </div>
            </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>



.container{
    margin-top: 0px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
    font-size: 12px;
}

@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>