<template>
    <div style="padding-left: 0px" class="container">
            <div style="padding-bottom: 100px; " class="col col-lg-12  self-align-right login_container">
                <router-link to="/" class="navbar-brand offset-5">
                    <img src="../assets/linkadoo_logo_mini.png" style="width: 40px; height: 40px; margin-bottom: 40px; margin-top: 20px;"/>
                </router-link> 
                <div class="col col-lg-5 offset-lg-3 col-12" >
                <h2 style="text-align: center;">Sıkça Sorulan Sorular</h2>
                </div> 
                <div style="padding-top: 50px;" class="col col-lg-12 col-sm-12">   
                    <div id="accordion">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          1. Linkadoo nedir?
        </button>
      </h5>
    </div>
    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        Linkadoo temelde, kullanıcıların marka kampanya veya ürün linklerini kolayca paylaşıp, satış gerçekleştiğinde komisyon kazanmasını sağlayan yenilikçi bir platformdur.
        Bu platform ile marka ve kullanıcılarımızın maksimum fayda sağlayacağı bir ekosistem kurmayı amaçlıyoruz.      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          2. Linkadoo nasıl çalışır?
        </button>
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
Linkadoo ile anlaşmalı olduğumuz markaların beğendiğiniz ürünlerini, size özel linklere çevirerek paylaşabilir bu paylaşımlardan satış geldiğinde komisyonlar kazanabilirsin. Linkadoo ile kazanmak 3 basit yoldan geçer; 1- Ürün veya kampanya seçmek. 2- Özel Linkadoo linkini oluşturmak 3- Dilediğin platformda bu linki paylaşmak.      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          3. Linkadoo ile nasıl link üretirim?
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
Linkadoo'ya girdiğinde seni "link dönüştürme alanı" karşılar. Anlaşmalı markalarımıza ait paylaşmak istediğin linki ilk kutuya yapıştırmalısın. Aradaki "çift ok" simgesine tıkladığında ikinci kutuda sana özel oluşan Linkadoo linkini görebilirsin. İşte, linkini oluşturdun bile!      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style="text-align: left">
          4. Linkadoo ile nasıl paylaşım yapabilirim?
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
Link dönüştürme alanında oluşturduğun linki bu alanda bulunan paylaşım simgelerine tıklayarak hızlıca paylaşabilirsin. Eğer bu linki kopyalamak istiyorsan ilk sıradaki "kopyala" simgesine tıklayarak sana özel linki kopyalayabilir, dilediğin platformda paylaşabilirsin. Unutma, Linkadoo sen nerede paylaşırsan paylaş, linkine ait performans bilgilerini senin için toplayabilir.      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" style="text-align: left">
          5. İstediğim ürünü paylaşabilir miyim?
        </button>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
Evet istediğin ürünü paylaşabilirsin, istediğin içerikle paylaşabilirsin. Buradaki içeriğin sorumluluğu sana ait.      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingSix">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" style="text-align: left">
          6. Paylaştığım linkteki ürün değil de farklı bir ürün alınırsa para kazanabilir miyim?
        </button>
      </h5>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
      <div class="card-body">
Linkadoo platformu sadece paylaştığın ürün değil, paylaşımlarınla gelen herhangi bir satıştan da komisyon kazanabilrsin. Kazandığın komisyon, satılan ürünün kategorisine veya grubuna göre değişiklik gösterebilir.      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingSeven">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" style="text-align: left">
          7. Marka komisyonu ve kurallarını nasıl öğrenirim?
        </button>
      </h5>
    </div>
    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
      <div class="card-body">
Anlaşmalı markalar bölümünde yer alan markaların üzerindeki orandan, o markanın komisyon için belirlediği oranı görebilirsin. Bu orana tıklayarak marka kurallarına ulaşabilirsin.      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingEight">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight" style="text-align: left">
          8. Kazançlarımı nasıl görüntülerim?
        </button>
      </h5>
    </div>
    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
      <div class="card-body">
        Kazançlarını "cüzdan" bölümünde anlık olarak görebilirsin. Cüzdan bölümüne sağ üst köşede bulunan menüye tıklayrak ulaşabilirsin.      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingNine">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine" style="text-align: left">
          9. Kazançlarımı nasıl alabilirim?
        </button>
      </h5>
    </div>
    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordion">
      <div class="card-body">
        Şahıs şirketi olan veya vergi mükellefi olmayan kullanıcılarımıza yapılan ödemelerden internet reklam hizmetleri kapsamında 476 Sayılı Cumhurbaşkanlığı kararına istinaden % 15 stopaj kesintisini yapıp, bilgilerini iletmiş olduğun banka hesabına kazancını aktarıyoruz. 
        </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTen">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen" style="text-align: left">
          10. Kazançlarımı ne zaman alabilirim?
        </button>
      </h5>
    </div>
    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordion">
      <div class="card-body">

  “Çekilebilir Kazanç” tutarın 250 TL’yi geçtiğinde, kazancını almaya hak kazanırsın. Kazançlarının hesabına aktarılabilmesi için sana mail ile iletilen tarihlerde talep butonuna basman gereklidir. Kazancın markalardan tahsil edildiği ayın son gününe kadar hesabına iletilir. Fatura kesecek kullanıcılarımızın mail ile iletilen fatura kesim talimatlarına uygun olarak süreci izlemesi gerekmektedir.
  </div>
    </div>
  </div>
  
  <div class="card">
    <div class="card-header" id="headingEleven">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven" style="text-align: left">
          11. "Bekleyen Kazanç" nedir?
        </button>
      </h5>
    </div>
    <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordion">
      <div class="card-body">
Paylaşımlarından gerçekleşen satışla ile kazandığın komisyon tutarı ilk önce "Bekleyen Kazanç" alanı altında görüntülenir. Bu kazanç henüz marka tarafından onaylanmadığı veya tutarı iletilmediği için onaylanmayı beklemektedir.
 </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwelve">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve" style="text-align: left">
          12. "Onaylanan Kazanç" nedir?
        </button>
      </h5>
    </div>
    <div id="collapseTwelve" class="collapse" aria-labelledby="headingTwelve" data-parent="#accordion">
      <div class="card-body">
"Bekleyen Kazanç" bölümündeki kazancın marka tarafından onaylandıktan sonra "Onaylanan Kazanç" bölümüne geçer.
 </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThirteen">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen" style="text-align: left">
          13. Hangi durumlarda kazancım onaylanmaz?
        </button>
      </h5>
    </div>
    <div id="collapseThirteen" class="collapse" aria-labelledby="headingThirteen" data-parent="#accordion">
      <div class="card-body">
Marka ve platform kurallarına uymadığın durumlarda kazancın onaylanmaz. Sistemi kandırmaya yönelik yapılan işlemlerde üyelik sonlandırılır ve elde edilen kazanç sıfırlanır.
 </div>
    </div>
  </div>
  
</div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

button, button:active, button.collapsed, button:focus, button:hover{
    color: rgb(7, 164, 175);
}
.container{
    margin-top: 0px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>