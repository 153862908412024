<template>
    <div style="padding-left: 0px" class="container">
            <div style="padding-bottom: 100px; " class="col col-lg-12  self-align-right login_container">
                <router-link to="/" class="navbar-brand offset-5">
                    <img src="../assets/linkadoo_logo_mini.png" style="width: 40px; height: 40px; margin-bottom: 40px; margin-top: 20px;"/>
                </router-link> 
                <div class="col col-lg-5 offset-lg-3 col-12" >
                <h2 style="text-align: center;">Hesap Bilgilerin</h2>
                </div>
                <div style="padding-top: 50px;" class="col col-lg-12 col-sm-12">   
                    <form @submit.prevent="handleSubmit">
                        <div class="form-group col-lg-6 offset-lg-2 col-sm-12">
                            <label for="name">İsim Soyisim</label>
                            <input style="text-transform: uppercase" type="text" v-model="name" name="name" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                            <div v-show="submitted && !name" class="invalid-feedback">Kullanıcı Adı Gerekli</div>
                        </div>
                        <div class="form-group col-lg-6 offset-lg-2 col-sm-12">
                            <label for="iban">IBAN</label>
                            <input style="text-transform: uppercase" type="text" v-model="iban" name="iban" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                            <div v-show="submitted && !name" class="invalid-feedback">IBAN gerekli</div>
                        </div>
                        <div class="form-group col-lg-8 offset-lg-2 col-sm-12">
                            <button class="btn btn-primary kaydol" :disabled="false">Giriş Yap</button>
                        </div>
                    </form>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.error-div{
    display: block; 
    padding-left: 10px
}

.form-group{
  padding: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.container{
    margin-top: 30px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

.kaydol{
    background-color: rgb(7, 164, 175);
    border-color: rgb(7, 164, 175);
    
}

.kaydol:hover, .kaydol:focus, .kaydol:active{
   outline: none !important;
   box-shadow: none;

}

@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

}
</style>