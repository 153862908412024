<template>
<div class="container">
    <div class="col align-self-center">
        <h4> Heey! Linkadoo'ya tekrar gel ... </h4>

    </div>
</div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
   computed: {
        ...mapState('account', ['status'])
    },
    created () {
        // reset login status
        this.logout();
        window.location.href = '/'
    },
     methods: {
        ...mapActions('account', ['login', 'logout'])
     }
};
</script>

<style scoped>


@media screen and (max-width: 860px) {


}

</style>