import Vue from 'vue'
import App from './App.vue'
import {router} from './router.js'
import {store} from './_store'
import VueCookies from 'vue-cookies'


import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import 'vue-form-generator/dist/vfg.css'
import VueFormGenerator from 'vue-form-generator'
import ModuleLibrary1 from "vfg-field-array";
import ModuleLibrary from "vfg-field-object";
import SocialSharing from "vue-social-sharing";
import VueClipboard from 'vue-clipboard2'
import VueTableDynamic from 'vue-table-dynamic'


import LazyLoadDirective from './components/directives/LazyLoadDirective.js';

Vue.config.productionTip = false

Vue.use(VueFormGenerator);
Vue.use(ModuleLibrary);
Vue.use(ModuleLibrary1);
Vue.use(SocialSharing);
Vue.use(VueCookies);
Vue.use(VueClipboard);
Vue.use(VueTableDynamic)


Vue.directive("lazyload", LazyLoadDirective);

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});
