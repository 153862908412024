var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-md navbar-light bg-light fixed-top navbar-transition",staticStyle:{"padding-bottom":"0px"},on:{"&scroll":function($event){return _vm.handleScroll()}}},[_vm._m(0),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarNavAltMarkup","aria-controls":"navbarNavAltMarkup","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":function($event){return _vm.togglerClick()}}},[_vm._m(1)]),_c('div',{staticClass:"container d-md-none"},[_c('div',{staticClass:"collapse navbar-collapse d-md-none",attrs:{"id":"navbarNavAltMarkup"}},[_c('ul',{staticClass:"navbar-nav w-100 justify-content-center",attrs:{"id":"root"},on:{"click":function($event){_vm.isShow = !_vm.isShow}}},[_vm._m(2),_c('transition',{attrs:{"name":"slide"}},[(_vm.isShow)?_c('div',{staticClass:"child",staticStyle:{"border":"0","padding-top":"0px"}},[_c('a',{staticClass:"dropdown-item",staticStyle:{"color":"white"},attrs:{"href":"#features"}},[_vm._v("Nedir?"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])]),_c('a',{staticClass:"dropdown-item",staticStyle:{"color":"white"},attrs:{"href":"#nasil-kullanirim-1"}},[_vm._v("Nasıl Kullanırım?"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])]),_c('a',{staticClass:"dropdown-item",staticStyle:{"color":"white"},attrs:{"data-toggle":"modal","data-target":"#appModal","href":""}},[_vm._v("Uygulamayı İndir"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])]):_vm._e()]),_vm._m(3),_vm._m(4),_vm._m(5)],1)])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",staticStyle:{"padding-left":"15px"},attrs:{"href":"/"}},[_c('img',{staticClass:"d-none d-md-block",attrs:{"src":require("../../assets/logo_beyaz.png"),"width":"140","height":"35"}}),_c('img',{staticClass:"d-md-none",staticStyle:{"width":"35px","height":"35px"},attrs:{"src":require("../../assets/linkadoo_logo_mini_beyaz.png"),"width":"35","height":"35"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated-icon1"},[_c('span'),_c('span'),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link pl-3",staticStyle:{"color":"white"},attrs:{"href":"#","id":"navbarDropdown","role":"button"}},[_vm._v("ÖNERİLERİNLE KAZAN"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-3",staticStyle:{"color":"white"},attrs:{"href":"/login"}},[_vm._v("Giriş Yap"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-3 signup-mobile",staticStyle:{"color":"white"},attrs:{"href":"/signup"}},[_vm._v("Kayıt Ol"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-3",staticStyle:{"color":"white"},attrs:{"href":"/business"}},[_vm._v("MARKALAR İÇİN"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collapse navbar-collapse d-none d-md-block",attrs:{"id":"navbarNavAltMarkup"}},[_c('ul',{staticClass:"navbar-nav w-100 justify-content-center",staticStyle:{"padding-left":"0px!important"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-3",staticStyle:{"color":"white"},attrs:{"href":"#what"}},[_vm._v("Nedir?"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-3",staticStyle:{"color":"white"},attrs:{"href":"#nasil-kullanirim-1"}},[_vm._v("Nasıl Kullanırım?"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-3",staticStyle:{"color":"white"},attrs:{"href":"/login"}},[_vm._v("Giriş Yap"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])]),_c('li',{staticClass:"nav-item signup"},[_c('a',{staticClass:"nav-link pl-3",staticStyle:{"color":"white"},attrs:{"href":"/signup"}},[_vm._v("Kayıt Ol"),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])])])])
}]

export { render, staticRenderFns }