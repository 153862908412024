<template>
    <section class="linkadoo-layer">
        <div class="shape">
			<img src="../../assets/layer2.svg">
		</div>
    </section>
</template>

<script>
export default {
    name: "LinkadooLayer"
}
</script>

<style scoped>
.linkadoo-layer{
    background: trasparent;
    padding-top: 45px;
    position: relative
}

.linkadoo-layer .shape{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transform: translateY(-85%);
}
.shape > img{
    width: 100%;
    max-width: initial;
}

@media (min-width: 992px) {
    .linkadoo-layer {
        padding-top: 80px;
    }
}
</style>