<template> 
<section id="features" class="linkadoo-features d-flex justify-content-center">
<div class="col-12 col-md-8 mt-4">
    <h3 style="text-align: center">Linkadoo ile Kazanmaya Bugün Başla!</h3>
    <div class="row mt-4">
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/onerilerinle_kazan.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title"><b>Önerilerinle Kazan</b></h5>
                    <p class="card-text">Arkadaşlarına veya takipçilerine önerdiğin ürünler satıldıkça kazanıp, önerilerine kolayca değer katabilirsin.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/share_link.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title" >Kolayca Link Oluştur</h5>
                    <p class="card-text">Linkadoo'da beğendiğin ürünlerin linklerini bir tıklama ile sana özel linklere çevirebilir, kolayca paylaşım yapabilirsin.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/social_sharing1.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title">Dilediğin Yerden Paylaş</h5>
                    <p class="card-text">Sana özel Linkadoo linkini, sadece sosyal medya araçlarından değil, mesajlaşma uygulamaları dahil istediğin tüm platformlardan sosyal çevrenle paylaşabilirsin.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/social_interaction.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title">Favori Markalarına Tek Tıkla Ulaş</h5>
                    <p class="card-text">Linkadoo ile beğendiğin tüm markaların kampanyalarını paylaşarak para kazanabilirsin. Bunun için markalarla birebir anlaşmalar yapmak zorunda kalmazsın.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/post_analysis2.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title">Paylaşımlarını Analiz Et</h5>
                    <p class="card-text">Yaptığın paylaşımların ne kadar tıklandığını, hangi paylaşımdan ne kadar satış yaptığını ve hangi markaların kampanyalarının kitlene daha uygun olduğunu analiz edebilirsin.</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="card border-light mb-3">
                <div class="card-header" style="background-color: white; border: none; padding-top: 8.33%;">
                    <img src="../../assets/social_interaction.svg" width="60" height="60">
                </div>
                <div class="card-body">
                    <h5 class="card-title">Takipçi Sayınla Değil Etkileşiminle Kazan</h5>
                    <p class="card-text">Unutma! Önemli olan ne kadar insanı harekete geçirebildiğin. Linkadoo'da takipçi sayınla değil etkileşim sayınla kazanırsın. Ayrıca sosyal medya uygulamaları ile sınırlı değilsin. Mesajlaşma uygulamaları ile arkadaşlarına kolayca ulaşabilirsin.</p>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
</template>

<script>
export default {
    name: "LinkadooFeatures",
}
</script>

<style scoped>

.linkadoo-features{
    background-color: #eef4f5;
}

.border-light{
    border-radius: 5px;
    border: 1px solid #d9e4e6 !important;
}

img{
    background-color: RGBA(230,71,105,0.4);
    border-radius: 80%;
    z-index: 10;

}

.card-columns{
    column-count: 3;
}

@media screen and (min-width: 768px) {
}

@media screen and (max-width: 767px) {
    .card-columns{
        column-count: 1;
    }
}
</style>