<template>
  <form class="link-creator" novalidate>
    <div
      class="form-row align-items-center link-key-form"
      style="padding-left: 15px; padding-right: 10px"
    >
      <div class="col-md-7" style="padding: 0px">
        <p
          class="offset-3 offset-md-4"
          style="
            color: white;
            padding-top: 10px;
            margin-bottom: 10px;
            font-size: 15px;
          "
        >
          Sana Özel Linkini Oluştur
        </p>
        <vue-form-generator :schema="schema" :model="model">
        </vue-form-generator>
      </div>
      <!--<button type="submit" class="btn btn-outline-primary offset-1 col-3">
                <i class="fab fa-facebook "></i>Facebook'ta Paylaş</button>-->
      <!--<button type="button" class="btn btn-primary btn-circle col-lg-2 col-sm-12 social-btn-circle">
                    <img src="../assets/icons/copy.svg">
                </button>-->
      <div class="row col-12 col-md-4 share-bucket" style="padding-top: 0px">
        <p class="col-9 offset-3 offset-md-1 col-md-11 social-header">
          Dilediğin Gibi Paylaş
        </p>
        <div class="col-3" style="background: transparent; padding-right: 0px">
          <button
            v-clipboard:copy="model.linkadoo_url"
            v-clipboard:error="onError"
            class="btn btn-primary btn-circle social-btn-circle copy"
            type="button"
            data-toggle="modal"
            data-target="#appModal"
          >
            <img
              class="copy_mobile"
              src="../../assets/icons/copy.svg"
              data-intro="Linkadoo linkini buradan kopyalayabilirsin"
            />
          </button>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="appModal" role="dialog">
          <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content header-modal">
              <div class="modal-header">
                <p class="modal-title center-text">
                  Ürün Linkin Kopyalandıı, Hemen şimdi Paylaş!
                </p>
                <button
                  type="button"
                  class="close shadow-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
              </div>
              <!--<div class="modal-body">
                                <p> Bu ürün linkini tüm çevrenle paylaş, paylaştıkça kazan!!</p>
                            </div>
                            <div class="modal-footer d-flex justify-content-between"> 
                            </div>-->
            </div>
          </div>
        </div>

        <!--<div v-if="domainContains(model.product_url)" class="modal fade" id="modalEnglishHome" role="dialog">
                    <div class="modal-dialog">
                        
                        <div class="modal-content header-modal">
                            <div class="modal-header">
                                <p class="modal-title center-text"></p>
                                <button type="button" class="close shadow-none" data-dismiss="modal" aria-label="Close">&times;</button>
                            </div>
                            <div class="modal-body">
                                <p> MarkanınX “beta” dönem değerlendirmesi bekleniyor. Değerlendirme sonuçlandıktan sonra tekrar link oluşturabilirsiniz.</p>
                                    <div class="col-12 offset-lg-3 col-lg-6 col-lg-6 btn-next-wrapper justify-content-start" style="text-align: center;">
                                        <button style="width: 100%; color: white" type= "button" class="btn btn-next" data-dismiss="modal" aria-label="Close"
                                        v-on:click="createLink(model)"
                                        >Anladım</button>
                                    </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-between"> 
                            </div>
                        </div>
                    </div>
                </div>-->

        <social-sharing
          v-bind:url="model.linkadoo_url"
          quote="deneme"
          hashtags="Linkadoo"
          v-model="model"
          network-tag="span"
          title="Heey! Bir Göz at istersen, beğeneceğin şeyler olabilir!"
          description="Heey! Bir Göz at istersen, beğeneceğin şeyler olabilir!"
          inline-template
        >
          <div
            class="row col-9"
            style="padding-left: 0px"
            data-intro="Linkadoo linkini Twitter, Facebook ve Whatsapp'ta paylaşmayı buradan yapabilirsin"
          >
            <div class="col-3 offset-1">
              <network
                class="btn btn-primary btn-circle social-btn-circle twitter"
                network="twitter"
              >
                <!--<i class="fab fa-twitter"></i>Twitter'da Paylaş-->
                <img src="../../assets/icons/tw.svg" />
              </network>
            </div>

            <div class="col-3 offset-1">
              <network
                class="btn btn-primary btn-circle social-btn-circle"
                network="facebook"
              >
                <!--<i class="fab fa-facebook"></i>Facebook'ta Paylaş-->
                <img src="../../assets/icons/fb.svg" />
              </network>
            </div>

            <div class="col-3 offset-1">
              <network
                class="btn btn-primary btn-circle social-btn-circle"
                network="whatsapp"
              >
                <!--<i class="fab fa-whatsapp"></i>Whatsapp'ta Paylaş-->
                <img src="../../assets/icons/wp.svg" />
              </network>
            </div>
          </div>
        </social-sharing>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { reject } from "q";
import VueFormGenerator from "vue-form-generator";

export default {
  name: "CreateLinkContainer",
  data() {
    return {
      msg: "Paylaşmak İstediğin Linki Buraya Koy",
      reflink: "",
      model: {
        product_url: "",
        linkadoo_url: "",
        source: "mweb",
      },
      is_approved_english: false,
      schema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            placeholder: "Paylaşmak İstediğin Linki Buraya Koy",
            //label: 'Product Link',
            model: "product_url",
            featured: false,
            required: true,
            styleClasses: "col-md-5 input-link",
            attributes: {
              wrapper: {
                "data-intro":
                  "Buraya paylaşmak istediğin ürün linkini koyabilirsin",
              },
            },
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Önce Link Girmelisin !!",
            }),
          },
          {
            type: "submit",
            buttonText: " ",
            id: "convert",
            validateBeforeSubmit: true,
            styleClasses: "col-1 align-items-center offset-5",
            onSubmit: function (model) {
              model.aff_id = JSON.parse(localStorage.user).aff_id;
              /*if(model.product_url.includes('englishhome') == false)
                        {
                            axios({ method: "POST", "url": "https://www.linkadoo.co/api/v1/links/", "baseURL": "", "data": model, "headers": {"content-type": "application/json"} }).then(result => {
                                if(result.data.message == "success")
                                    model.linkadoo_url = "https://www.linkadoo.co/" + result.data.data.vendor_name + '/' + result.data.data.link_key;
                                else if(result.data.message == "fail")
                                    model.linkadoo_url = result.data.data;
                            }, error => {
                                reject(error);
                            });
                        }*/
              axios({
                method: "POST",
                url: "https://www.yukarikaydir.com/api/v1/links/",
                baseURL: "",
                data: model,
                headers: { "content-type": "application/json" },
              }).then(
                (result) => {
                  if (result.data.message == "success")
                    model.linkadoo_url =
                      "https://www.yukarikaydir.com/" +
                      result.data.data.vendor_name +
                      "/" +
                      result.data.data.link_key;
                  else if (result.data.message == "fail")
                    model.linkadoo_url = result.data.data;
                },
                (error) => {
                  reject(error);
                }
              );
            },
            attributes: {
              "data-intro": "Linkini dönüştürmek için bu butonu kullan",
              "data-toggle": "modal",
              "data-target": "#modalEnglishHome",
            },
          },
          {
            type: "input",
            inputType: "text",
            //label: 'Linkadoo Link',
            model: "linkadoo_url",
            featured: true,
            required: true,
            readonly: true,
            styleClasses: "col-md-6 output-link",
            attributes: {
              wrapper: {
                "data-intro": "Oluşan Linkadoo linkini burada görebilirsin",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    onError: function () {
      alert("Failed to copy texts");
    },
    /*domainContains(url){
            var res = false
            if (url.includes('englishhome')){
                res = true
            }
            return res
        },*/
    createLink(model) {
      model.aff_id = JSON.parse(localStorage.user).aff_id;
      axios({
        method: "POST",
        url: "https://www.yukarikaydir.com/api/v1/links/",
        baseURL: "",
        data: model,
        headers: { "content-type": "application/json" },
      }).then(
        (result) => {
          if (result.data.message == "success")
            model.linkadoo_url =
              "https://www.yukarikaydir.com/" +
              result.data.data.vendor_name +
              "/" +
              result.data.data.link_key;
          else if (result.data.message == "fail")
            model.linkadoo_url = result.data.data;
        },
        (error) => {
          reject(error);
        }
      );
    },
  },
};
</script>

<style>
.btn-next {
  text-align: center;
  color: #fff;
  background-color: rgb(7, 164, 175);
  border-color: rgb(7, 164, 175);
  border-radius: 30px;
}

.header-modal {
  background-color: white;
  border-radius: 10px;
  color: #e64769;
}

.modal-header {
  padding: 1.5rem 2.5rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  padding-left: 2.5rem;
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
  padding-top: 0.5%;
}

.modal-title {
  padding-top: 1rem;
}

@media (min-width: 768px) {
  .share-bucket {
    margin-left: 4%;
  }

  .input-link {
    padding-right: 0px;
    padding-left: 0px;
  }

  .field-submit {
    margin-left: 0px;
  }
}

.form-group.error input:not([type="checkbox"]) {
  background-color: white !important;
}

.form-group.error input:not([type="checkbox"]) {
  color: white !important;
}

#convert {
  background-color: transparent !important;
  border: none;
  width: 20px;
  height: 30px;
  background: url("../../assets/icons/convert.svg");
  background-position: center;
  background-size: 100% 90%;
}

.field-submit {
  padding-right: 0px;
}
.form-row {
  margin-right: 0px;
}
.social-header {
  color: white;
  padding-top: 25px;
  margin-bottom: 10px;
  font-size: 15px;
}

.link-creator {
  background: linear-gradient(to right, rgb(7, 164, 175), #e64769) !important;
}

.twitter {
  width: 42px !important;
}

.social-btn-circle {
  background: transparent !important;
  width: 40px;
  padding: 0px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  border: None;
  text-align: center;
  font-size: 12px;
}

.field-wrap button {
  background-color: transparent !important;
  border: none !important;
}

.input-group-text {
  background-color: white;
  width: 70px;
  border: none;
  text-align: right !important;
}

@media screen and (max-width: 767.98px) {
  .copy_mobile {
    width: 42px !important;
    height: 60px !important;
    padding-bottom: 5px !important;
  }

  .field-wrap {
    display: block !important;
  }

  .buttons {
    padding-left: 45% !important;
    padding-top: 0%;
  }
  #convert {
    transform: rotate(-90deg);
    background-size: 100% 100%;
  }
  .field-input {
    margin-bottom: 0px !important;
  }

  .social-header {
    padding-top: 10px !important;
    margin-bottom: 0px !important;
  }

  .twitter {
    padding-top: 1% !important;
  }

  .social-btn-circle {
    width: 60px !important;
    padding-left: 25% !important;
    margin-right: 5% !important;
  }

  .link-key-form {
    margin-right: 0px;
  }

  .field-submit {
    margin-bottom: 0% !important;
  }
}
</style>
