<template>
    <div class="col-3 align-self-start category_navigation d-none d-lg-block">
        <div class="categori_bucket">
            <router-link to="/home/markalar/all-categories/a" class="nav-link">
            <h4>Tüm Kategoriler</h4>
            </router-link>

            
        <ul class= "category_list" style="list-style-type:none; padding-left: 10px; line-height: 2.0;">
            <li><router-link to="/home/markalar/all-categories/Moda">Moda</router-link></li>
            <li><router-link to="/home/markalar/all-categories/Kozmetik&KisiselBakim">Kozmetik & Kisisel Bakim</router-link></li>
            <!--<li><router-link to="/home/markalar/all-categories/Seyahat">Seyahat</router-link></li>-->
            <li><router-link to="/home/markalar/all-categories/Elektronik">Elektronik</router-link></li>
            <li><router-link to="/home/markalar/all-categories/Ev&Yasam">Ev & Yaşam</router-link></li>
            <li><router-link to="/home/markalar/all-categories/Yemek&Supermarket">Yemek & Süpermarket</router-link></li>
        </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "CategoryFilter",
    data(){
        return {

        }
    },
    methods: {

    }
}
</script>

<style scoped>

.category_navigation{
    border: 0px solid #ddd;
    display: inline-block;
    width:  100%; 
    float:none;
    background: white !important;
    border-radius: 5px 5px 5px 5px;
    /*margin-right: 0%;*/
}

.categori_bucket > a >  h4{
    font-size: 1rem;
    color: #636F7B;
}

.categori_bucket{
    padding-top: 15%;
}


.category_list> li >a {
    font-size: 0.75rem;
    font-weight: 400;
    color: rgb(75, 74, 74)  !important; /**/
    font-size: 13px;

}

.category_list> li >a:hover {
    color: gray !important;

}

.category_list> li:hover{
    /*: rgba(0,0,0,0.58) !important;*/
    /*background-color: rgba(0, 0, 0, 0.18); */
    text-decoration: none;
    outline-color: none !important;
}
 .category_list> li >:hover {
    text-decoration: none;
    outline-color: none !important;

 }

 
</style>