import { render, staticRenderFns } from "./ChakraDetail.vue?vue&type=template&id=02a58e72&scoped=true&"
import script from "./ChakraDetail.vue?vue&type=script&lang=js&"
export * from "./ChakraDetail.vue?vue&type=script&lang=js&"
import style0 from "./ChakraDetail.vue?vue&type=style&index=0&id=02a58e72&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a58e72",
  null
  
)

export default component.exports