<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row justify-content-start">
        <div class="col-lg-8 col-md-8 col-sm-4 d-md-block">
          <div class="row justify-content-start">
            <div
              class="col-lg-4"
              v-for="dataset in chart_data.datasets"
              :key="dataset.label"
            >
              <label class="checkbox">
                <input
                  type="checkbox"
                  :value="dataset.label"
                  v-model="selectedLabels[dataset.label]"
                />
                {{ dataset.label }}
              </label>
            </div>
          </div>

          <div v-if="bar_selected">
            <button v-on:click="toggleDisplay">Line Chart</button>
            <bar-chart
              id="mybarchart"
              ref="mybarchart"
              v-if="chart_loaded"
              :chartdata="displayed_chart_data"
              :options="options"
            />
          </div>
          <div v-if="line_selected">
            <button v-on:click="toggleDisplay">Bar Chart</button>
            <line-chart
              v-if="chart_loaded"
              :chartdata="displayed_chart_data"
              :options="options"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-2 col-sm-2 d-md-block">
          <multiselect
            v-model="selected_date_option"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Süre Seçin"
            :options="date_options"
          ></multiselect>
        </div>

        <div>
          <vue-table-dynamic
            v-if="table_loaded"
            :params="table_params"
            ref="table"
          ></vue-table-dynamic>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
import LineChart from "../components/LineChart.js";
import BarChart from "../components/BarChart.js";
import Multiselect from "vue-multiselect";
import VueTableDynamic from "vue-table-dynamic";

//import Vue from 'vue';
export default {
  name: "AffDashboardDetail",
  components: {
    BarChart,
    LineChart,
    Multiselect,
    VueTableDynamic,
    //mdbDatachart2
    //LoginHistoryFeed,
  },
  data() {
    return {
      bar_selected: true,
      line_selected: false,
      chart_loaded: false,
      table_loaded: false,
      selectedLabels: {},
      chart_data: {
        labels: [],
        datasets: [],
      },
      displayed_chart_data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        lineTension: 1,
        distributed: true,
        enableAnimation: true,
        legend: {
          display: false,
        },
      },
      table_params: {
        data: [],
        header: "row",
        border: true,
        stripe: true,
        sort: [2, 3, 4],
        enableSearch: true,
        pagination: true,
        pageSize: 10,
        fixed: 0,
        pageSizes: [10, 20, 50],
        columnWidth: [
          { column: 0, width: "%10" },
          { column: 1, width: "60%" },
          { column: 2, width: "10%" },
          { column: 3, width: "10%" },
          { column: 4, width: "10%" },
        ],
      },
      date_options: ["Son Gün", "Son 3 Gün", "Son Hafta", "Son Ay", "Son 6 Ay"],

      selected_date_option: "Son Hafta",
      days: 0,
      weeks: 1,
      months: 0,
    };
  },
  created() {},

  async mounted() {
    this.chart_loaded = false;
    this.table_loaded = false;
    try {
      if (localStorage.user) {
        var userID = JSON.parse(localStorage.user);
        var aff_id = userID.aff_id;
        var trxQuery =
          "?days=" +
          this.days +
          "&weeks=" +
          this.weeks +
          "&months=" +
          this.months +
          "&aff_id=" +
          aff_id;
        const resp = await Axios.get(
          "https://ulan-gitlab-heroku-env-staging.herokuapp.com/transactions/getTransactionsByDate" +
            trxQuery
        );
        this.displayed_chart_data.datasets = resp.data.datasets;
        this.displayed_chart_data.labels = resp.data.labels;
        this.chart_data.datasets = resp.data.datasets;
        this.chart_data.labels = resp.data.labels;

        this.chart_loaded = true;
      }

      var linkQuery =
        "?days=" +
        this.days +
        "&weeks=" +
        this.weeks +
        "&months=" +
        this.months +
        "&aff_id=" +
        aff_id;
      const resp = await Axios.get(
        "https://www.yukarikaydir.com/api/v1/linkStatistics/query" + linkQuery
      );
      alert(resp.data);
      this.table_params.data = resp.data;
      this.table_loaded = true;
    } catch (err) {
      this.error = err.message;
    }
  },
  methods: {
    toggleDisplay() {
      this.bar_selected = !this.bar_selected;
      this.line_selected = !this.line_selected;
    },
    selectLabel(payload) {
      this.chart_loaded = false;
      const indexes = [];
      alert(payload);
      for (var label of payload) {
        if (payload[label] == true)
          indexes.push(this.chart_data.labels.indexOf(payload[label]));
      }

      const indexOfLabeledData = this.chart_data.labels.indexOf(payload);
      this.displayed_chart_data.datasets =
        this.chart_data.datasets[indexOfLabeledData];
      this.displayed_chart_data.labels =
        this.chart_data.labels[indexOfLabeledData];
      this.chart_loaded = true;
    },
    async selectDate(payload) {
      switch (payload) {
        case "Son Gün":
          this.days = 1;
          this.weeks = 0;
          this.months = 0;
          break;
        case "Son 3 Gün":
          this.days = 3;
          this.weeks = 0;
          this.months = 0;
          break;
        case "Son Hafta":
          this.days = 0;
          this.weeks = 1;
          this.months = 0;
          break;
        case "Son Ay":
          this.days = 0;
          this.weeks = 0;
          this.months = 1;
          break;
        case "Son 6 Ay":
          this.days = 0;
          this.weeks = 0;
          this.months = 6;
          break;
        default:
          this.days = 0;
          this.weeks = 1;
          this.months = 0;
          break;
      }
      this.chart_loaded = false;
      this.table_loaded = false;
      try {
        if (localStorage.user) {
          var userID = JSON.parse(localStorage.user);
          var aff_id = userID.aff_id;
          var trxQuery =
            "?days=" +
            this.days +
            "&weeks=" +
            this.weeks +
            "&months=" +
            this.months +
            "&aff_id=" +
            aff_id;
          const resp = await Axios.get(
            "https://ulan-gitlab-heroku-env-staging.herokuapp.com/transactions/getTransactionsByDate" +
              trxQuery
          );
          this.chart_data.datasets = resp.data.datasets;
          this.chart_data.labels = resp.data.labels;
          this.chart_loaded = true;

          var linkQuery =
            "?days=" +
            this.days +
            "&weeks=" +
            this.weeks +
            "&months=" +
            this.months +
            "&aff_id=" +
            aff_id;
          const resp2 = await Axios.get(
            "https://www.yukarikaydir.com/api/v1/linkStatistics/query" +
              linkQuery
          );
          this.table_params.data = resp2.data;
          this.table_loaded = true;
        }
      } catch (err) {
        this.error = err.message;
      }
    },
  },
  watch: {
    selected_date_option: async function () {
      this.selectDate(this.selected_date_option);
    },
    selectedLabels: {
      handler: function () {
        alert(JSON.stringify(this.selectedLabels));
        this.selectLabel(this.selectedLabels);
      },
      deep: true,
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
body {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.content {
  padding: 0 30px 30px;
  min-height: calc(100vh - 123px);
  margin-top: 0px;
}

.label-container {
  flex-direction: row;
}

.content .row-container {
  padding-top: 10px;
}
</style>
