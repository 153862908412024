<template>
    <section id="how" >
        <div class="container">
            <div class="col-12 mb-5 how-center">
                <h1>Yeteneklerimiz</h1>
                <hr/>
                <div class = "row">
                    <div class="col-sm-4 mobil">
                        <img src="../../assets/campaign.png" class="centered">
                        <br>
                        <h4 style="text-align:center">Kampanya Oluşturun</h4>
                        <br class="d-none d-sm-block"><br class= "d-none d-sm-block">
                        <p style="text-align:center">Size uygun komisyon oranı ile müşterilerinize ulaşmak istediğiniz kampanyalarınızı duyurmak için, özel kampanyalarınızı oluşturun</p>
                    </div>
                    <div class="col-sm-4 mobil">
                            <img src="../../assets/segment.svg" class="centered">
                            <br>
                            <h4 style="text-align:center">Segmentlere Göre Özelleştirin</h4>
                            <br class="d-none d-sm-block">
                            <p style="text-align:center">Kategorilere, geçmiş performasına veya demografiye göre özelleştirilmiş segmentlere özel kampanyalar yaparak kazancınızı arttırın.</p>
                    </div>
                    <div class="col-sm-4 mobil">
                            <img src="../../assets/analysis.png" class="centered"> 
                            <br>
                            <h4 style="text-align:center">Analiz edin ve Geliştirin</h4>
                            <br class="d-none d-sm-block"><br class="d-none d-sm-block">
                            <p style="text-align:center">Size özel raporları analiz ederek kampanyalarınızı geliştirin ve kampanyalarınızın verimini arttırın.</p>
                    </div>
                </div>
            </div>
        </div>
    
        </section>
</template>

<script>
export default {
    name: "WhatBussIntro"
    
}
</script>

<style scoped>

#how{
    margin-left: -21.4%;
    width: 100vw;
    background-color: white !important;
    
}

.how-center{
    padding: 10% 10%;
    margin-bottom: 0% !important;
}

.centered{
    display: block;
    width: 100px; /*40%;*/
    margin-left: auto;
    margin-right: auto;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid #E64769;
    margin: 1em 0;
    padding: 0;
    width: 35%;
}

@media (max-width: 767px){
    .mobil{
        padding-bottom: 10% !important;
    }
    #how{
        margin-left: 0px;

    }
}

</style>