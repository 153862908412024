<template>
    <div style="padding-left: 0px" class="container">
            <div style="padding-bottom: 100px; " class="col col-lg-12  self-align-right login_container">
                <div style="text-align: center;">
                    <router-link to="/" class="navbar-brand">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/campaign-details-logo%2Fteknosa_logo.png?alt=media&token=77b5d279-dea2-4e8f-b53a-30d1de9bc1be" style="width: 180px; height: 180px; margin-bottom: 0px; margin-top: 20px;"/>
                    </router-link>
                    <h4>Komisyon Oranları</h4>
                </div>  
                <div style="padding-top: 0px;" class="col col-lg-12 col-sm-12"> 
                    <div class="commission-detail">
                    <div v-for="(group, index) in groups" 
                    v-bind:item = "group"
                    v-bind:index = "index"
                    v-bind:key = "index">
                        <div v-on:click="group.ishidden = !group.ishidden"
                        style="background-color: #F0F0F0; display: flex; justify-content: space-between; align-items: center; position: relative; "
                         class="category-detail"
                         v-if="index % 2 == 0"
                         >
                         
                            <div class="category-name">
                                {{ group.name }}
                            </div>
                            <div class="category-commission">
                                {{ group.rate }} %
                            <img src="../assets/icons/more_button.svg" width="10" height="10">
                            </div>
                        </div>
                        <div v-on:click="group.ishidden = !group.ishidden"
                        style="background-color: white; display: flex; justify-content: space-between; align-items: center;"
                         class="category-detail"
                         v-else>
                         
                            <div class="category-name">
                                {{ group.name }}
                            </div>
                            <div class="category-commission">
                                {{ group.rate }} %
                            
                            <img src="../assets/icons/more_button.svg" width="10" height="10">
                            </div>
                        </div>
                        <span style="display: block; overflow-y: auto; max-height: 160px;" v-if="!group.ishidden">
                                <div  style="padding-left: 10px;" 
                                v-for="(category, cat_index) in group.categories" 
                                v-bind:item = "category"
                                v-bind:index = "cat_index"
                                v-bind:key = "cat_index"
                                >
                                <div v-if="cat_index % 2 == 0" style="background-color: #F0F0F0">
                                    {{ category}}
                                </div>
                                <div v-else style="background-color: white">
                                    {{ category}}
                                </div>
                            </div>
                                
                        </span>
                    </div>
                    </div>  
                    <ul style="padding-top: 20px;">
                    <li>
                        Kampanya <a style="color: rgb(7, 164, 175)" href="https://www.teknosa.com/" target="_blank">teknosa.com</a> sitesinden yapılacak tüm alışverişlerde geçerlidir.
                    </li>
                    <li>
                        Google, Facebook ve diğer reklam ağlarının paralı reklam seçenekleri ile getirilen trafikten komisyon kazanamazsınız.

                    </li>
                    <li>
                        Fraud yöntemleri ile sistemi kandırmaya çalışan kullanıcılarımız kalıcı olarak üyelikten çıkarılacak, önceden devreden komisyon hakları sıfırlanacaktır.
                    </li>
                    <!--<li>
                        Paylaşım yaptığınız linkten gelen kullanıcının 5 gün içerisinde yaptığı alışverişlerden komisyon kazanabilirsiniz.
                    </li>-->
                    <li>
                        Her ayın 25'inden önce <b>“Onaylanan Kazanç”</b> alanında biriken tutarı çekmeyi talep edebilirsiniz. Bu tutar sonraki ayın 15'ine kadar hesabınıza devletin zorunlu tuttuğu vergiler düşülerek aktarılacaktır.
                    </li>
                    <li>
                        <b>“Onaylanan Kazanç”</b> alanındaki tutar 100 TL’yi geçtiğinde komisyonu çekme talebi iletebilirsiniz                    
                    </li>   
                    <li>
                        Kazanılan komisyon tutarı, ürünün kargo hariç bedeli üzerinden hesaplanmaktadır.
                    </li>           
                    <li>
                        Paylaşımlarınızda kullandığınız içerik yasadışı, argo, müstehcen ve zararlı nitelikte olmamalıdır. Bu tür paylaşımlardan elde edilen gelir red edilecektir.
                    </li>
                    <li>
                        Linkadoo komisyon ve kampanya koşullarında değişiklik yapma hakkını saklı tutar                    
                    </li>
                    <li>
                        Satın alım yapan kullanıcının internet tarayıcısı nedenli; çerez engelleme, internet erişim sorunu nedeniyle sipariş onaylanmasının Linkadoo’ya yapılamaması gibi durumlarda data alınamayacağından komisyon yansıtılamayacaktır. Bu gibi bir durum yaşadığınızda <b>info@linkadoo.me</b> mail adresi ile iletişime geçebilirsiniz.
                    </li>
                    <li>
                        Markanın sipariş onaylama ve ödeme süresi ortalama <b>90</b> gündür.
                    </li>
                    <li>
                        Komisyon kategorilere göre değişiklik göstermektedir, bu sebeple marka kartı üzerindeki komisyona tıklayarak kategorilerim komisyon oranlarını öğrenebilirsiniz
                    </li>
                    <li>
                        Toplu Alımlarda, Call Center satışlarında, kurumsal üyelerin satışlarında, mobil uygulama  üzerinden gerçekleşen satışlarda  komisyon verilmeyecektir. Ayrıca Teknosa kötüye kullanım tespit ettiğinde yukarıda belirtilen nedenlerin dışında bir nedenden dolayı ya da bir neden göstermeden komisyon vermeyebilir.
                    </li>
                    <li>
                        Toplu Alım tanımı: 1 ay içerisinde herhangi bir üründen 3 adet ve üzeri alımlar, ve/veya aynı adrese aynı müşteri tarafından yapılan herhangi bir üründen 3 adet ve üzeri alımlar, ve/veya farklı adrese aynı müşteri tarafından yapılan herhangi bir üründen 3 adet ve üzeri alımlar Toplu Alım olarak tanımlanır. Teknosa Toplu Alım tanımını değiştirme hakkını saklı tutar.
                    </li>
                    </ul> 
                </div>
            </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            groups:[
                {'name': '1.grup',
                'rate': 3 ,
                'categories': [
                    'Boş DVD ve CD',
                    'Süpürge Aksesuarları',
                    'Süpürge Aparatları',
                    'TV Sehpaları',
                    'TV Askı Aparatı',
                    'Kablo ve Adaptör',
                    'Toz Torbası',
                    'Lamba',
                ],
                'ishidden': true} ,
                {'name':'2.grup',
                'rate': 2.2,
                'categories': [
                    'Boş DVD ve CD',
                    ' Kulak Üstü Kulaklık',
                    ' Akım Korucuyular',
                    ' Akım Korumalı Prizler',
                    ' Aksesuar Hizmet Paketi',
                    ' Radyo',
                    ' Müzik ve Ses Sistemleri',
                    ' MP3 ve MP4 Çalar',
                    ' Şarj Cihazları',
                    ' Outdoor ',
                    ' Tekne ve Yat',
                    ' Mutfak Şefi',
                    ' Kıyma Makineleri',
                    ' Mutfak Robotu',
                    ' Mikser',
                    ' Doğrayıcı ve Rondolar',
                    ' Blender',
                    ' Tost Makineleri',
                    ' Ekmek Kızartma Makineleri',
                    ' Ekmek Yapma Makineleri',
                    ' Spor ve Fitness',
                    ' Koşu Bantları',
                    ' Masaj Aleti',
                    ' Sağlık Ürünleri ',
                    ' Meyve Sıkıcılar',
                    ' Su Isıtıcısı ve Kettle',
                    ' Su Arıtma Cihazı',
                    ' Su Arıtma Cihazları ',
                    ' Su Arıtma Filtresi',
                    ' Kahve Makineleri ve Aksesuarları ',
                    ' Espresso & Cappuccino Kahve Makineleri',
                    ' Türk Kahvesi Makineleri',
                    ' Çay Makineleri',
                    ' Kahve Öğütücüler',
                    ' Kahve Kapsülleri',
                    ' Filtre Kahve Makineleri',
                    ' Kapsüllü Kahve Makinesi',
                    ' Baskül ve Terazi',
                    ' Saç Şekillendiriciler ',
                    ' Saç Kurutma Makineleri',
                    ' Fön Makineleri',
                    ' Saç Şekillendiriciler',
                    ' Saç Bakım ',
                    ' Saç Düzleştiriciler',
                    ' Saç Maşaları',
                    ' Saç, Sakal ve Vücut Tüyü Kesme Makineleri',
                    ' Ağız ve Diş Bakımı',
                    ' Ütü Masası',
                    ' Tencere ve Tavalar',
                    ' Termoslar',
                    ' Pişiriciler ve Tencereler ',
                    ' Mutfak Terazileri',
                    ' Pişiriciler',
                    ' Kondisyon Bisikletleri',
                    ' Spor ve Fitness ',
                    ' Selfie Çubuğu',
                    ' Sanal Gerçeklik Gözlüğü',
                    ' Ayakkabı ve Çanta ',
                    ' Çantalar',
                    ' Beslenme Çantası',
                    ' Kalem Set',
                    ' Defter Set',
                    ' Kostüm Aksesuarı',
                    ' Pipetli Bardak',
                    ' Kişisel Aksesuar ',
                    ' Telefon Kılıfı',
                    ' Çocuk Oyuncakları',
                    ' Matara',
                    ' Çatal Kaşık Seti',
                    ' Tabak',
                    ' Oyuncak Araba',
                    ' Figür Set',
                    ' Bebek',
                    ' Peluş Oyuncak',
                    ' Tsum Tsum',
                    ' Havlu',
                    ' Atkı ve Bere',
                    ' Kase',
                    ' Diğer Aksesuar',
                    ' Kostüm Aksesuarı ',
                    ' Bavul',
                ],
                'ishidden': true},
                {'name':'3.grup',
                'rate': 1.8,
                'categories': [
                    ' Kulak İçi Kulaklık',
                    ' Kulaklıklar',
' Epilatörler',
' IPL Cihazları',
' Playstation 3 Oyunları',
' Playstation 4 Oyunları',
' PC Oyunları',
' Xbox One Oyunları',
' Xbox 360 Oyunları',
' Xbox One Aksesuarları',
                  
                         
                         
                ],
                'ishidden': true},
                {'name':'4.grup',
                'rate': 1.5,
                'categories': [
                    ' Toz Torbasız Süpürgeler',
' Dikey Süpürgeler',
' Halı Yıkama Makineleri',
' Buharlı Temizleyici',
' Şarjlı Süpürgeler',
' Toz Torbalı Süpürgeler',
' Basınçlı Yıkama Makineleri',
' Televizyon Aksesuarları',
' 3D Gözlük',
' Oto Ürünleri ve Sistemleri',
' Uydu Alıcılar',
' Amfili Hoparlör',
' Soundbar',
' Ev Sinema Sistemleri',
' Müzik Enstrümanları',
' DVD ve Blu-Ray Oynatıcı ',
' Sinema Sistemi',
' Hoparlörler ',
' Dijital Video Kamera',
' Aksiyon Kamera',
' Fotoğraf Makinesi Aksesuarları',
' Projektör Askı Aparatı',
' Projektörler',
' Uydu Alıcı Aksesuarları',
' Antenler',
' Lazer Yazıcılar',
' Büro Tipi Buzdolabı',
' Yazıcılar ',
' Çift Kapılı',
' Buzdolabı',
' Kurutma Makinesi',
' Çamaşır Makinesi',
' Kurutmalı Çamaşır Makinesi',
' Bulaşık Makinesi',
' Ankastre Bulaşık Makinesi',
' Mikrodalga Fırın',
' Mini ve Midi Fırın',
' Fırın',
' Ankastre Fırın',
' Mini Fırın',
' Ocak',
' Alttan Donduruculu',
' Ankastre Ocak',
' Ankastre Davlumbaz',
' Soba ve Isıtıcılar',
' Vantilatör',
' Şofben',
' Hava Nemlendirici ve Temizleyici',
' Klima',
' Buharlı Ütü',
' Ütüler ',
' Buz Makinesi',
' Elektrikli Izgaralar ve Barbeküler ',
' Elektrikli Izgara',
' Barbekü',
' Buhar Kazanlı Ütü',
'Elektrikli Ev Aletleri ve Yaşam',
' Süpürgeler ',
'Kişisel Bakım ve Sağlık',
' Tıraş Makinesi',
' Erkek Tıraş Ürünleri ',
' Su Sebili',
' Fritözler',
' Derin Dondurucu',
' Isıtma ve Soğutma ',
' Temizleme Ürünleri',
' Sunum Kumandası',
' Notebook Soğutucu',
' Notebook Aksesuarları',
' Taşınabilir Disk Kılıfı',
' Ofis ve Sarf Malzemeleri ',
' Çevre Birimleri ',
' Notebook Çantası',
' Şarj Kabloları',
' Şarj Cihazları ',
' Tablet ve iPad Aksesuarları',
' Taşınabilir Şarj Cihazları',
' Araç Kitleri',
' Tablet Kılıfları',
' Mouse',
' Klavye',
' Mousepad',
' iMac ve MacBook Aksesuarları ',
' Bluetooth Hoparlör',
' Oyuncu Mouse ve Mousepad',
' Projeksiyon Cihazları ',
' Oyuncu Klavyesi',
' Playstation 4 Aksesuarları',
' Oyuncu Kulaklıkları',
' Uzaktan Kumandalı Oyuncaklar ',
' Ses Kayıt Cihazları',
' Müzik ve Ses Sistemleri ',
' Drone ve Multikopterler',
' Pikap',
' Oyuncu Aksesuarları ',
' Kağıt',
' Fotoğraf Yazıcılar',
' Toner ve Kartuş',
' Bilgisayar Aksesuarları ',
' Webcam / Kamera',
' Hoparlörler',
' Bilgisayar Hoparlörü',
' USB Bellek',
' Wireless Adaptör',
' Menzil Arttırıcı',
' Router',
' Ağ, Modemler ',
' Modemler',
' Switch',
' Pil Şarj Cihazları',
' Piller',
' Şarj Edilebilir Pil',
' Standart Pil',
' El Feneri',
' Piller ve Şarj Cihazları ',
' Akıllı Ev Seti',
' Akıllı Sensörler',
' Akıllı Kumanda ve Asistanlar',
' Akıllı Prizler',
' Alarmlar',
' Akıllı Ampül',
' Diğer Oyuncaklar',
' Hafıza Kartı',
' Çanta',
' Dürbün',
' Hafıza Kartı ve Kart Okuyucu ',
' Araç Kamerası',
' Tripod',
' Film ve Müzik',
' Ödeme Kartları',
' Kurulum Paketleri',
' Ekran Koruma Hizmeti',
' Güvenlik Paketleri',
' Cep Telefonu Aksesuarları ',
' IP Kameralar',
' Kılıflar',
' Kılıf ve Koruyucular ',
' Diğer Telefonlar',
' Masaüstü Telefonlar',
' Telsiz Telefonlar',
' Bluetooth Kulaklıklar',
' Ekran Koruyucular',
' Giyilebilir Teknoloji Aksesuarları',
' Sırt Çantası',
'Kostüm',
' Veri Depolama ',
' Tablet',
'Diğer Ürünler',
' Mürekkep Püskürtmeli Yazıcılar /Inkjet',
' Tarayıcılar',
' Tanklı Yazıcılar',
' Taşınabilir Disk',
' Office Yazılımları',
' Yazılım Ürünleri ',
' Antivirüs Güvenlik Yazılımları',
' Taşınabilir SSD',
' SLR Fotoğraf Makineleri',
' Fotoğraf Makinesi ',
' Dijital Fotoğraf Makineleri',
' Kamera ',
' Akıllı Saatler',
' Giyilebilir Teknoloji ',
' Akıllı Bileklik',
' Media Player',
' Monitör',
' Akıllı Oyuncaklar',
' Hobi ve Eğlence ',
' Xbox 360 ',
' Xbox One ',
                ],
                'ishidden': true},
                {'name':'5.grup',
                'rate': 1.1,
                'categories': [
                    ' LED Monitör TV',
' LED TV Televizyonlar',
' All-in-One PC / Monitör Bilgisayar',
' Masaüstü Bilgisayar',
' Notebook',
' Notebook / Laptop',
' 2\'si 1 Arada Notebook',
' Oyun Bilgisayarı / Gaming Notebook',
' MacBook',
' Android Telefonlar',
' iPhone iOS Telefonlar',
' Cep Telefonu',
' Playstation 4 (PS4) ',
' Playstation 3 (PS3) ',
' Oyun Konsolları ve Oyun ',
' Televizyonlar ',
                ],
                'ishidden': true},
            ]
        }
    },
    methods:{
        show () {
                this.$modal.hide('hello-world');
        },
        hide () {
            this.$modal.hide('hello-world');
        }
    }
    
}
</script>

<style scoped>
.container{
    margin-top: 0px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>