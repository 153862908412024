<template>
<div>
    <hero-banner />
    <div class='col-12 campaign_container'>
        <div class="row campaign_bigger">
            <div class="col-12 campaign_group">
                <h4> {{ filteredCategory[0].category_name }} </h4>
            </div>
            <div class="container-fluid mobile_scrollable">
            <div class="row flex-row flex-nowrap">
            <div class="col-lg-2 col-5 campaign_cards"
            v-for="(campaign, index) in limitFilteredCat(filteredCategory)"
                v-bind:item = "campaign"
                v-bind:index = "index"
                v-bind:key = "campaign._id"
                >
                <div class="card card-user">
                    <div class="image">
                        <img v-if="campaign.category_name === 'Moda'" src="../assets/card_background/fashion/a.png"  alt="..."> <!--  -->
                        <img v-if="campaign.category_name === 'Kozmetik & Kişisel Bakım'" src="../assets/card_background/beauty/a.png"  alt="..."> <!--  -->
                        <img v-if="campaign.category_name === 'Elektronik'" src="../assets/card_background/electronic/a.png"  alt="..."> <!--  -->
                        <img v-if="campaign.category_name === 'Ev & Yaşam'" src="../assets/card_background/home/a.png"  alt="..."> <!--  -->
                        <img v-if="campaign.category_name === 'Yemek & SüperMarket'" src="../assets/card_background/market/a.png"  alt="..."> <!--  -->
                        <img v-if="campaign.category_name === 'Seyahat'" src="../assets/card_background/travel/a.png"  alt="..."> <!--  -->
    
                    </div>
                    <div class="card-body">
                        <div class="align-self-center author">
                            <img class="avatar border-gray" v-bind:src="campaign.banner_url" alt="..."> <!--../assets/mike.jpg-->
                                <h5 class="title">{{ campaign.vendor_name }}</h5>
                        </div>
                    </div>
                    <button v-bind:id="campaign.vendor_name" type="button" class="btn btn-outline-secondary btn-sm btn-block cta-btn" @click="clickedButton(campaign.vendor_name, campaign.campaign_id)">
                        Gelince Haber Ver
                        <i class="fas fa-bell"></i>
                    </button>
                </div>  
            </div>
            </div>
            </div>
        </div>
    </div>
        <div class="row campaign_row" style="margin-left: 0px;">
            <category-filter />
            <div class="col-lg-9 col-12" style="padding:0px;">
            <div class="table_container">
                <div class="card">
                    <div class="card-header d-none d-lg-block">
                        <h4 class="card-title">Markalar</h4>
                    </div> 
                    <div class="card-body other-brand-body">
                        <div class="table-responsive">
                            <table class="table">
                                <!--<thead class="text-primary">
                                    <th>
                                    Marka Adı
                                    </th>
                                    <th>
                                    Kategori
                                    </th>
                                    <th> 
                                    </th> 
                                </thead>-->
                                <tbody>
                                    <tr v-for="(campaign, index) in filteredCategory" 
                                    v-bind:item = "campaign"
                                    v-bind:index = "index"
                                    v-bind:key = "campaign._id">
                                    <td>
                                        {{ campaign.vendor_name }}
                                    </td>
                                    <td class="d-none d-lg-block">
                                        {{ campaign.category_name }}
                                    </td> 
                                    <td class="text-right">
                                        <div class="col-lg-7 col-4 brand_button" style="margin-left: 100px;">
                                            <button v-bind:id="campaign.campaign_id" type="button" class="align-self-end btn btn-outline-secondary btn-sm btn-block cta-btn" @click="clickedButton(campaign.vendor_name, campaign.campaign_id)">
                                            Gelince Haber Ver
                                            <i class="fas fa-bell"></i>
                                            </button>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
//import AffOffersService from '../../AffOffersService'; 
import AffOffersService from "../_services/aff_offer.service";
import CategoryFilter from "../components/layout/CategoryFilter.vue";
import HeroBanner from "../components/layout/HeroBanner.vue";

export default {
  name: 'Categories',
  components: {
    CategoryFilter,
    HeroBanner
  },
  
  data(){
      return {
          campaigns: []          
      }
  },
    props: {
    category: {
      type: String,
      default: 'All'
    }
  },
  async created() {
      try{
        this.campaigns =  await AffOffersService.getCampaigns(); // Moda&Elektronik
        //var pathh= this.$route.path;
        //this.filteredCategory =  pathh.substring(pathh.length - 15); 
      }catch(err) {
        this.error = err.message;
      }
  },
  computed: {
      filteredCategory: function(){
        var filter = this.$route.params.category
        
        if (filter !=='a'){
            return this.campaigns.filter(campaign => campaign.category_name.replace('ş', 's').replace('ı', 'i').replace('ö', 'o').replace('ü', 'u')
        .replace('ğ', 'g').replace('ç', 'c').replace('Ş', 'S').replace('Ç', 'C').replace('Ü', 'U')
        .replace('Ö', 'O').replace('İ', 'I').replace(' & ', '&').replace(' ', '') === filter);
        }
        else {
            var campaigns =  this.campaigns; //.slice(0, 30);
            var flags = {}, output = [], l = campaigns.length, i;
            for( i=0; i<l; i++) {
                if( flags[campaigns[i].vendor_name]) continue;
                flags[campaigns[i].vendor_name] = true;
                output.push(campaigns[i]);
            }
            /*.filter(campaign => (campaign.campaign_id != 2 && campaign.campaign_id != 4
            && campaign.campaign_id != 6)));*/

            return output;
        }
      }
  },
  methods: {
      
      clickedButton: function(vendor_name, vendor_id){
        //this.$refs[vendor_name].innerText = 'serhatEmreCebeci'
        //alert(JSON.stringify(this.ref));
        var campaign_btn_by_name = document.getElementById(vendor_name) //_by_name
        //var btn_list = [];
        var campaign_btn_by_id = document.getElementById(vendor_id);
        var btn_list = [campaign_btn_by_id, campaign_btn_by_name];
        
        for (let i=0; i<btn_list.length; i++){
        var campaign_btn = btn_list[i];
        
        var icon = document.createElement('i');

        if (campaign_btn.className === 'btn btn-outline-secondary btn-sm btn-block cta-btn haberver'){
            campaign_btn.className = 'btn btn-outline-secondary btn-sm btn-block cta-btn';
            icon.setAttribute('class', 'fas fa-bell');

            campaign_btn.innerText = 'Gelince Haber Ver'        
            campaign_btn.appendChild(icon)

        }
        else{ 
            campaign_btn.className = 'btn btn-outline-secondary btn-sm btn-block cta-btn haberver'
            icon.setAttribute('class', 'far fa-check-circle');

            campaign_btn.innerText = 'Onaylandı'        
            campaign_btn.appendChild(icon)
        }

        
        }
      },
      limitFilteredCat: function(filteredCategory){

          var limitedfiltered = filteredCategory.slice(0,6);
          return limitedfiltered;
      }
  },
  filters: {
  }
}
</script>

<style scoped>

body {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.campaign_row {
    margin-left: 0;
    margin-right: 0;
    margin-top: 2%;
}
.content {
  padding: 0 30px 30px;
  min-height: calc(100vh - 123px);
  margin-top: 93px;
}

.content .row-container{
    padding-top: 100px;
}

.card {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.75);
  background-color: #FFFFFF;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}


.card .card-body {
  padding: 15px 15px 10px 15px;
}

.card .numbers {
  font-size: 2em;
}

.card .card-body.table-full-width {
  padding-left: 0;
  padding-right: 0;
}

.card-category {
    text-transform: capitalize;
    font-weight: 400;
    color: #9A9A9A;
    font-size: 0.7142em;
}
@media screen and (max-width: 768px) {

    .content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .card-stats [class*="col-"] .statistics::after {
    display: none;
    }
}



@media screen and (max-width: 576px) {

    .card-contributions .card-stats {
    flex-direction: column;
    }

    .card-contributions .card-stats .bootstrap-switch {
    margin-bottom: 15px;
  }

}


.campaign_cards{
    padding-bottom: 1%;
    padding-right: 10px;
}


.card-user {
  width: 100% !important;
  max-height: 160px;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: rgb(241, 238, 234); /* #FFFFFF*/
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}

.card-user button{
    padding-left: 0%;
    padding-right: 0%;

    border-radius: 0 0 12px 12px;
    
    position: relative;
    color: white;
    border: 0px solid #E64769;
    background-color: #E64769;
    /*box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.75);
    /*box-shadow: 0px, 2px, 4px, 0px rgb(125, 125, 125, 0.18);*/
}

.card:hover{
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.75);
}

.card .image {
    overflow: hidden;
    height: 200px;
    position: relative;
}


.card-user .image {
  height: 90px;
}

.card-user .image img {
  border-radius: 12px;
  border: 1px solid rgb(241, 238, 234);
}

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -37px;
}

.card .card-body {
    padding: 15px 15px 10px 15px;
}

.card-user .card-body h5 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.card .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    /*border-radius: 50%;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;*/
    border: 1px solid white;
    margin-bottom: 35px;
}

.card-user .avatar {
  width: 124px;/*124px;*/
  height: 42px;/*124px;*/
  border: 1px solid #FFFFFF;
  position: relative;
}

img {
    max-width: 100%;
    border-radius: 3px;
}

.card-user hr {
  margin: 5px 15px 15px;
}

.card-user .button-container {
  margin-bottom: 6px;
  text-align: center;
}

.cta-btn{
    font-size: 0.75rem;
}

.btn:hover {
    background-color:rgba(230,71,105,0.9) !important;
    text-decoration: none;
    outline-color: none !important;
    box-shadow: none;
    /*outline-color: linear-gradient(60deg, #ffa726, #fb8c00) !important;
    box-shadow: none;*/
}

.haberver{
    background-color: rgb(7, 164, 175) !important;
    transition: none;
}

.haberver:hover {
    background-color:rgba(7, 164, 175,0.9) !important;
    text-decoration: none;
    outline-color: none !important;
    /*box-shadow: none;*/
    /*outline-color: linear-gradient(60deg, #ffa726, #fb8c00) !important;
    box-shadow: none;*/
}

.campaign_bigger{
    border: 0px solid #ddd;
    background-color: white;
    padding: 0% 2% 0% 2%;
    margin-top: 3%;
    margin-left: 0%;
    margin-right: 0%;
    border-radius: 5px 5px 5px 5px;
    
}

.other-campaign_bigger{
    border: 0px solid #ddd;
    background-color: white;
    padding: 0%;
    margin-top: 3%;
    margin-left: 2%;
    margin-right: 0%;
    padding-right: 6%;
    border-radius: 5px 5px 5px 5px;
}

.campaign_group h4{
    font-family: 'Merriweather', serif;
    color: #636F7B; /*#66615B;*/
    margin-bottom: 0px;
    font-size: 1rem;
    padding-top: 3%;
    padding-bottom: 2%;
}

.campaign_container{
    padding-left: 0%;
    padding-right: 0%;
}


.table-responsive{
    width: 100%;
}

tbody button{
    width: 120px;
    padding-left: 0%;
    padding-right: 0%;

    border-radius: 12px 12px 12px 12px;
    
    position: relative;
    color: white;
    border: 0px solid #E64769;
    background-color: #E64769;
    /*box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.75);
    /*box-shadow: 0px, 2px, 4px, 0px rgb(125, 125, 125, 0.18);*/
}

.table_container{
    padding-right: 0%;
    margin-left: 4.333333%;
}

@media screen and (max-width: 860px) {
    .brand_button{
        margin-left: 25px !important;
    }

    .card{
        width: 100%;

    }

    .card{
        background-color: #FFFFFF;
        }

    .card-user .image img{
        border: 0px;
    }

    .campaign_bigger{
      background-color: transparent;
      padding-right: 0%;
    }


    .main_container> div > .row{
        margin-right: 0px;
    }

    .mobile_scrollable{
        padding: 0px;
    }
    .mobile_scrollable > .row{
     /*overflow: scroll;*/
     overflow-x: auto !important;
     white-space: nowrap;
     -ms-overflow-style: none;
     margin-right: 0px;
     padding-left: 10px;
    }

    .mobile_scrollable > .row::-webkit-scrollbar {
        display: none;
    }


    .table_container{
        padding-left: 0px;
        margin-left: 0px;
    }

    .other-brand-body{
        padding-right: 0px!important;
    }
}
</style>
