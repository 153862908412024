<template>
    <div class="container login_container">
        <form @submit.prevent="handleSubmitBankAccount">
        <div style="height:100%;" class="form-group  self-align-right form-container ">
            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_first_name"><b>İsim</b><span style="color:red;">*</span>:</label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_first_name"><b>İsim<span style="color:red;">*</span></b></label>

                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                    <input style="background-color: white;" placeholder="İsim" type="text" v-model="account_first_name" name="account_first_name" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_first_name }"/>
                    <div v-show="submitted && !account_first_name" class="invalid-feedback">İsim gerekli</div>
                </div>
            </div>

            <div class="form-group">
            <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_last_name"><b>Soyisim</b><span style="color:red;">*</span>:</label>
            <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_last_name"><b>Soyisim<span style="color:red;">*</span></b></label>
                 <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                <input placeholder="Soyisim" type="email" v-model="account_last_name" name="account_last_name" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_last_name }" />
                <div v-show="submitted && !account_last_name" class="invalid-feedback">Soyisim gerekli</div>
                </div>
            </div>

            <!--<div class="form-group col-lg-6 offset-lg-3 col-sm-12">
                <label htmlFor="password">Şifre</label>
                <ValidationProvider rules="min:6" v-slot="{ errors }">
                <input placeholder="şifre" type="password" v-model="password" name="password" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !password }" />
                <span style="font-size: 80%; color: #dc3545;">{{ errors[0] }}</span>
                <div v-show="submitted && !password" class="invalid-feedback">Şifre girmen gerekir</div>
                </ValidationProvider>
            </div>-->

            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_phone"><b>Telefon</b><span style="color:red;">*</span>:</label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_phone"><b>Telefon<span style="color:red;">*</span></b></label>
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                <input type="text" placeholder="Telefon Numarası" v-model="account_phone" name="firstName" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_phone }" />
                <div v-show="submitted && !account_phone" class="invalid-feedback">Telefon numarası gerekli</div>
                </div>
            </div>

            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_address"><b>Adres</b><span style="color:red;">*</span>:</label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_address"><b>Adres<span style="color:red;">*</span></b></label>    
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                    <textarea style="line-height: 0.7; padding-top: 12px;" placeholder="Adres" v-model="account_address" name="account_address" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_address }"></textarea>
                    <div v-show="submitted && !account_address" class="invalid-feedback">Adres bilgisi gerekli</div>
                </div>
            </div>

            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_identity_number"><b>TC Kimlik No</b><span style="color:red;">*</span>:</label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_identity_number"><b>TC Kimlik No<span style="color:red;">*</span></b></label>
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                <input type="text" placeholder="TC Kimlik Numarası" v-model="account_identity_number" name="account_identity_number" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_identity_number }" />
                <div v-show="submitted && !account_identity_number" class="invalid-feedback">TC Kimlik numarası gerekli</div>
                </div>
            </div>

            <div class="form-group">
                <label style="text-align: right; font-size: 0.9rem;" class="col-lg-3 d-none d-lg-inline-block" for="sel1"><b>Vergi Mükellefi misiniz?</b><span style="color:red;">*</span></label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="sel1"><b>Vergi Mükellefi misiniz?<span style="color:red;">*</span></b></label>
                
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                    <select v-model="account_is_individuals" name="account_is_individuals" id="sel1" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_is_individual }">
                        <option value="" disabled selected hidden>Lütfen Birini Seçiniz</option>
                        <option>Evet</option>
                        <option>Hayır</option>
                    </select>                    
                <div v-show="submitted && !account_is_individual" class="invalid-feedback">Bu bilgiyi girmen gerekir</div>
                </div>
            </div>
            
            <div v-if="account_is_individuals == 'Evet'" class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_tax_number"><b>Vergi Numarası<span style="color:red;">*</span>:</b></label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_tax_number"><b>Vergi Numarası<span style="color:red;">*</span></b></label>
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                <input type="text" placeholder="Vergi Numarası" v-model="account_tax_number" name="account_tax_number" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_tax_number }" />
                <div v-show="submitted && !account_tax_number" class="invalid-feedback">Vergi numarası gerekli</div>
                </div>
            </div>

            <div v-if="account_is_individuals == 'Evet'" class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_tax_office"><b>Vergi Dairesi<span style="color:red;">*</span>:</b></label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_tax_office"><b>Vergi Dairesi<span style="color:red;">*</span></b></label>    
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                    <textarea style="line-height: 0.7; padding-top: 12px;" placeholder="Vergi Dairesi" v-model="account_tax_office" name="account_tax_office" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_tax_office }"></textarea>
                    <div v-show="submitted && !account_tax_office" class="invalid-feedback">Vergi Dairesi bilgisi gerekli</div>
                </div>
            </div>

            <div v-if="account_is_individuals == 'Evet'" class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_company_name"><b>Şirket Ünvanı<span style="color:red;">*</span>:</b></label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_company_name"><b>Şirket Ünvanı<span style="color:red;">*</span></b></label>    
                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                    <textarea style="line-height: 0.7; padding-top: 12px;" placeholder="Şirket Ünvanı" v-model="account_company_name" name="account_company_name" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_company_name }"></textarea>
                    <div v-show="submitted && !account_company_name" class="invalid-feedback">Şirket Ünvanı bilgisi gerekli</div>
                </div>
            </div>

            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_iban"><b>IBAN<span style="color:red;">*</span>:</b></label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_iban"><b>IBAN<span style="color:red;">*</span></b></label>

                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                    <!--<div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <div style="text-align: center; padding: 0px; background-color: #E8E8E8; width: 24px; font-size: 12px;" class="input-group-text">TR</div>
                    </div>-->
                    <input style="background-color: white;" placeholder="IBAN" type="text" v-model="account_iban" name="account_iban" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_iban }"/>
                    <div v-show="submitted && !account_iban" class="invalid-feedback">IBAN gerekli</div>
                    <!--</div>-->
                </div>
            </div>

            <div class="form-group">
                <label style="text-align: right;" class="col-lg-3 d-none d-lg-inline-block" for="account_bank_name"><b>Banka Adı<span style="color:red;">*</span>:</b></label>
                <label style="text-align: left; font-size: 12px;margin-left:12px;" class="col-lg-3 d-lg-none" for="account_bank_name"><b>Banka Adı<span style="color:red;">*</span></b></label>

                <div style="display: inline-block;" class="col-lg-6 col-sm-12">
                    
                    <input style="background-color: white;" placeholder="Banka Adı" type="text" v-model="account_bank_name" name="account_bank_name" class="form-control account-update-form" :class="{ 'is-invalid': submitted && !account_bank_name }"/>
                    <div v-show="submitted && !account_bank_name" class="invalid-feedback">Banka Adı bilgisi gerekli</div>
                </div>
            </div>
            <div style="height: 100%; display: flex; align-items: center; justify-content: center;">
                <div class="col-6 col-lg-3">
                    <button style="width: 100%; font-size: 12px;" type= "button" class="btn btn-next" @click="handleSubmitBankAccount">Güncelle</button>
                </div>
            </div>
        </div>
        </form>
        <hr>
 
<div id="appModal">
        <!--</section>-->
        <transition name="slide-fade" mode="in-out">
        <div style="display: block;" class="modal fade show" role="dialog" v-if="showModal">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content header-modal">
                    <div class="modal-header ">
                        <p class="modal-title center-text">Banka Hesap Bilgilerin Güncellendi</p>
                        <button type="button" class="close shadow-none" @click="showModal = false">&times;</button>
                    </div>
                    <!--<div class="modal-body">
                        <p> Bu ürün linkini tüm çevrenle paylaş, paylaştıkça kazan!!</p>
                    </div>
                    <div class="modal-footer d-flex justify-content-between"> 
                    </div>-->
                </div>
            </div>
        </div>
        </transition>
</div>
        <transition name="slide-fade">
        <div v-if="showModal" class="modal-backdrop fade show" ></div>
        </transition>
        
    
    </div>
</template>

<script>
import axios from 'axios';
import { extend } from 'vee-validate';
import { min, email, confirmed } from 'vee-validate/dist/rules';

extend('min', {
  ...min,
  message: 'Şifren en az 6 karakter içermeli'
});

extend('email', {
  ...email,
  message: 'Lütfen geçerli bir email giriniz'
});

extend('confirmed', {
  ...confirmed,
  message: 'Lütfen yukarıda girmiş olduğun şifreyi tekrarla.'
});

export default {
    components: {        
    },
    data(){
        return {
            account_first_name: '',
            account_last_name: '',
            account_phone: '',
            account_address: '',
            account_identity_number: '',
            account_is_individual: '',
            account_is_individuals: '',
            account_tax_number: '',
            account_tax_office: '',
            account_company_name: 'sa',
            account_iban: '',
            account_bank_name: '',
            submitted: false,
            username_is_ok: true,
            res: '',
            showModal: false,
            error_status : '',
        }
    },
    async created() {
    var userID = JSON.parse(localStorage.user).aff_id;

    this.url = 'https://ulan-gitlab-heroku-env-staging.herokuapp.com/accounts/' + userID

    //this.info = await axios.get(this.url)
    
    axios.get(this.url).then(res => { 
        this.res = res.data
        this.account_first_name = res.data.first_name
        this.account_last_name = res.data.last_name
        this.account_phone = res.data.phone_number
        this.account_address = res.data.address
        this.account_identity_number = res.data.identity_number
        this.account_tax_number = res.data.tax_number ? res.data.tax_number: ''
        this.account_tax_office = res.data.tax_office ? res.data.tax_office: '' 
        this.account_company_name = res.data.company_name ? res.data.company_name: '' 
        this.account_iban = res.data.iban 
        this.account_bank_name = res.data.bank_name
        this.account_is_individual = res.data.is_individual
        this.error_status = 200
        
        }).catch(error => {
            this.error_status = error.response.status
            this.account_company_name= ''
            this.account_tax_office = ''
            this.account_tax_number = ''


        })
      },

    methods: {
        changeHandler(key, value){
            this.changedict[key] = value
            //this.change_list.push(change_dict)
            //this.change_list.push(this.changedict)
        },
        async handleSubmit () {
            
            /*const url = "https://ulan-gitlab-heroku-env-staging.herokuapp.com"
            
            let ress= await axios.post(url, {
                aff_id: userID,
                first_name: this.username,
                last_name: this.email,
                identity_number: this.account_identity_number,
                address: this.account_address,
                bank_name: this.account_bank_name,
                iban: this.account_iban,
                phone_number: this.account_phone,
                tax_office: this.account_tax_office,
                tax_number: this.account_tax_number,
                company_name: this.account_company_name,
                company_type: 'Şahıs'
                }) */           
        },
        async handleSubmitBankAccount (){
            var userID = JSON.parse(localStorage.user).aff_id;
            //alert(this.account_company_name)
            if (this.error_status == 500){
                axios.post('https://ulan-gitlab-heroku-env-staging.herokuapp.com/affiliaters/addAccount/', 
            {
                aff_id: userID,
                first_name: this.account_first_name,
                last_name: this.account_last_name,
                identity_number: this.account_identity_number,
                address: this.account_address,
                bank_name: this.account_bank_name,
                iban: this.account_iban,
                phone_number: this.account_phone, 
                is_individual: this.account_is_individuals == 'Hayır',
                tax_office: this.account_tax_office ? this.account_tax_office: 'undefined',
                tax_number: this.account_tax_number ? this.account_tax_number: 'undefined',
                company_name: this.account_company_name ? this.account_company_name: 'undefined',
                company_type: 'Şahıs'
                }).then(() =>{
                    this.error_status = 200
                })
                this.showModal = true
            }
            else{
            axios.patch('https://ulan-gitlab-heroku-env-staging.herokuapp.com/affiliaters/account/'+ userID, 
            {
                first_name: this.account_first_name,
                last_name: this.account_last_name,
                identity_number: this.account_identity_number,
                address: this.account_address,
                bank_name: this.account_bank_name,
                iban: this.account_iban,
                phone_number: this.account_phone,
                is_individual: this.account_is_individuals == 'Hayır',
                tax_office: this.account_tax_office,
                tax_number: this.account_tax_number,
                company_name: this.account_company_name,
                company_type: 'Şahıs'
                }).then(() =>{
                    this.error_status = 200
                })
            this.showModal = true  
        }  
        }
    }
    
}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: opacity 0.15s linear;

}
.slide-fade-leave-active {
  transition: opacity 0.15s linear;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {

  opacity: 0;
}
.slide-fade-leave-to{
    opacity: 0;
}


.header-modal{
    background-color: white;
    border-radius: 10px;
    color: #E64769;
}

.modal-header{
    padding: 1.5rem 2.5rem;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    padding-left: 2.5rem;
    border-bottom: 0 none;
}

.modal-footer{
    border-top: 0 none;
    padding-top: 0.5%;
}

.modal-title{
    padding-top: 1rem;
}

.error-div{
    display: block; 
    padding-left: 10px
}

.btn-next{
    text-align: center;
    color: #fff;
    background-color: rgb(7, 164, 175);
    border-color: rgb(7, 164, 175);
    border-radius: 30px;
    line-height: 1;

}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

.account-update-form{
    font-size: 12px;
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-bottom: 0px;
}

</style>