<template>
  <div id="app"> <!-- col-12 col-md-8 offset-md-2 -->
    <hr style="height:5px;border:none;color:#E64769;background-color:#E64769; margin-bottom:0px; margin-top: 0px;">
    <router-view name="header"></router-view>
    <!--<router-view name="sidebarMenu"></router-view>-->
    <!--<sidebar-menu></sidebar-menu>-->
    <router-view name="landing"></router-view>
    <router-view name="storyfirst"></router-view>
    <main @click="closeNav()" >
      <div class="col-12 main_container">
      <router-view />
      </div>

    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>

//import Header from "./components/layout/Header.vue";
//import AffOffers from "./components/layout/AffOffers.vue";
//import CreateCampaign from "./components/layout/CreateCampaign.vue";
//import { SidebarMenu } from 'vue-sidebar-menu'
//import SideHeader from "./components/layout/SideHeader.vue"

export default {
  name: 'app',
  components: {
    //SideHeader
    //SidebarMenu
    //Header,
    //AffOffers,
    //CreateCampaign
  },
  computed: {
   
  },
  methods: {
    closeNav() {
      var noError = true;
      try{
        document.getElementById("mySidenav").style.width = "0";
      }
      catch(err){
        noError = true;
      }
      if(noError){
        document.getElementById("app").style.marginLeft= "0";
        document.getElementById("app").style.marginRight = "0px";

        var main = document.getElementsByTagName("main")[0];
        main.setAttribute("class", "");   
        
        var body = document.getElementsByTagName("body")[0];
        body.setAttribute("class", "");   
        
        var html = document.getElementsByTagName("html")[0];
        html.setAttribute("class", "");  
      }
    
    }
    
  },
  watch: {
      
  } 

  //methods: {
  //  myFunction(){
//
//    }
//  }
}
</script>

<style>
/*
.introjs-helperLayer{
  background-color: transparent !important;
}*/
#app{
  transition: 0.3s padding-left!important;
  min-height: 100vh;
  background: #f2f2f2; /* #f1f3f5 !important; /*rgb(250, 250, 250); #f4f3ef */
  line-height: 1.5 !important;
  
}

/**{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}*/

/*body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  background: #f4f3ef ;
}*/

.main_container{
  padding-right:15%; 
  padding-left:15%; 
  padding-top: 2%; 
  margin:0
}

.social-btn-icon{ /* linktable'daki social media iconları için */
  cursor: pointer;
}

@media screen and (max-width: 860px) {
   .main_container{
     padding: 0%;
   }
   #app{
     margin-right: -0%vw!important;
     transition: margin-left .3s!important;
   }

   .disable-main{
     pointer-events: none;  
     opacity: 0.4;
   }

    .disable-body{
      overflow: hidden;
      position: relative;
    }

    .disable-html{
      overflow-x: hidden;

    }

    .social-button-div{ /* share again social button adjustments*/
      padding-right: 10px;
      padding-left: 10px;
    }

 }

</style>
