<template>
    <div style="padding-left: 0px" class="container">
            <div style="padding-bottom: 100px; " class="col col-lg-12  self-align-right login_container">
                <div style="text-align: center; padding-top: 20px;" class="content-header">
                <h4>Gizlilik ve Kişisel Verilerin Korunması Hakkında Bilgilendirme</h4>
                </div>
                <div style="padding-top: 20px; overflow-y: auto; height: 100vh;" class="col col-lg-12 col-sm-12">   
                    <p>
Linkadoo Pazarlama Teknolojileri ve Danışmanlık A.Ş. (“Linkadoo”) tarafından www.linkadoo.me websitesi aracılığıyla yeni marka, ürün ve kampanyaların tanıtımı başta olmak üzere, tarafıma ticari elektronik ileti gönderilmesi amacıyla Linkadoo ile paylaşmış olduğum veya ilerleyen dönemlerde paylaşacağım telefon ve eposta ve mobil uygulamalar aracılığıyla adresimin kullanılmasına ve işbu iletişim araçları ile şahsımla iletişime geçilmesine, bu kapsamda paylaşmış olduğum bilgilerin Linkadoo tarafından saklanmasına, kullanılmasına ve ticari elektronik iletinin içeriğinin ve gönderiye ilişkin diğer kayıtların gerektiğinde Gümrük ve Ticaret Bakanlığı’na sunulmak üzere kayıt altına alınarak saklanmasına onay veriyorum. İşbu Ticari Elektronik İleti izni https://www.linkadoo.me/veri-politikasi adresindeki GİZLİLİK VE KİŞİSEL VERİLERİN KORUNMASI HAKKINDA BİLGİLENDİRME metnin ayrılmaz bir parçasıdır.                    
                    </p>
                </div>
            </div> 
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>



.container{
    margin-top: 0px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
    font-size: 12px;
}

@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>