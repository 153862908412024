<template>
<div class="container" style="padding: 0px;">
<section id="wallet-cards">
<div class="content">
    <div class="container-fluid">
        <div class="row row-container">
            <div class="col-lg-4 col-md-6 col-sm-6 d-none d-md-block">
                <div class="card card-stats">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-5 col-md-4">
                                <div class="icon-big text-center icon-warning">
                                    <i class="fas fa-money-check" style="color:#7AC29A"></i>
                                </div>
                            </div>
                            <div class="col-7 col-md-8">
                                <div class="numbers">
                                    <p class="card-category">Bekleyen Kazanç</p>
                                    <p class="card-title">{{ pending_amount.toFixed(2) }} ₺</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer ">
                        <hr>
                        <div class="stats">
                            <i class="fa fa-calendar-o"></i> Markaların onaylarını bekliyor.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 d-none d-md-block">
                <div class="card card-stats">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-5 col-md-4">
                                <div class="icon-big text-center icon-warning">
                                    <i class="fas fa-money-check" style="color:#7AC29A"></i>
                                </div>
                            </div>
                            <div class="col-7 col-md-8">
                                <div class="numbers">
                                    <p class="card-category">Onaylanan kazanç</p>
                                    <p class="card-title">{{ confirmed_amount.toFixed(2) }} ₺</p> <!--{{ wallet.toFixed(2) }}-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer ">
                        <hr>
                        <div class="stats">
                            <i class="fa fa-calendar-o"></i> Onaylanan kazancın 100 ₺'yi aşınca çekebilirsin.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 d-none d-md-block">
                <div class="card card-stats">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-5 col-md-4">
                                <div class="icon-big text-center icon-warning">
                                    <i class="fas fa-money-check" style="color:#7AC29A"></i>
                                </div>
                            </div>
                            <div class="col-7 col-md-8">
                                <div class="numbers">
                                    <p class="card-category">Ödenen kazanç</p>
                                    <p class="card-title"> {{ paid_amount.toFixed(2) }} ₺</p> <!--{{ wallet.toFixed(2) }}-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer ">
                        <hr>
                        <div class="stats">
                            <i class="fa fa-calendar-o"></i> Paylaştıkça toplam kazancını artır.
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6 col-sm-6 d-block d-md-none wallet-card">
                <div class="card card-stats">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-4 col-md-4">
                                <div class="icon-big text-center icon-warning">
                                    <i class="fas fa-money-check" style="color:#7AC29A"></i>
                                </div>
                            </div>
                            <div class="col-8 col-md-8 wallet-tabs">
                                <div class="numbers">
                                  <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                      <a class="nav-link active card-category wallet-nav-tab" data-toggle="tab" href="#bekleyen">Bekleyen</a>
                                    </li>
                                    <li class="nav-item">
                                      <a class="nav-link card-category wallet-nav-tab" data-toggle="tab" href="#onaylanan">Onaylanan</a>
                                    </li>
                                    <!--<li class="nav-item">
                                      <a class="nav-link card-category wallet-nav-tab" data-toggle="tab" href="#odenecek">Ödenecek</a>
                                    </li>-->
                                    <li class="nav-item">
                                      <a class="nav-link card-category wallet-nav-tab" data-toggle="tab" href="#toplam">Ödenen</a>
                                    </li>
                                  </ul>
                                      
                                    <div class="tab-content">
                                      <div class="tab-pane container active" id="bekleyen">
                                        <p class="card-title">{{ pending_amount.toFixed(2) }} ₺</p>
                                      </div>
                                      <div class="tab-pane container fade" id="onaylanan">
                                        <p class="card-title">{{ confirmed_amount.toFixed(2) }} ₺</p> <!-- {{ wallet.toFixed(2) }}-->
                                      </div>
                                      <div class="tab-pane container fade" id="odenecek">
                                        <p class="card-title">{{ confirmed_amount.toFixed(2) }} ₺</p> <!-- {{ wallet.toFixed(2) }}-->
                                      </div>
                                      <div class="tab-pane container fade" id="toplam">
                                        <p class="card-title">{{ paid_amount.toFixed(2) }} ₺</p> <!-- {{ wallet.toFixed(2) }} -->
                                      </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer ">
                        <hr>
                        <div class="stats">
                            <i class="fa fa-calendar-o"></i> 
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6 col-sm-6 wallet-card">
                <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-4 col-md-4">
                          <div class="icon-big text-center icon-warning">
                              <i class="fas fa-mouse-pointer" style="color:#F3BB45; "></i>
                          </div>
                          </div>
                          <div class="col-8 col-md-8">
                          <div class="numbers">
                              <p class="card-category">Tıklanma Sayısı</p>
                              <p class="card-title">{{ click_count }}</p>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div class="card-footer ">
                      <hr>
                      <div class="stats">
                          <i class="fa fa-refresh"></i>
                      </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-6 col-sm-6 offset-sm-6 wallet-card">
                <div class="card card-stats">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4 col-md-4">
                                <div class="icon-big text-center icon-warning">
                                    <i class="fas fa-shopping-bag" style="color:#ff9800"></i>
                                </div>
                            </div>
                            <div class="col-8 col-md-8">
                                <div class="numbers">
                                    <p class="card-category">Yapılan Alışveriş Sayısı</p>
                                    <p class="card-title">{{ conversion_count }}</p> <!--{{ people }}-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer ">
                        <hr>
                        <div class="stats">
                            <i class="fa fa-clock-o"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row row-container">
            <link-data-feed />
            <login-history-feed />
        </div>-->
    </div>
</div>
</section>
<section id="link-statistics">
  <link-statistics />
</section>
</div>
</template>
<script>
//import axios from "axios";
//import { reject } from 'q';
//import VueFormGenerator from "vue-form-generator";
//import LinkDataFeed from "./LinkDataFeed.vue";
//import LoginHistoryFeed from "./LoginHistoryFeed";
import Axios from 'axios';
import LinkStatistics from '../components/layout/LinkStatistics.vue';
//import {userService} from '../_services';

export default {
  name: "AffDashboard",
  components: {
    LinkStatistics

      //LinkDataFeed,
      //LoginHistoryFeed,
  }, 
  data(){
      return {
        pending_amount: 0,
        confirmed_amount: 0,
        paid_amount: 0,
        click_count: 0,
        conversion_count: 0,
          
      }
  },
  async created() {
      try{
        if(localStorage.user)
        {
          var userID = JSON.parse(localStorage.user);
          var wallet = userID.aff_wallet;//await userService.getWallet(userID.aff_id);
          //var wallet = userID.wallet;
          Axios.get('https://ulan-gitlab-heroku-env-staging.herokuapp.com/affwallet/' + wallet).then( //
          res => { //
            this.pending_amount = res.data.pending_amount
            this.confirmed_amount = res.data.confirmed_amount
            this.paid_amount = res.data.paid_amount
            this.click_count = res.data.click_count
            this.conversion_count = res.data.conversion_count
            //this.pending_amount= res.data.total
          }
          )
        }
      }catch(err) {
        this.error = err.message;
      }
  },
  /*watch: {
    wallet:  async function () {
      return await Axios.get('https://ulan-gitlab-heroku-env-staging.herokuapp.com/wallets/5e3062c8b44f82001754e94b');
      
    }
  },*/
  methods: {
            
        },
  /*computed: {
    deneme(){
      var url = ''
      var queryString = url.substring( url.indexOf('?') + 1 );

      var params = {}, queries, temp, i, l;

    // Split into key/value pairs
    queries = queryString.split("&");

    // Convert the array of strings into an object
    for ( i = 0, l = queries.length; i < l; i++ ) {
        temp = queries[i].split('=');
        params[temp[0]] = decodeURIComponent(temp[1]);
    }

    return params;

    }
  }*/

}
</script>

<style scoped>

body {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.content {
  padding: 0 30px 30px;
  min-height: calc(100vh - 250px);
  margin-top: 0px;
}

.content .row-container{
    padding-top: 10px;
}

.card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.75);
  background-color: #FFFFFF;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}


.card .card-body {
  padding: 15px 15px 10px 15px;
}

.card .numbers {
  font-size: 0.8em;
}

.card .card-body.table-full-width {
  padding-left: 0;
  padding-right: 0;
}

.card .card-footer {
  background-color: transparent;
  border: 0;
}

.card[data-background-color="orange"] .card-footer .stats {
  color: #FFFFFF;
}

.card .card-footer .stats i {
  margin-right: 5px;
  position: relative;
  top: 0px;
  color: #66615B;
}


.card-stats .card-body {
  padding: 15px 15px 0px;
}

.card-stats .card-body .numbers {
  text-align: right;
  font-size: 2em;

}

.card-stats .card-body .numbers p {
  margin-bottom: 0;
}

.card-stats .card-body .numbers .card-category {
  color: #9A9A9A;
  font-size: 0.4em;
  line-height: 1.4em;
  font-family: 'Muli', "Helvetica", Arial, sans-serif;
}

.card-stats .card-body .numbers .card-title{
  font-size: 0.8em !important;
  line-height: 1.4em;
  padding-top: 14px;
}


.card-stats .card-footer {
  padding: 0px 15px 15px;
}
 
.card-stats .card-footer .stats {
  color: #9A9A9A;
}

.card-stats .card-footer hr {
  margin-top: 10px;
  margin-bottom: 15px;
}

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px;
}

.card-stats .icon-big i {
  line-height: 59px;
}

.card-category {
    text-transform: capitalize;
    font-weight: 400;
    color: #9A9A9A;
    font-size: 0.7142em;
}

@media screen and (max-width: 768px) {

    .wallet-tabs{
      padding-left:0px;
      padding-right: 5px;
    }

    .wallet-card{
      padding: 0px;
    }

    .wallet-nav-tab{
      padding: 1px;
    }

    .content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .card-stats [class*="col-"] .statistics::after {
    display: none;
    }

}

@media screen and (max-width: 576px) {

    .card-contributions .card-stats {
    flex-direction: column;
    }

    .card-contributions .card-stats .bootstrap-switch {
    margin-bottom: 15px;
  }

}
</style>
