<template>
  <div class="container">
    <form @submit.prevent="handleSubmit">
      <div
        class="form-group col-lg-8 offset-lg-2 self-align-right login_container"
      >
        <router-link to="/" class="navbar-brand offset-5">
          <img
            src="../assets/linkadoo_logo_mini.png"
            style="
              width: 40px;
              height: 40px;
              margin-bottom: 40px;
              margin-top: 20px;
            "
          />
        </router-link>
        <h2 class="offset-lg-2" style="padding-left: 2%">
          Linkadoo'ya Giriş Yap
        </h2>
        <div
          v-if="alert.message"
          class="offset-lg-2 invalid-feedback error-div"
        >
          Hatalı Kullanıcı Adı, Mail veya Şifre Girişi
        </div>
        <div
          v-if="alert.message && alert.message.includes('pending')"
          class="modal fade"
          id="appModal"
          role="dialog"
        >
          <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content header-modal">
              <div class="modal-header">
                <p
                  class="modal-title center-text"
                  style="color: black; text-align: center"
                >
                  Linkadoo’ya hoş geldin!<br />
                  Hesabını inceleyip en geç 24 saat içerisinde sana haber
                  vereceğiz.<br />
                  Soruların için bize info@linkadoo.me mail adresimizden veya
                  instagram hesabımızdan ulaşabilirsin
                </p>
                <button
                  type="button"
                  class="close shadow-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
              </div>
              <!--<div class="modal-body">
                                <p> Bu ürün linkini tüm çevrenle paylaş, paylaştıkça kazan!!</p>
                            </div>
                            <div class="modal-footer d-flex justify-content-between"> 
                            </div>-->
            </div>
          </div>
        </div>

        <div class="form-group col-lg-6 offset-lg-2 col-sm-12">
          <label for="username">Kullanıcı Adı veya Email</label>
          <input
            style="text-transform: lowercase"
            type="text"
            v-model="username"
            name="username"
            class="form-control"
            :class="{ 'is-invalid': submitted && !username }"
          />
          <div v-show="submitted && !username" class="invalid-feedback">
            Kullanıcı Adın veya Mailin Gerekli
          </div>
        </div>
        <div class="form-group col-lg-6 offset-lg-2 col-sm-12">
          <label htmlFor="password">Şifre</label>
          <input
            type="password"
            v-model="password"
            name="password"
            class="form-control"
            :class="{ 'is-invalid': submitted && !password }"
          />
          <div v-show="submitted && !password" class="invalid-feedback">
            Şifre girmen gerekir
          </div>
          <router-link
            to="/begin-password-reset"
            style="color: rgb(7, 164, 175); font-size: 14px"
            >Şifremi Unuttum</router-link
          >
        </div>
        <div class="form-group col-lg-8 offset-lg-2 col-sm-12">
          <button
            class="btn btn-primary kaydol"
            :disabled="status.loggingIn"
            data-toggle="modal"
            data-target="#appModal"
          >
            Giriş Yap
          </button>
          <img v-show="status.loggingIn" src="../assets/loading.svg" />
          <router-link to="/signup" class="btn btn-link" style="color: #e64769"
            >Kaydol</router-link
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
    };
  },
  components: {},
  computed: {
    ...mapState("account", ["status"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  created() {
    // reset login status
    this.logout();
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    handleSubmit() {
      this.submitted = true;

      var { username, password } = this;

      username = encodeURIComponent(username.toLowerCase().replace("i̇", "i"));

      const statusURL =
        "https://ulan-gitlab-heroku-env-staging.herokuapp.com/affiliaters/getStatus/" +
        username;

      if (username && password) {
        axios.get(statusURL).then((res) => {
          res = res.data;
          if (res.status == 1) this.login({ username, password });
          else {
            var msg = res.message1 + "\n" + res.message2 + "\n" + res.message3;
            alert(msg);
          }
        });
      }
    },
  },
  mounted() {
    this.observer;
  },
};
</script>

<style scoped>
.header-modal {
  background-color: white;
  border-radius: 10px;
  color: #e64769;
}

.modal-header {
  padding: 1.5rem 2.5rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  padding-left: 2.5rem;
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
  padding-top: 0.5%;
}

.modal-title {
  padding-top: 1rem;
}

.error-div {
  display: block;
  padding-left: 10px;
}

.form-group {
  padding: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.container {
  margin-top: 30px;
}

.login_container {
  background: white;
  box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

.kaydol {
  background-color: rgb(7, 164, 175);
  border-color: rgb(7, 164, 175);
}

.kaydol:hover,
.kaydol:focus,
.kaydol:active {
  outline: none !important;
  box-shadow: none;
}

@media screen and (max-width: 860px) {
  .container {
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
