<template>
    <div style="padding-left: 0px" class="container">
            <div style="padding-bottom: 100px; " class="col col-lg-12  self-align-right login_container">
                <div style="padding-top: 20px; overflow-y: auto; height: 100vh;" class="col col-lg-12 col-sm-12">   
                    
                    <ol>
                    <li><b> TARAFLAR</b>
                       <p> İşbu İşbirliği Sözleşmesi (“Sözleşme”), Linkadoo Pazarlama Teknolojileri ve Danışmanlık Anonim Şirketi (“Linkadoo”) ile işbu Sözleşme’yi online ortamda onaylayarak bilgilerini Linkadoo ile paylaşan İş Ortağı (“İş Ortağı”) arasında online ortamda onaylanmış ve onaylandığı tarihte yürürlüğe girmiştir. İşbu Sözleşme’de Linkadoo ve İş Ortağı ayrı ayrı “Taraf” birlikte “Taraflar” olarak anılabilecektir.
                       </p>
                    </li>
                    <li> <b> TANIMLAR</b>
                        <ol>
                            <li>Reklam Veren: Bu sözleşmenin tarafı olmayan dışında, Linkadoo’nun müşterisi olan ve her türlü ürün, hizmet ve ticari emtiası ile markasının tanıtımı yapılan gerçek ve tüzel kişilerdir.</li>
                            <li>Web Sitesi: www.linkadoo.me internet sitesidir.</li>
                            <li>Son Kullanıcı: Linkadoo’ya ait internet sitesi üzerinden oluşturulan linklerin İş Ortağı tarafında çeşitli sosyal medya platformlarında gören ve tıklayarak alışveriş  işlemini gerçekleştiren gerçek ve tüzel kişidir.</li>
                        </ol>
                    </li>
                    <li> <b>  KONU</b>
                        <p> 
                            İşbu Sözleşme’nin konusu gerçekleştirilecek işbirliğine ilişkin Taraflar’ın karşılıklı hak ve yükümlülüklerinin belirlenmesidir. İş Ortağı, işbu Sözleşme konusu hizmetlerin doğrudan kendisi tarafından yerine getirileceğini taahhüt etmektedir
                       </p>                    
                    </li>
                    <li> <b> SÜRE</b>
                        <p> 
                            İşbu Sözleşme İş Ortağı tarafından Linkadoo’ya ait internet sitesinde İş Ortağı’nın formun doldurulması ve onay kutusunun işaretlenmesi suretiyle yürürlüğe girecek olup Taraflar’ca feshedilmediği müddetçe yürürlükte kalacaktır.
                       </p>                    
                    </li>
                    <li> <b> TARAFLAR’IN HAK VE YÜKÜMLÜLÜKLERİ </b>
                        <ol>
                            <li>
Taraflar arasında gerçekleştirilecek işbirliği kapsamında İş Ortağı, Linkadoo’da üretilen linklerde yer alan markalar ile bağlantılı  içerik üretecek, bu çerçevede Linkadoo’da üretilen linklerde yer alan  ürün, hizmet ve her türlü ticari emtia ile markasının tanıtımını yapacak, satışının yapılmasına etki etmesi ile satışın gerçekleşmesini sağlayacak ve ürünlerin satışa sunulduğu bir online statik sayfa (link) oluşturacak, bu linkleri sosyal medya platformlarında paylaşacaktır.                                 </li>
                            <li>
İş Ortağı kendisine ait trafik ve satış verilerini oluşturmuş olduğu üyelik hesabı üzerinden inceleyebilecektir. İş Ortağı Linkadoo tarafından ayrıca bir bilgilendirme yapılmayacağını kabul, beyan ve taahhüt eder.
                            </li>
                            <li>
İş Ortağı,  aşağıda belirtilen hususlarda kendisinden beklenen nitelikleri haiz olduğunu beyan ve taahhüt eder: 
                            <ol type="I">
                                <li>
                                    Sözleşme konusu işleri yerine getirirken daima uygun ve profesyonel bir şekilde davranacaktır;
                                </li>
                                <li>
                                    Linkadoo’nun menfaatlerini korumak, bildirmiş olduğu linklerde yer alan markaları tanıtmak ve verdiği hizmeti geliştirmek adına elinden gelen en iyi çabayı gösterecektir;
                                </li>
                            </ol>                            
                            </li>
                            <li>
                                İş Ortağı, Sözleşme konusu iş kalemlerini yerine getirirken Linkadoo ve Linkadoo ile bağlantılı diğer markaların ve Linkadoo’da üretilen linklerde yer alan markaların imajına uygun hareket edeceğini, Linkadoo ve kendisine bildirilen markalar için negatif bir söylemde bulunmayacağını ve/veya herhangi bir şekilde bu markaları kötülemeyeceğini kabul, beyan ve taahhüt eder. 
                            </li>
                            <li>
                                İş Ortağı, Linkadoo tarafından işbu Sözleşme’de belirtilmeyen ancak makul nitelikte her türlü iş ve hizmeti sunmayı kabul eder.
                            </li>
                            <li>
                               İş Ortağı kendi medyasında yer alan, tüm bilgi ve içeriğe ilişkin hak ve sorumlulukların kendisine ait olduğunu, herhangi bir paylaşımının yasadışı olmadığını, üçüncü kişilerin fikri ve mülkiyet haklarını veya kişisel haklarını ihlal etmediğini, zararlı, tehdit ve hakaret içerikli, küçük düşürücü, müstehcen ve taciz içerikli olmadığını, ayrıca şiddet içermediğini, içeriklerin din, dil, ırk, etnik köken, cinsiyet, milliyet, engellilik, yaş ve sair ayrımcılık içermediğini ve/veya yasadışı faaliyetleri övücü ve teşvik eder nitelikte olmadığını, fikri ve sınaim mülkiyet haklarına ilişkin olarak yürürlükte bulunan hukuki ve cezai tüm kanun ve ulusal /uluslararası sözleşme ve yasal kurallara, doğrudan veya dolaylı olarak aykırı olmadığını ve aykırı içerikte bulunmadığını, netice olarak; Linkadoo’nun İş Ortağı’na sakıncalı olduğunu bildirdiği içerikler de dahil diğer tüm düzenlemeleri sağladığını kabul, beyan ve taahhüt eder. 
                            </li>
                            <li>
                                İş Ortağı, kendisi ve medyası hakkında platforma sunduğu bilgilerin doğru, güncel ve eksiksiz olduğunu, gerekli düzene uygun biçimde gönderildiğini ve bu bilgilerin fiili gerçeklere uygun olduğunu kabul ve beyan etmektedir. İş Ortağı, bu bilgilerde meydana gelen her türlü değişikliği platformun ilgili kısmında kendisi hakkında yer alan bilgileri güncelleyerek Linkadoo’ya derhal bildirmelidir.
                            </li>
                            <li>
                                İş Ortağı işbu Sözleşme kapsamında platform tarafından temin edilen hizmetler çerçevesinde Linkadoo’nun yazılı onayı olmadan hiçbir gerçek ve/veya tüzel kişiye Linkadoo ile yürütülmekte olunan hiçbir teklifi göndermeyecektir. İş Ortağı, işbu sözleşme kapsamında Linkadoo tarafından hizmet temin edilen hiçbir Reklam Veren ile ilgili olarak, üçüncü bir tedarikçiye veya benzer nitelikte iş yapan hiçbir kişi veya kuruma devretmeyeceğini, doğrudan ya da dolaylı olarak transfer etmesine sebebiyet vermeyeceğini kabul ve beyan etmektedir. İş Ortağı hiçbir reklam vereni böyle bir devrin gerçekleştirilmesi yönünde teşvik etmeyecektir. İş Ortağı, herhangi bir Reklam Veren’e doğrudan ya da dolaylı olarak talep götürmesi veya bir bağlı şirketine devredilmesine sebep olması halinde, Linkadoo’ya, İş Ortağı bu maddeyi ihlal etmemiş olsaydı Linkadoo’nun kazanmış olacağı miktarı ödeyecektir; ayrıca bu durumda Linkadoo ilgili İş Ortağı’na yapacağı ödemeleri askıya alma veya iptal etme konusunda seçimlik hakka sahiptir.
                            </li>
                            <li>
                                Linkadoo, hizmetteki kusurlardan, hizmete erişimdeki aksaklıklardan, bilgi taşıma sistemindeki verilere yönelik ihlallerden veya veri kaybından, Web Sitesi hizmetinin güvenlik sistemindeki kusurlardan, virüslerden veya diğer zararlı yazılım bileşenlerinden dolayı hizmete, İş Ortağı’nın yazılımına ve/veya İş Ortağı’nın websitesine verilen herhangi bir zarardan ötürü sorumlu olmayacaktır. Linkadoo, İş Ortağı medya bağlantılarını gerçekleştirilmesinde meydana gelen herhangi bir hatadan veya bağlantıların belirtilen fonksiyonlarında meydana gelebilecek her türlü aksaklık ve zarardan sorumlu olmayacaktır.
                            <br>
                                Linkadoo işbu maddede belirtilen hallerde İş Ortağı’nın bağlantıları aracılığıyla gönderilen tüm işlemleri silme, ödenmemiş komisyonları durdurma veya dondurma ve ödenen komisyonları geri alma haklarını saklı tutmaktadır. Linkadoo, İş Ortağı’nın bu sözleşmeyi ihlal ettiğini belirler veya tespit ederse , bu sözleşmeyi ihlal ettiğine ilişkin makul ve inandırıcı bir şikayet alır veya herhangi bir nitelikli eylemin daha sonra bu sözleşmede ve İş Ortağı Programı’nda belirtilen şartları yerine getirmediğini tespit ederse; komisyonların bu şekilde durdurulmasını veya dondurulmasını, gerekirse ödenen komisyonların geri alınması, söz konusu komisyonların bu ihlal nedeniyle kazanılıp kazanılmadığına bakılmaksızın gerçekleştirilebilir. İşbu sözleşmenin ciddi bir şekilde ihlal edilmesi durumunda, Linkadoo, İş Ortağı’nın kimliğini ve iletişim bilgilerini resmi kurum ve kuruluşlarla veya eylemleri tarafından doğrudan zarar görmüş üçüncü taraflara açıklama hakkını saklı tutar.

                            </li>
                            <li>
                                İş Ortağı Linkadoo’da üretilen linklerde yer alan ürün, hizmet ve her türlü ticari emtia ile markalara ilişkin tanıtımının yapılması için üreteceği içeriklerin Fikir ve Sanat Eserleri Kanunu, Sınai Mülkiyet Kanunu ve bunlarla sınırlı kalmamak üzere sair kanuni düzenlemelere aykırılık teşkil etmesi halinde tüm sorumluluğun kendisine ait olduğunu, Linkadoo’nun bu içerikler açısından hiçbir sorumluluğunun bulunmadığını, hiçbir koşul ve şart altında bu içerikler neticesinde ödemek zorunda kalacağı bedelleri Linkadoo’dan talep etmeyeceğini kabul, beyan ve taahhüt eder. 
                            </li>
                        </ol>             
                    </li>
                    
                    <!--<li>
                        Paylaşım yaptığınız linkten gelen kullanıcının 5 gün içerisinde yaptığı alışverişlerden komisyon kazanabilirsiniz.
                    </li>-->
                    <li><b>SÖZLEŞME BEDELİ VE ÖDEME</b>
                        <ol>
                            <li>
                                Sözleşme’ye konu ve Reklam Verenler’in ürün, hizmet ve her türlü ticari emtiasına ilişkin olarak, İş Ortağı’na verilecek ücret, komisyon ve sair ödemeler ile ilgili teklifin koşulları Linkadoo tarafından münhasıran, tek yanlı olarak belirlenir. Linkadoo gerekli gördüğünde tek taraflı olarak, bu koşulları istediği şekilde değiştirilebilir veya sunulan teklifi ortadan kaldırılabilir, bu durumu yine Web Sitesi üzerinden düzenleyerek ilan etmiş olur. İş Ortağı bu koşullara uymayı peşinen kabul ve taahhüt etmiştir.
                            </li>
                            <li>
                                Linkadoo İş Ortağı tarafından yapılan paylaşımlara istinaden ödenecek komisyon oranlarını Web Sitesi üzerinden ilan edecektir.
                            </li>
                            <li>
                                İş Ortağı, aşağıdaki hususları peşinen kabul, beyan ve taahhüt eder:
                                <ol>
                                    <li type="i" class="romen">
                                        İş Ortağı tarafından paylaşılan linkten satış olduğunda bu miktar "Bekleyen Kazanç" alanına düşecektir. Bu aşamada İş Ortağı’na herhangi bir ödeme yapılmayacaktır.
                                    </li>
                                    <li type="i" class="romen">
                                        İş Ortağı tarafından ürünleri tanıtılan marka/firma tarafından İş Ortağı’nın “Bekleyen Kazanç’larının” onaylanması ve marka/firma tarafından Linkadoo’ya fatura kesildiğinde “Onaylanan Kazanç” statüsüne geçilecektir.
                                    </li>
                                    <li type="i" class="romen">
                                        İş Ortağı "Onaylanan Kazanç" değeri 100 TL'yi aştığı her ayın 25'ine kadar, kazancını çekmek istediğini Linkadoo’ya iletirse ve bu isteğin Linkadoo tarafından uygun bulunması halinde takip eden ayın 15'ine kadar ödemeler yapılacaktır. 
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Linkadoo’nun işbu Sözleşme’deki yükümlülüklerini mücbir sebep halleri dahil olmak üzere herhangi bir sebeple yerine getiremediği takdirde, reklamı yapılan firmaların haklarında kapatılma, kayyuma devredilme, iflas, konkordato kararı verildiği takdirde ya da reklamı yapılan firmaların ödeme yapmadığı takdirde ve/veya mücbir sebeplerin oluşması durumunda İş Ortağı ödeme talep etme hakkı olmayacağını kabul, beyan ve taahhüt eder.
                            </li>
                            <li>
                                İş Ortağı 213 Sayılı Vergi Usul Kanunu ve 3065 Sayılı Katma Değer Vergisi Kanunu uyarınca sunduğu hizmetle ilgili fatura ve/veya gider pusulası düzenlemek zorundadır. İş Ortağı’nın işbu yükümlülüklerini gereği gibi yerine getirmemesi sebebiyle Linkadoo’nun ödemek zorunda kalacağı adli ve/veya idari para cezaları dahil tüm zararlarını, üçüncü kişi taleplerinden doğan tazminat borcu dahil yaptığı tüm ödemeleri ve masrafları (mahkeme masrafları, para cezaları, vergi, resim, harç dahil giderleri) Linkadoo’nun ilk talebinde herhangi bir mahkeme kararı ve/veya 3. Kişilerin onayına gerek kalmaksızın nakden ve defaten ödemeyi kabul, beyan ve taahhüt eder. 
                            </li>
                            <li>
                                Linkadoo tarafından İş Ortağı tarafından ödemeler yapılırken bu ödemelerden vergi mevzuatlarında öngörülen oranda vergi kesintilerini yapma hakkına sahiptir. Vergi kesintisi yapılması ödemeyi alan tarafın vergisel sorumlulukları ile ilgili yükümlülüklerin Linkadoo tarafından yerine getirildiği anlamına gelmez. Bu süreçte gerektiği takdirde ıslak imza da dahil olmak üzere sair yollarla gelir elde eden kişilerden bilgi ve belgeler talep edilebilir.
                            </li>
                        </ol>
                    </li>
                    <li><b>DİĞER YÜKÜMLÜLÜKLER</b>
                        <ol>
                            <li>
                                İş Ortağı, işbu Sözleşme’nin olağan süresince Gizli Bilgilere erişimi olduğunu kabul eder. İşbu Sözleşme süresi içinde ve işbu anlaşmanın feshinden sonra (her halükarda bunun meydana gelmesi durumunda) İş Ortağı, hizmetlerin sağlanmasına ilişkin aşağıdakileri yapmayacaktır: i) işbu Anlaşma süresince İş Ortağı’na gizli olarak verilen veya ifşa edilen Linkadoo’nun müşterileri, çalışanları ve memurlarının dahil olduğu taraflara ait herhangi bir Gizli Bilgiyi; ii) kendi amaçları ya da herhangi bir kişi, şirket, grup şirketi, kurum ya da kuruluşun amaçları için kullanmayacak, iii) herhangi bir kişiye, şirkete, grup şirketine, kurum veya kuruluşa açıklamayacak, iv) Linkadoo’nun yazılı iznini almaksızın; halen bildiği ya da çalışmaları sırasında öğrendiği, Linkadoo’nun işleri, finansal durumu, sırları, faaliyetleri, anlaşmaları, işlemleri veya menfaatlerine ilişkin herhangi bir gizli bilgiyi veya Linkadoo’dan almakta olduğu her nevi ücret ile niteliği her ne olursa olsun elde ettiği menfaatleri üçüncü kişilere açıklamayacak ve bu tür bilgilerin açıklanmasını önleyecektir. İşbu gizlilik yükümlüğü Sözleşme herhangi bir nedenle sona erse de yürürlükte kalmaya devam edecektir.
                            </li>
                            <li>
                                Hukuken mücbir sebep sayılan tüm durumlarda, Linkadoo işbu Sözleşme ile belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle sorumlu olmayacaktır. Mücbir sebep hallerinde; gecikme, eksik ifa etme, ifa etmeme durumları temerrüt addedilmeyecek veya bu durumlar için Linkadoo’dan herhangi bir nam altında tazminat talep edilemeyecektir. Mücbir sebep terimi; doğal afet, isyan, savaş, grev, siber saldırı, iletişim sorunları, altyapı ve internet arızaları, sisteme ilişkin iyileştirme veya yenileştirme çalışmaları ve bu sebeple meydana gelebilecek arızalar, elektrik kesintisi ve 
                            </li>
                            <li>
                                Linkadoo tarafından İş Ortağı’na yapılacak duyurulara istinaden Linkadoo bu sözleşmedeki şartları değiştirebilecek ve/veya yeni şartlar getirebilecektir. Bu kapsamda İş Ortağı değiştirilen ve/veya yeni şartlar getirilen bu yükümlülükleri yerine getireceğini ve anılan yükümlülüklere uygun davranacağını kabul, beyan ve taahhüt eder.
                            </li>
                            <li>
                                Taraflar’ın her zaman diğer Taraf’a yazılı bir bildirimde bulunarak iş Sözleşme’yi feshetme hakkı saklıdır.
                            </li>
                            <li>
                                Taraflar’ın işbu Sözleşme tahtındaki haklardan herhangi birinin kullanılmaması veya bunların kullanılmasının ertelenmesi haklardan feragat olarak değerlendirilmeyecek ve söz konusu hakların tek başına veya kısmi olarak kullanılması, diğer hakların kullanılmasını engellemeyecektir.
                            </li>
                            <li>
                                İşbu Sözleşme’nin herhangi bir hükmünün herhangi bir zamanda herhangi bir mahkeme ya da yetkili idari organ tarafından geçersiz, yasa dışı veya uygulanamaz bulunması durumunda, bu tür bir geçersizlik, yasa dışılık ya da uygulanamazlık hali; tam olarak yürürlükte kalacak ve geçerli olacak işbu Sözleşme’nin diğer hükümlerini etkilemez.
                            </li>
                            <li>
                                Taraflar, yetkilendirdikleri kişiler arasında gerçekleştirilecek mail ortamı dahil tüm yazışmaların bağlayıcı olduğunu, bu yazışmalarda mutabık kalınan hususların işbu Sözleşme’nin eki niteliğinde olacağını ve bunlara herhangi bir itiraz ileri sürmeyeceklerini kabul, beyan ve taahhüt ederler.
                            </li>
                            <li>
                                İşbu Sözleşme’nin yorumlanmasında ve uygulanmasında Türkiye Cumhuriyeti Hukuku geçerli olacaktır. İşbu Sözleşme’den doğabilecek her türlü uyuşmazlıkta İstanbul Merkez (Çağlayan) Mahkemeleri ve İcra Daireleri yetkilidir.
                            </li>
                            <li>
                                İş Ortağı, Linkadoo’dan yazılı onay almadan işbu Sözleşme kapsamındaki haklarının ya da yükümlülüklerinin herhangi birini devredemez ve/veya temlik edemez.
                            </li>
                        </ol>
                    </li>             
                    <li><b>YÜRÜRLÜK</b>
                        <p>
                            8 (sekiz) maddeden ibaret bu işbu Sözleşme, Taraflarca okunarak, İş Ortağı tarafından Linkadoo’ya ait internet sitesinde formun doldurulması ve onay kutusunun işaretlenmesi suretiyle akdedilmiş ve işbu onay işleminin gerçekleştiği tarihte yürürlüğe girmiştir.
                        </p>
                    </li>
                    </ol> 
                </div>
            </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
ol {
    list-style: none;
    counter-reset: item; 
}

ol > li {
    counter-increment: item;
}
    
ol > li::before{
    margin-right: 10px;
    content: counters(item, ".") ".";
    display: inline-block;
}

.romen::before{
    content: none !important;
}


.container{
    margin-top: 0px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
    font-size: 12px;
}

@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>