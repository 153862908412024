<template>
<section id="how-can-i-use" class="story-1">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-10 col-sm-6 col-xl-5">
                <div class="content-img">
                    <img src="../../assets/story-1.png">
                </div>
            </div>
            <div class="col-sm-6 col-xl-5 offset-xl-1">
                <div class="content-right-content">
                    <h2>Create links dedicated to you, for the products you love</h2>
                    <p>You can convert the product or campaign link you want to share to a Linkadoo link with just one click.</p>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "LinkadooStory"
}
</script>

<style scoped>
.story-1{
    background-color: #f8f8f8;
    padding: 60px 0 30px;
}

.content-img{
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-img > img{
    width: 100%;
}
img{
    max-width: 100%;
}
.content-right-content{
    padding-top: 30px;
    text-align: left;
}
.content-right-content > h2{
        padding-bottom: 25px;
        font-weight: 600;
        letter-spacing: 0px;
        font-size: 30px;
        line-height: 40px;
        font-family: 'Montserrat', sans-serif; 
    }

.content-right-content > p{
        font-weight: 500;
        letter-spacing: -0.4px;
        font-size: 18px;
        line-height: 30px;
        font-family: 'Montserrat', sans-serif; 
    }
 
@media (min-width: 576px){
    .content-right-content{
        padding-top: 0px;
    }
    .content-right-content > h2{
        font-size: 26px;
        line-height: 36px;
    }

    .content-right-content > p{
        font-size: 17px;
        line-height: 24px;
    }
}

@media (min-width: 768px){
    .content-right-content > h2{
        font-size: 30px;
        line-height: 44px;
    }

    .content-right-content > p{
        font-size: 21px;
        line-height: 36px;
    }
}

@media (min-width: 992px) {
    .content-right-content {
        padding-top: 60px;
    }

    .content-img{
        padding-bottom: 0px;
    }
    
    .story-1{
        padding: 120px 0 80px;
    }

    .content-right-content > h2{
        font-size: 38px;
        line-height: 60px;
    }

    .content-right-content > p{
        font-size: 21px;
        line-height: 36px;
    }
}

@media (min-width: 1200px) {
    .content-right-content{
        padding-top: 83px;
    }
}
</style>