import { authHeader } from '../_helpers';
import axios from 'axios';

const url = "https://ulan-gitlab-heroku-env-staging.herokuapp.com"

export const userService = {
    login,
    logout,
    getAll,
    register,
    emailusernameCheck,
    getWallet,
    /*   getAll,
       getById, 
       update,
       delete: _delete*/
};

function login(username, password) {
    return axios.post(url + '/affiliaters/authenticate', {
        username: username,
        password: password
    }).then(res => {
        if (res.data.token) {
            localStorage.setItem('user', JSON.stringify(res.data));
        }
        return res
    })
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(username, password, firstName, lastName, email, categories, consent, social_media_accounts, referrer_id) {
    /*const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };*/
    //return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);

    return axios.post(url + '/affiliaters/register', {
        username: username,
        password: password,
        firstName: firstName,
        lastName: lastName,
        email: email,
        categories: categories,
        agreed_mailings: consent,
        agreed_user_agreement: true,
        social_media_accounts: social_media_accounts,
        referrer_id: referrer_id
    }).then(res => { return res })
}
function emailusernameCheck(username, email) {
    return axios.post(url + '/affiliaters/exists', {
        username: username,
        email: email,
    }).then(res => {
        return res
    })
}

function getAll() {
    return axios.get(url + '/affiliaters/all', {}, {
        headers: authHeader()
    });
}

function getWallet(userID) {

    return axios.post(url + '/affiliaters/wallet', {
        userID: userID
    }).then(res => {
        return res;
    }).catch(err => { return err; })
}
/*
function getById(id) {
}

function update(user) {

}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
}*/
/*
function handleResponse(response) {
    return response.text().then(text => {
        const data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}*/