<template>
  <div class="col-12 hero-banner">
    <vue-agile
      :autoplay="true"
      :autoplaySpeed="5000"
      :navButtons="false"
      :speed="500"
    >
      <!--<div class="slide">
            <img class="d-none d-md-block img-big" src="../../assets/mainbanner1.png">
            <img class="d-block d-md-none img-small" src="../../assets/main_banner_mobile.png">
        </div>-->
      <!--<div v-for="(banner, index) in headbanners" class="slide"
            v-bind:item = "banner"
            v-bind:index = "index"
            v-bind:key = "index"
        >
          <a v-bind:href="banner.href_link" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FWatsons_17_23_08_desktop.png?alt=media&token=f2e9429d-7c09-4ee1-8190-a8528770185f">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fwatsons_17_23_08_mobile.png?alt=media&token=88298235-96a7-4cf0-b5e5-2d8c33b4f4af">
            
              <img class="d-none d-md-block img-big" v-bind:src="banner.banner_url_desktop">
              <img class="d-block d-md-none img-small" v-bind:src="banner.banner_url">

            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.ciceksepeti.com/indirim-var-var" target="_blank">
              
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FCiceksepeti_varvar_desktop.png?alt=media&token=7bcb1af4-bf2b-465e-b581-fc090ef9d910">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FCiceksepeti_acilis_mobile.png?alt=media&token=a743e3a4-a544-470c-9473-334b01dff23b">

            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.ciceksepeti.com/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2F13_14_15_10_Ciceksepeti%2Fmainbanner-desktop-c%CC%A7s-varvar%20(2).png?alt=media&token=4160106c-45a6-4547-b318-954dcd64a97b">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2F13_14_15_10_Ciceksepeti%2Fmainbanner-mobile-c%CC%A7s-varvar.png?alt=media&token=86c0f883-644d-47bc-8f4b-2e2ff811eb2b">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.linkadoo.me" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner_web_ty_install.png?alt=media&token=9e4995b0-fea1-42c4-8b0a-cd26a9f87823">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner_mweb_ty_install.png?alt=media&token=70cc9cde-aa7b-4f64-85af-d42884d1058d">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.linkadoo.co/linkadoo/YaxjJxigT" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner_desktop_linkadoo_son.png?alt=media&token=f27ad72b-2340-4617-a360-f3c35118c66c">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner_mweb_linkadoo_son.png?alt=media&token=87344106-5ed0-41a3-9142-7888ecdd0bce">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.watsons.com.tr" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fblack_friday%2FWatsons%20web%20mainbanner.png?alt=media&token=be753715-a632-4710-a9fb-8938a464ebe5">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fblack_friday%2Fwatsons%20mweb%20mainbanner.png?alt=media&token=d5e47b78-967b-46d9-aa79-b6fff5ac04df">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.englishhome.com" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fblack_friday%2FEH%20web%20mainbanner.png?alt=media&token=dd57de67-acd2-42b9-89da-83b2bb7ad685">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fblack_friday%2FEH%20mweb%20mainbanner.png?alt=media&token=a78bfa07-df2d-4075-8b0f-5bd0200dd252">
            </a>
        </div>

        <div class="slide">
          <a href="https://www.flormar.com.tr" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fblack_friday%2Fflormar%20desktop%20mainbanner.png?alt=media&token=62f7ebf2-1391-47fe-891a-0da16f2fbc28">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fblack_friday%2Fflormar%20mweb%20mainbanner.png?alt=media&token=b74843d7-c49e-4276-9f8a-040a86cd82da">
            </a>
        </div>-->

      <div class="slide">
        <a
          href="https://www.yukarikaydir.com/linkadoo/YaxjJxigT"
          target="_blank"
        >
          <img
            class="d-none d-md-block img-big"
            src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner_web_sadeceuygulamada.png?alt=media&token=a40b2322-f546-4b9c-a3a5-177a435a2381"
          />
          <img
            class="d-block d-md-none img-small"
            src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmanbanner_mweb_mobiluygulamada.png?alt=media&token=6157dfff-71f3-4234-aae5-e5fbbdd3edbc"
          />
        </a>
      </div>

      <!--<div class="slide">
          <a href="https://www.linkadoo.me">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-desktop-ciceksepeti-hediyeceki.png?alt=media&token=34b014a4-36b4-4902-b49d-670213110bad">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-mobile-ciceksepeti-hediyeceki.png?alt=media&token=03200f89-4910-4950-9b2a-487cd187a63d">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.linkadoo.me">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner_desktop_cs_app2.png?alt=media&token=a8da99ca-7b7d-4ea0-be80-e1562c75c74c">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner_mobil_cs_app2.png?alt=media&token=77d16f70-7ff2-4730-9f70-3614647a8a0e">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.flormar.com.tr" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner_desktop_flormar_2.png?alt=media&token=71ce5c58-1269-41ab-9d0c-5b1645a264f9">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner_mobile_flormar_2.png?alt=media&token=fa66bb1e-06dd-405b-8729-a440df7e8aa8">
            </a>
        </div>-->

      <!-- <div class="slide">
          <a href="https://www.ciceksepeti.com/favori-secimler" target="_blank">
              
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fevent_brand_banners%2Fmainbanner-desktop-ciceksepeti.png?alt=media&token=8f218cc4-01a7-46c8-a2dd-a6d0e47da898">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fevent_brand_banners%2Fmainbanner-mobile-ciceksepeti.png?alt=media&token=a746283c-913c-47f8-9823-c55961f10b0b">

            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.evyapshop.com/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FEvyapShop_desktop.png?alt=media&token=8223b992-ac8f-4292-b7f9-1cc98ea53aa8">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FEvyapShop_mobile.png?alt=media&token=2c9c1436-5c09-4896-b230-e0d4f8ea89ec">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.bellamaison.com/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-desktop-bellamaison.png?alt=media&token=ae3aae9a-246e-4152-a4e4-2da0897cff70">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-mobile-bellamaison.png?alt=media&token=45020efb-f5e9-4562-b274-e12a01f010cf">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.newessentials.com.tr/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-desktop-newessentials.png?alt=media&token=641e1eae-14c3-457d-9b4e-2c5ce18683c4">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-mobile-newessentials.png?alt=media&token=86734234-2fe7-4ca9-b3b9-a7fc832edb9e">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.chakra.com.tr/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fevent_brand_banners%2Fmainbanner-desktop-chakra.png?alt=media&token=298b5ef5-5fdc-49d1-b42e-bb684ed37d3c">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fevent_brand_banners%2Fmainbanner-mobile-chakra.png?alt=media&token=d9bd15e4-5fc3-4e98-a9e0-8b9bea3ac92f">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.vivense.com/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2F18_09_vivense%2Fmainbanner-desktop-vivense_18_09.png?alt=media&token=9d0751e8-b33a-46f2-ab85-3a41d46e1f18">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2F18_09_vivense%2Fmainbanner-mobile-vivense_18_09.png?alt=media&token=4f87d649-a4d1-4cd3-8901-6451cc30d34d">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.thebodyshop.com.tr/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fevent_brand_banners%2Fmainbanner-desktop-tbs.png?alt=media&token=87fa156d-6c3e-48fb-ac1f-29dce249513e">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fevent_brand_banners%2Fmainbanner-mobile-tbs.png?alt=media&token=07661687-d99f-4261-b918-9662fda4c5f4">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.perabulvari.com/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-desktop-perabulvari.png?alt=media&token=bec8aee9-1bb8-42e3-a51b-679e354008da">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-mobile-perabulvari.png?alt=media&token=11ed69f3-15a9-4a9b-b3b5-c861a98ec457">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.krc.com.tr/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FKaraca_headbanner_desktop.png?alt=media&token=4ac5cdb1-d396-4f1f-a495-799d8e9f5484">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FKaraca_headbanner_mobile.png?alt=media&token=b32f44ca-0b2d-48d6-b64a-ac517c239433">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.bernardo.com.tr/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-bernardo11.09.png?alt=media&token=05821782-f11c-41ee-a37c-55e3c16f8114">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fmainbanner-bernardo11.09-mobile.png?alt=media&token=42eb2415-0472-45bd-a706-57b692868cab">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.watsons.com.tr" target="_blank">
              
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2F5_6_eylul_20%2Fmainbanner-desktop-0509-watsons.png?alt=media&token=ba47236d-3336-4645-b329-75820c1bbfc9">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2F5_6_eylul_20%2Fmainbanner-0509-watsons_mobile.png?alt=media&token=2354cc61-da90-489d-9497-e7b4dc53204e">

            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.evyapshop.com/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FEvyapShop_desktop.png?alt=media&token=8223b992-ac8f-4292-b7f9-1cc98ea53aa8">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FEvyapShop_mobile.png?alt=media&token=2c9c1436-5c09-4896-b230-e0d4f8ea89ec">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.englishhome.com/yatak-odasi-indirim/" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fkampanya_alarmi-englishhome-desktop.png?alt=media&token=a760f069-0924-4fe6-9caa-87528b351720">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fkampanya_alarmi-englishhome-mobile.png?alt=media&token=a61a7b10-c451-40f8-bb62-86adeb32fef8">
            </a>
        </div>-->

      <!--<div class="slide">
          <a href="https://www.vivense.com" target="_blank">
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FVivense_desktop.png?alt=media&token=5aa7ff15-524e-45e2-ab85-1c94a8820673">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2FVivense_mobile.png?alt=media&token=4ba64d49-c01f-4073-a444-a385439c6621">
              
              <img class="d-none d-md-block img-big" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fkampanya_alarmi-vivense.png?alt=media&token=7783d84a-d7f0-4960-94ed-b804b966ad9b">
              <img class="d-block d-md-none img-small" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/head-banner%2Fkampanya_alarmi-mobile-vivense.png?alt=media&token=5c5354e9-5b4c-4ba6-95ea-5e7684c038df">
            
            </a>
        </div>-->
    </vue-agile>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import Axios from "axios";

export default {
  name: "HeroBanner",
  components: {
    VueAgile,
  },
  data() {
    return {
      headbanners: [],
    };
  },

  async created() {
    Axios.get(
      "https://campaign-detail-api-v2-38e3b6518b5b.herokuapp.com/api/headbanners"
    ).then(
      //
      (res) => {
        this.headbanners = res.data.data;
      }
    );
  },

  methods: {},
};
</script>

<style>
.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 80px;
}

.agile__nav-button:hover {
  background-color: rgba(#000, 0.5);
  opacity: 1;
}

.agile__nav-button--prev {
  left: 0;
}

.agile__nav-button--next {
  right: 0;
}

.agile__dots {
  margin-bottom: 2px;
  bottom: 0px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.agile__dot {
  margin: 0 3px;
}

.agile__dot > button {
  background-color: transparent;
  border: 1px solid rgb(7, 164, 175);
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}

.agile__dot--current > button,
.agile__dot:hover > button {
  background-color: rgb(7, 164, 175);
}

.hero-banner {
  padding-left: 0px;
  padding-right: 0px;
}

.img-big {
  width: 100%;
  display: block;
  position: relative;
  height: 200px;
}

.img-small {
  width: 100vw;
}
</style>
