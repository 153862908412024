<template>
<div class="container">
    <div class="col align-self-center">
        <h1> Alışverişiniz Hayrola. </h1>
       
            <div v-if="amount">
              <h4> Aff ID: {{aff_id}}</h4>
      
              <h4> Campaign ID: {{campaign_id}} </h4>
      
              <h4> Amount: {{amount}} </h4>
        
            </div>
            <div v-else>
            <h1> Beybi no info here plz bye </h1>
            </div>
        </div>
   
</div>
</template>

<script>
export default {
    data() {
        return {
            aff_id: "",
            campaign_id: "",
            amount: ""
        }
    },
    mounted() {
        this.aff_id = this.$route.params.aff_id;
        this.amount =  this.$route.params.amount;
        this.campaign_id =  this.$route.params.campaign_id;
    }
};
</script>

<style scoped>

</style>