<template>

<section id="contact-form">
        <div class="container">
            <div class="mb-5 text-center contact-h2">
                <h2 style="color: white; font-family: 'Indie Flower', cursive; font-size: 2.8rem">Bize Ulaşın</h2>
            </div>
        </div>
        <div class="container" id="form-container">
                <form action="https://ulan-gitlab-heroku-env-staging.herokuapp.com/business" method="POST">
                    <div class="row form-group d-flex justify-content-center" id="form-row">
                        <!--<label for="exampleFormControlInput1">Email address</label>-->
                        <div class="col-sm-4 col-md-6 col-lg-4">
                            <input type="text" name="name" class="form-control form-control-lg" id="exampleFormControlInput1" placeholder="İsminiz">
                        </div>
                    </div>
                    <div class="row form-group d-flex justify-content-center" id="form-row">
                            <!--<label for="exampleFormControlInput1">Email address</label>-->
                            <div class="col-sm-4 col-md-6 col-lg-4">
                                <input type="email" name="email" class="form-control form-control-lg" id="exampleFormControlInput1" placeholder="E-mail Adresiniz">
                            </div>
                    </div>
                    <div class="row form-group d-flex justify-content-center" id="form-row">
                            <!--<label for="exampleFormControlInput1">Email address</label>-->
                            <div class="col-sm-4 col-md-6 col-lg-4">
                                <input type="text" name="comp_name" class="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Şirketinizin İsmi">
                            </div>
                    </div>
                    <div class="row form-group d-flex justify-content-center" id="form-row">
                        <!--<label for="exampleFormControlTextarea1">Example textarea</label>-->
                        <div class="col-sm-4 col-md-6 col-lg-4">
                            <textarea class="form-control form-control-lg" name="message" id="exampleFormControlTextarea1" rows="4" placeholder="Size Nasıl Yardımcı Olabiliriz?"></textarea>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center" id="form-row">
                        <div class="col-sm-4 col-md-6 col-lg-4" style="padding-left: 0px;">
                        <input type="submit" class="btn btn-outline-primary btn-lg btn-block submitt" value="Gönder"/>
                        </div>
                    </div>
                </form>
        </div>  
    </section>    

</template>

<script>
export default {
    name: "WhatBussForm"
}
</script>

<style scoped>

#contact-form{
    margin-left: -21.4%;
    width: 100vw;
    background: rgba(230, 71, 105, 0.8);
    /*background-color: transparent !important;*/
    /*background: rgba(230, 71,105, 0.08);*/ 
    padding-top: 10%; 
}

.contact-h2{
    margin-bottom: 1rem!important;
}

#form-container{
    padding-bottom: 5%;
}

#form-row{
    padding-top: 1%;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid #E64769;
    margin: 1em 0;
    padding: 0;
    width: 35%;
}

.submitt{
    color: white !important;
    border-color: #E64769 !important;
    background: rgb(7, 164, 175) !important;
}

.submitt:hover{
    background: rgb(8, 202, 216) !important;
}

@media (max-width: 767px){
    #contact-form{
        margin-left: 0px;
    }
}
</style>