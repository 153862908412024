<template>
  <div>
    <hero-banner
      data-intro="Merhaba! Ürünler Önererek Kazanmaya Hazır mısın?"
      data-position="bottom"
    />

    <div
      class="col-12 link-container"
      data-intro="Paylaşmadan önce linklerini burada oluşturabilirsin..."
      data-position="bottom"
    >
      <create-link-container />
    </div>
    <div class="row campaign_row">
      <category-filter />
      <div class="col-lg-9 col-12" style="padding: 0px">
        <div class="campaign_bigger">
          <p class="error" v-if="error">{{ error }}</p>
          <div
            class="row"
            v-for="(campaigns, category_name) in groupedCampaigns"
            :key="category_name"
          >
            <div
              class="col-12 campaign_group"
              style="padding-bottom: 10px; padding-top: 10px"
            >
              <!--<div style="float:left;">
      <img src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/Other%2Fflas%CC%A7%20f%C4%B1rsat.png?alt=media&token=47b70613-c9e0-40f9-827f-a0a4beb454d5" width="49" height="7">
    </div>-->
              <div style="float: left">
                <router-link
                  v-bind:to="
                    '/markalar/all-categories/' +
                    beautifyCategory(category_name)
                  "
                  onClick="FireEvent(this.href)"
                >
                  <h4>{{ category_name }}</h4>
                </router-link>
              </div>
              <!--<div v-if="category_name === 'Ev & Yaşam'" style="float:left; padding-left: 5px;">
      <img src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/Other%2Fflas%CC%A7%20f%C4%B1rsat.png?alt=media&token=47b70613-c9e0-40f9-827f-a0a4beb454d5" width="56" height="8">
    </div>-->
            </div>
            <div
              class="container-fluid mobile_scrollable"
              style="width: 100%; padding-left: 4%"
            >
              <div class="row flex-row flex-nowrap">
                <div
                  class="col-5 col-lg-3 campaign_cards"
                  v-for="(campaign, index) in campaigns.slice(0, 15)"
                  v-bind:item="campaign"
                  v-bind:index="index"
                  v-bind:key="campaign._id"
                >
                  <div class="card card-user">
                    <div class="image">
                      <img
                        v-if="campaign.category_name === 'Uygulama İndirme'"
                        src="../assets/card_background/home/a.png"
                        alt="..."
                      />
                      <!--  -->
                      <img
                        v-if="campaign.category_name === 'Moda'"
                        src="../assets/card_background/fashion/a.png"
                        alt="..."
                      />
                      <!--  -->
                      <img
                        v-if="
                          campaign.category_name === 'Kozmetik & Kişisel Bakım'
                        "
                        src="../assets/card_background/beauty/a.png"
                        alt="..."
                      />
                      <!--  -->
                      <!--<img v-if="campaign.vendor_name === 'Watsons'" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/Other%2Fcosmetics-komisyon%20art%C4%B1s%CC%A7%C4%B1.png?alt=media&token=f455ed23-026c-4d86-a5c6-069466c42138"  alt="..."> -->
                      <img
                        v-if="campaign.category_name === 'Elektronik'"
                        src="../assets/card_background/electronic/a.png"
                        alt="..."
                      />
                      <!--  -->
                      <img
                        v-if="campaign.category_name === 'Ev & Yaşam'"
                        src="../assets/card_background/home/a.png"
                        alt="..."
                      />
                      <!--  -->
                      <!--<img v-if="campaign.vendor_name == 'Bernardo' || campaign.vendor_name == 'Vivense' || campaign.vendor_name == 'Chakra' || campaign.vendor_name == 'Jua Home' || campaign.vendor_name == 'Karaca' || campaign.vendor_name == 'Akayev'" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/Other%2Fhome-11ekim.png?alt=media&token=ad7aceec-d14e-4ce5-a4b0-14454951224e"  alt="...">-->

                      <!--<img v-if="campaign.vendor_name === 'Bernardo'" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/Other%2Fhome%20coupon2.png?alt=media&token=fffe8cb0-e424-4e0f-bf91-5c35279f66a2"  alt="...">
                -->
                      <!--<img v-if="campaign.category_name === 'Ev & Yaşam'" src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/Other%2Fhome%20v3.png?alt=media&token=244e1cb4-5654-4d23-bd60-e6200f1c39f6"  alt="...">-->
                      <img
                        v-if="campaign.category_name === 'Yemek & Süpermarket'"
                        src="../assets/card_background/market/a.png"
                        alt="..."
                      />
                      <!--  -->
                      <img
                        v-if="campaign.category_name === 'Seyahat'"
                        src="../assets/card_background/travel/a.png"
                        alt="..."
                      />
                      <!--  -->
                    </div>
                    <div>
                      <div class="card-body">
                        <div class="align-self-center author">
                          <img
                            class="avatar border-gray"
                            v-bind:src="campaign.banner_url"
                            alt="..."
                          />
                          <!--../assets/mike.jpg-->
                          <div
                            style="
                              font-size: 20px;
                              color: rgb(7, 164, 175);
                              margin-top: -20px;
                              margin-bottom: 0px;
                              padding: 0px;
                            "
                            class="title"
                          >
                            <a
                              style="color: rgb(7, 164, 175)"
                              :href="
                                '/campaign-detail/' +
                                campaign.vendor_name
                                  .replace(/ /g, '')
                                  .toLowerCase()
                              "
                            >
                              <p
                                v-if="
                                  campaign.category_name != 'Uygulama İndirme'
                                "
                                style="
                                  display: inline;
                                  text-decoration: underline;
                                "
                              >
                                {{ campaign.max_offer }}%
                              </p>
                              <p
                                v-if="campaign.vendor_name == 'Teknosa'"
                                style="
                                  position: relative;
                                  top: -12px;
                                  display: inline;
                                  font-size: 10px;
                                "
                              >
                                'e kadar
                              </p>
                              <p
                                v-if="
                                  campaign.category_name == 'Uygulama İndirme'
                                "
                                style="
                                  display: inline;
                                  text-decoration: underline;
                                "
                              >
                                {{ campaign.max_offer }}₺
                              </p></a
                            >
                          </div>
                        </div>
                      </div>
                      <button
                        v-if="campaign.category_name != 'Uygulama İndirme'"
                        @click="redirect(campaign.domain)"
                        v-bind:id="campaign.vendor_name"
                        type="button"
                        v-bind:class="
                          'buttonn btn btn-outline-secondary btn-sm btn-block haberver kesfet ' +
                          campaign.vendor_name
                        "
                      >
                        Ürünleri Keşfet
                      </button>

                      <button
                        v-if="campaign.category_name != 'Uygulama İndirme'"
                        @click="getSmartLink(campaign.domain)"
                        v-bind:id="campaign.vendor_name"
                        type="button"
                        v-bind:class="'buttonn btn btn-outline-secondary btn-sm btn-block quicklink'"
                        data-target="#appModals"
                        data-toggle="modal"
                      >
                        Hızlı Link Al
                      </button>

                      <button
                        v-if="campaign.category_name == 'Uygulama İndirme'"
                        style="margin-top: 15px; padding-top: 10px"
                        @click="getInstallLink(campaign.beautified_vendor_name)"
                        v-bind:id="campaign.vendor_name"
                        type="button"
                        v-bind:class="'buttonn btn btn-outline-secondary btn-sm btn-block quicklink'"
                        data-target="#appModals"
                        data-toggle="modal"
                      >
                        Linkini Al
                      </button>
                      <input id="cb" type="text" hidden />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal -->
            <div
              style="white-space: none"
              class="modal fade"
              id="appModals"
              role="dialog"
            >
              <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content header-modal">
                  <div class="modal-header">
                    <!-- v-if="is_englishhome == false" -->
                    <p class="modal-title center-text">
                      Ürün Linkin Kopyalandı,<br />
                      Hemen şimdi Paylaş! <br /><a
                        v-bind:href="linkadoolink"
                        target="_blank"
                        style="font-size: 12px; color: rgb(7, 164, 175)"
                      >
                        {{ linkadoolink }}</a
                      >
                    </p>
                    <button
                      type="button"
                      class="close shadow-none"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      &times;
                    </button>
                  </div>
                  <!--<div v-if="is_englishhome == true" class="modal-body">
                                <p>Markanın “beta” dönem değerlendirmesi bekleniyor. Değerlendirme sonuçlandıktan sonra tekrar link oluşturabilirsiniz.</p>
                                    <div class="col-12 offset-lg-3 col-lg-6 col-lg-6 btn-next-wrapper justify-content-start" style="text-align: center;">
                                        <button style="width: 100%; color: white" type= "button" class="btn btn-next" data-target="#appModalsEng" data-toggle="modal" aria-label="Close"
                                        >Anladım</button>
                                    </div>
                            </div>-->

                  <!--<div class="modal-body">
                                <p> Bu ürün linkini tüm çevrenle paylaş, paylaştıkça kazan!!</p>
                            </div>
                            <div class="modal-footer d-flex justify-content-between"> 
                            </div>-->
                </div>
              </div>
            </div>

            <div
              style="white-space: none"
              class="modal fade"
              id="appModalsEng"
              role="dialog"
            >
              <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content header-modal">
                  <div class="modal-header">
                    <p class="modal-title center-text">
                      Ürün Linkin Kopyalandı,<br />
                      Hemen şimdi Paylaş! <br /><a
                        v-bind:href="linkadoolink"
                        target="_blank"
                        style="font-size: 12px; color: rgb(7, 164, 175)"
                      >
                        {{ linkadoolink }}</a
                      >
                    </p>
                    <button
                      type="button"
                      class="close shadow-none"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      &times;
                    </button>
                  </div>
                  <!--<div class="modal-body">
                                <p> Bu ürün linkini tüm çevrenle paylaş, paylaştıkça kazan!!</p>
                            </div>
                            <div class="modal-footer d-flex justify-content-between"> 
                            </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import AffOffersService from '../../AffOffersService';
import AffOffersService from "../_services/aff_offer.service";
import CategoryFilter from "../components/layout/CategoryFilter.vue";
//import ReferralContainer from "./ReferralContainer.vue";
import CreateLinkContainer from "../components/layout/CreateLinkContainer.vue";
import HeroBanner from "../components/layout/HeroBanner.vue";
import "intro.js/minified/introjs.min.css";
import "intro.js/themes/introjs-modern.css";

import axios from "axios";
import { router } from "../router.js";

var _ = require("lodash");

export default {
  name: "AffOffersFirstv2",

  components: {
    CategoryFilter,
    //ReferralContainer,
    CreateLinkContainer,
    HeroBanner,
  },

  data() {
    return {
      campaigns: [],
      error: "",
      text: "",
      button_text: "",
      post_msg: "",
      linkadoolink: "",
      is_englishhome: false,
    };
  },
  mounted() {
    var userID = JSON.parse(localStorage.user);
    if (userID.first_enterance) {
      const introJS = require("intro.js");
      introJS
        .introJs()
        .setOptions({
          prevLabel: "Geri",
          nextLabel: "İleri",
          skipLabel: "Geç",
          doneLabel: "Anladım",
          exitOnOverlayClick: false,
          overlayOpacity: 0.8,
        })
        .start();
      userID.first_enterance = false;
      localStorage.setItem("user", JSON.stringify(userID));
      axios
        .post(
          "https://ulan-gitlab-heroku-env-staging.herokuapp.com/affiliaters/enterance",
          { userID: userID.aff_id }
        )
        .then((res) => {
          return res;
        });
    }
    if (JSON.parse(localStorage.user).aff_id == 434) {
      router.push({ name: "logout" });
    }
  },

  async created() {
    try {
      this.campaigns = await AffOffersService.getCampaigns();
    } catch (err) {
      this.error = err.message;
    }
  },
  computed: {
    groupedCampaigns() {
      var grouped = _.groupBy(this.campaigns, "category_name");

      return _.reverse(grouped);
    },
  },
  methods: {
    getInstallLink(beautified_vendor_name) {
      var aff_id = JSON.parse(localStorage.user).aff_id;
      axios
        .post("https://www.yukarikaydir.com/api/v1/links/install", {
          aff_id: aff_id,
          vendor_name: beautified_vendor_name,
          source: "mweb",
        })
        .then((res) => {
          const resData = res.data.data;
          if (res.data.message == "success") {
            const linkadooLink =
              "https://www.yukarikaydir.com/" +
              resData.vendor_name +
              "/" +
              resData.link_key;
            this.linkadoolink = linkadooLink;
            this.copyStringToClipboard(linkadooLink);
          }
        });
    },
    getSmartLink(campaign_domain) {
      if (campaign_domain.includes("englishhome")) {
        this.is_englishhome = true;
      } else {
        this.is_englishhome = false;
      }

      var aff_id = JSON.parse(localStorage.user).aff_id;
      axios
        .post("https://www.yukarikaydir.com/api/v1/links/", {
          aff_id: aff_id,
          product_url: "https://www." + campaign_domain,
          source: "mweb",
        })
        .then((res) => {
          const resData = res.data.data;
          if (res.data.message == "success") {
            const linkadooLink =
              "https://www.yukarikaydir.com/" +
              resData.vendor_name +
              "/" +
              resData.link_key;
            this.linkadoolink = linkadooLink;
            this.copyStringToClipboard(linkadooLink);
          }
        });
    },
    copyStringToClipboard(str) {
      // Create new element
      var el = document.createElement("textarea");
      // Set value (string to be copied)
      el.value = str;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute("readonly", "");
      el.style = { position: "absolute", left: "-9999px" };
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand("copy");
      // Remove temporary element
      document.body.removeChild(el);
    },
    clickedButton: function (vendor_name) {
      //this.$refs[vendor_name].innerText = 'serhatEmreCebeci'
      //alert(JSON.stringify(this.ref));
      //var campaign_btn = document.getElementById(vendor_name)
      var btn_list = document.getElementsByClassName(vendor_name);
      for (let i = 0; i < btn_list.length; i++) {
        var campaign_btn = btn_list[i];

        var icon = document.createElement("i");

        if (
          campaign_btn.className ===
          "btn btn-outline-secondary btn-sm btn-block cta-btn haberver " +
            vendor_name
        ) {
          campaign_btn.className =
            "btn btn-outline-secondary btn-sm btn-block cta-btn " + vendor_name;
          icon.setAttribute("class", "fas fa-bell");

          campaign_btn.innerText = "Gelince Haber Ver";
          campaign_btn.appendChild(icon);
        } else {
          campaign_btn.className =
            "btn btn-outline-secondary btn-sm btn-block cta-btn haberver " +
            vendor_name;
          icon.setAttribute("class", "far fa-check-circle");

          campaign_btn.innerText = "Onaylandı";
          campaign_btn.appendChild(icon);
        }
      }
    },
    beautifyCategory(category_name) {
      var beautified_category = category_name
        .replace("ş", "s")
        .replace("ı", "i")
        .replace("ö", "o")
        .replace("ü", "u")
        .replace("ğ", "g")
        .replace("ç", "c")
        .replace("Ş", "S")
        .replace("Ç", "C")
        .replace("Ü", "U")
        .replace("Ö", "O")
        .replace("İ", "I")
        .replace(" & ", "&")
        .replace(" ", "");
      return beautified_category;
    },
    redirect(domain) {
      var url = "https://www." + domain;
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.btn-next {
  text-align: center;
  color: #fff;
  background-color: rgb(7, 164, 175);
  border-color: rgb(7, 164, 175);
  border-radius: 30px;
}

.quicklink {
  margin: 0px;
  background-color: #e64769 !important;
  transition: none;
  color: white !important;
}

.kesfet {
  border-radius: 0px !important;
}

body {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
}

.card {
  max-height: 170px;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: rgb(241, 238, 234); /* #FFFFFF*/
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
}

.card .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  /*border-radius: 50%;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;*/
  border: 1px solid white;
  margin-bottom: 20px;
}

.card .image {
  overflow: hidden;
  height: 200px;
  position: relative;
}

.card-user .image {
  height: 90px;
}

.card-user .image img {
  border-radius: 12px;
  border: 1px solid rgb(241, 238, 234);
}

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -37px;
}

.card .card-body {
  padding: 15px 15px 10px 15px;
}

.card .card-footer {
  background-color: transparent;
  border: 0;
}

img {
  max-width: 100%;
  border-radius: 3px;
}

.card-user .avatar {
  width: 124px; /*124px;*/
  height: 42px; /*124px;*/
  border: 1px solid #ffffff;
  position: relative;
}

.card-user hr {
  margin: 5px 15px 15px;
}

.card-user .card-body + .card-footer {
  padding-top: 0;
}

.card-user .card-body h5 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.card-user .button-container {
  margin-bottom: 6px;
  text-align: center;
}

/*.inner{
    padding-left: 6%;
    padding-right: 6%;
}*/

.main_content {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;
}

/*.main_content > [class*='col-'] {
    display: flex;
    flex-direction: column;
}*/

.campaign_group h4 {
  font-family: "Merriweather", serif;
  color: #636f7b; /*#66615B;*/
  margin-bottom: 0px;
  font-size: 1rem;
  padding-top: 3%;
  padding-bottom: 2%;
}

/*.card{
    background-color: rgb(241, 238, 234) !important; /*#eff1f4*/
/*border: 0px ;
    border-style: solid;
    border-color: #636F7B!important;
    /*border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}*/

.card:hover {
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.75);
}

.campaign_cards {
  padding-bottom: 3%;
}

.card .buttonn {
  padding-left: 0%;
  padding-right: 0%;

  border-radius: 0 0 12px 12px;

  position: relative;
  color: white;
  border: 0px solid #e64769;
  background-color: #e64769;
  /*box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.75);
    /*box-shadow: 0px, 2px, 4px, 0px rgb(125, 125, 125, 0.18);*/
}

.campaign_bigger {
  border: 0px solid #ddd;
  background-color: white;
  padding: 0%;
  padding-left: 3%;
  /*margin-left: 3%;*/
  padding-right: 3%;
  border-radius: 5px 5px 5px 5px;
}

.campaign_row {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 2%;
}
.cta-btn {
  font-size: 0.75rem;
}

.btn:hover {
  background-color: rgba(230, 71, 105, 0.9) !important;
  text-decoration: none;
  outline-color: none !important;
  box-shadow: none;
  /*outline-color: linear-gradient(60deg, #ffa726, #fb8c00) !important;
    box-shadow: none;*/
}

.linkinhazir {
  background-color: rgb(7, 164, 175) !important;
  transition: none;
}

.haberver {
  background-color: rgb(7, 164, 175) !important;
  transition: none;
}

.haberver:hover {
  background-color: rgba(7, 164, 175, 0.9) !important;
  text-decoration: none;
  outline-color: none !important;
  /*box-shadow: none;*/
  /*outline-color: linear-gradient(60deg, #ffa726, #fb8c00) !important;
    box-shadow: none;*/
}

.categori_bucket > h4 {
  font-size: 1rem;
  color: #636f7b;
}

.categori_bucket {
  padding-top: 15%;
}

.category_list > li > a {
  font-size: 0.75rem;
  font-weight: 400;
  color: rgb(75, 74, 74) !important; /**/
  font-size: 13px;
}

.category_list > li > a:hover {
  color: gray !important;
}

.category_list > li:hover {
  /*: rgba(0,0,0,0.58) !important;*/
  /*background-color: rgba(0, 0, 0, 0.18); */
  text-decoration: none;
  outline-color: none !important;
}
.category_list > li > :hover {
  text-decoration: none;
  outline-color: none !important;
}

.link-container {
  padding-left: 0px;
  padding-right: 0px;
}

@media (min-width: 768px) {
  .campaign_bigger {
    margin-left: 4.333333%;
  }

  .mobile_scrollable {
    padding-right: 10%;
    overflow-x: auto;
    width: 60vw;
  }
}
@media screen and (max-width: 860px) {
  .link-container {
    padding-right: 0px !important;
  }
  .campaign_bigger {
    background-color: transparent;
  }

  .card {
    background-color: #ffffff;
  }

  .card-user .image img {
    border: 0px;
  }

  .campaign_bigger {
    margin-left: 0px;
    padding: 0;
  }
  .campaign_bigger > .row {
    margin-right: 0px;
  }

  .campaign_row {
    margin-right: 0px !important;
  }
  .cta-btn {
    font-size: 0.75rem;
  }

  .mobile_scrollable {
    padding-right: 0px;
  }
  .mobile_scrollable > .row {
    /*overflow: scroll;*/
    overflow-x: auto !important;
    white-space: nowrap;
    -ms-overflow-style: none;
    margin-right: 0px;
    padding-left: 10px;
  }

  .mobile_scrollable > .row::-webkit-scrollbar {
    display: none;
  }

  .campaign_cards {
    display: inline-block;
    float: none;
  }
}
</style>
