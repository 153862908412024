<template>
    <section id="features">
            <div class="container">
                    <div class="nav-tab" role="tablist" style="padding-left:10%">
                        <h1>Nasıl Çalışır?</h1>
                        <hr/>
                        <div class="row mb-5 no-margin" >
                            <div class="col-md-6" style="padding:1px">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <a class="card nav-link active" data-toggle="tab" id="businessProfileCard" href="#business-profile">              
                                            <h4 style="text-align: left">Markanız için Bir Profil Oluşturun</h4>
                                            <p style="text-align: left">u-LAN kullanıcılarının sizinle ilgili görmek istediği her ayrıntı ile profilinizi oluşturabilir, sayfanızda kampanyalarınızı duyurabilirsiniz</p>
                                        </a>       
                                    </li>
                                    <li class="nav-item">
                                        <a class="card nav-link"  data-toggle="tab" id="offersCard" href="#publish-offers">              
                                                <h4 style="text-align: left">Kampanyalarınızı Oluşturun</h4>
                                                <p style="text-align: left">Segmentlere özel veya tüm kulllanıcılara özel kampanyalarınızı oluşturarak, kampanyalarınızın geniş kitlelere yayılmasını sağlayabilirsiniz.</p>
                                        </a>       
                                    </li>
                                    <li class="nav-item">
                                            <a class="card nav-link"  data-toggle="tab" id="payForPerformanceCard" href="#pay-for-performance" >              
                                                <h4 style="text-align: left">Sadece Satış Gerçekleştiğinde Ödeyin</h4>
                                                <p style="text-align: left">Satışlar için vereceğiniz komisyonu istediğiniz gibi özelleştirebilirsiniz. Sadece satış gerçekleştiğinde ödeme yapacağınız için, performans ölçütlerinize özel komisyon ayarlamaları yapabilirsiniz.</p>
                                            </a>       
                                    </li>
                                    <li class="nav-item">
                                        <a class="card nav-link"  data-toggle="tab" id="trackPerformanceCard" href="#track-performance">              
                                                <h4 style="text-align: left">Performansınızı Takip Edin</h4>
                                                <p style="text-align: left"> Kampanyanızın performansını size özel sağladığımız otomatik raporlarla takip edebilir, optimizasyonlarla kazancınızı arttırabilirsiniz.</p>
                                        </a>       
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-6 d-none d-md-block" style="padding-top:13%">
                                <div class = "tab-content float-right"> 
                                    <div id="business-profile" class="tab-pane fade active show" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <img src="../../assets/profile_page_1.png">
                                    </div>

                                    <div id="publish-offers" class="tab-pane fade" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <img src="../../assets/create_offer.png">
                                    </div>

                                    <div id="track-performance" class="tab-pane fade" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <img src="../../assets/ulan_tracking_1.png">
                                    </div>

                                    <div id="pay-for-performance" class="tab-pane fade" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <img src="../../assets/ulan_tracking_1.png"> <!---->
                                    </div> 

                                </div>                 
                            </div>
                        </div>
                    </div>
                </div>
    </section>
</template>

<script>
export default {
    name: "WhatBussSecond"
    
}
</script>

<style scoped>

#features{
    margin-left: -21.4%;
    width: 100vw;
    background-color: white !important;
    
}

.nav-item {
    color: white !important;
}

.nav-item:hover{
    background-color: rgba(0, 0, 0, 0.08);
}

a.nav-link:link
{
   color: #000000;
   text-decoration: none;
}
a.nav-link:visited
{
   color: #000000;
   text-decoration: none;
}
a.nav-link:hover
{
   color: #000000;
   text-decoration: none;
}
a.nav-link:active
{
   color: #000000;
   text-decoration: none;
}

.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin:25px;
    padding:20px;
    border-radius: 25px; /* 5px rounded corners */
    
 }
  
/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid #E64769;
    margin: 1em 0;
    padding: 0;
    width: 35%;
}

.no-margin{
    margin-bottom: 0px !important;
}
@media (max-width: 767px){
    #features{
        margin-left: 0px;
    }
}
</style>