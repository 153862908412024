<template>
    <div style="padding-left: 0px" class="container">
            <div style="padding-bottom: 100px; " class="col col-lg-12  self-align-right login_container">
                <div style="text-align: center;">
                    <router-link to="/" class="navbar-brand">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/campaign-details-logo%2Fjumbo_logo_1x1.png?alt=media&token=71459212-eaf3-4cc9-b556-bafce4644964" style="width: 180px; height: 180px; margin-bottom: 0px; margin-top: 20px;"/>
                    </router-link> 
                </div>
                <div style="padding-top: 0px;" class="col col-lg-12 col-sm-12">   
                    <ul>
                    <li>
                        Kampanya <a style="color: rgb(7, 164, 175)" href="https://jumbo.com.tr" target="_blank">jumbo.com.tr</a> sitesinden yapılacak tüm alışverişlerde geçerlidir.
                    </li>
                    <li>
                        Kazanılan komisyon tutarı, ürünün KDV ve kargo hariç bedeli üzerinden hesaplanmaktadır.                
                    </li>
                    <li>
                        Google, Facebook ve diğer reklam ağlarının paralı reklam seçenekleri ile getirilen trafikten komisyon kazanamazsınız.

                    </li>
                    <li>
                        Fraud yöntemleri ile sistemi kandırmaya çalışan kullanıcılarımız kalıcı olarak üyelikten çıkarılacak, önceden devreden komisyon hakları sıfırlanacaktır.
                    </li>
                    <!--<li>
                        Paylaşım yaptığınız linkten gelen kullanıcının 5 gün içerisinde yaptığı alışverişlerden komisyon kazanabilirsiniz.
                    </li>-->
                    <li>
                        Her ayın 25'inden önce <b>“Onaylanan Kazanç”</b> alanında biriken tutarı çekmeyi talep edebilirsiniz. Bu tutar sonraki ayın 15'ine kadar hesabınıza devletin zorunlu tuttuğu vergiler düşülerek aktarılacaktır.
                    </li>
                    <li>
                        <b>“Onaylanan Kazanç”</b> alanındaki tutar 100 TL’yi geçtiğinde komisyonu çekme talebi iletebilirsiniz                    
                    </li>              
                    <li>
                        Paylaşımlarınızda kullandığınız içerik yasadışı, argo, müstehcen ve zararlı nitelikte olmamalıdır. Bu tür paylaşımlardan elde edilen gelir red edilecektir.
                    </li>
                    <li>
                        Linkadoo komisyon ve kampanya koşullarında değişiklik yapma hakkını saklı tutar                    
                    </li>
                    <li>
                        Satın alım yapan kullanıcının internet tarayıcısı nedenli; çerez engelleme, internet erişim sorunu nedeniyle sipariş onaylanmasının Linkadoo’ya yapılamaması gibi durumlarda data alınamayacağından komisyon yansıtılamayacaktır. Bu gibi bir durum yaşadığınızda <b>info@linkadoo.me</b> mail adresi ile iletişime geçebilirsiniz.
                    </li>
                    <li>
                        Markanın sipariş onaylama ve ödeme süresi ortalama 30 gündür.
                    </li>
                    </ul> 
                </div>
            </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.container{
    margin-top: 0px;
    
}

.login_container{
    background: white;
    box-shadow: 0 6px 10px -10px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 860px) {
    .container{
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>