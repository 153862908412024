import Vue from "vue";
import Router from "vue-router";

//import HomePage from './views/HomePage'
import LoginPage from "./views/LoginPage"; //import Login from "./components/layout/Login.vue";
import LogoutPage from "./views/LogoutPage.vue";
/*import RegisterPage from './views/RegisterPage';*/
import RegisterPagev2 from "./views/RegisterPage_v2";
import DemoTransactionPage from "./views/DemoTransactionPage";
import DemoConversionPage from "./views/DemoConversionPage";
//import AffOffers from "./views/AffOffers.vue"; //"./components/layout/AffOffers.vue"
//import AffOffersFirst from "./views/AffOffersFirst.vue";
import AffOffersFirstv2 from "./views/AffOffersFirstv2.vue";
//import SidebarMenu from "./components/layout/SideHeader.vue";
//import SideHeader from "./components/layout/SideHeader.vue";
//import Header from "./components/layout/Header.vue";
//import Header from "./components/layout/Header_new.vue";
import Header from "./components/layout/Header_new_new.vue";
import HeaderLandingBusiness from "./components/layout/Header_Landing_Business.vue";
import HeaderLanding from "./components/layout/Header_Landing.vue";
import HeaderLandingEng from "./components/layout/Header_LandingEng.vue";
import AffDashboard from "./views/AffDashboard.vue";
import AffDashboardDetails from "./views/AffDashboardDetails.vue";

import Categories from "./views/Categories.vue";
import LandingPage from "./views/LandingPage.vue";
import LandingPageEng from "./views/LandingPageEng.vue";
import LandingPageBusiness from "./views/LandingPageBusiness.vue";

import CreateLinkContainer from "./components/layout/CreateLinkContainer.vue";
import LinkStatistics from "./components/layout/LinkStatistics.vue";
//import Signup from "./components/layout/Signup.vue";
//import axios from "axios";

//const config = require('../config.json');
import Footer from "./components/layout/Footer.vue";
/*import LinkadooFeatures from "./components/layout/LinkadooFeatures.vue";
import LinkadooStory from "./components/layout/LinkadooStory.vue";
import LinkadooStorySecond from "./components/layout/LinkadooStorySecond.vue";
import LinkadooStoryThird from "./components/layout/LinkadooStoryThird.vue";*/

import ForgotPassword from "./views/ForgotPassword.vue";
import NewPasswordConfirm from "./views/NewPasswordConfirm.vue";
import SendResetPassword from "./views/SendResetPassword.vue";
import SuccessPasswordReset from "./views/SuccessPasswordReset.vue";

import BeginDeleteAccount from "./views/BeginDeleteAccount.vue";
import DeleteAccountConfirm from "./views/DeleteAccountConfirm.vue";
import SendDeleteAccount from "./views/SendDeleteAccount.vue";

/* Campaign Detail Pages */
import ChakraDetail from "./views/ChakraDetail.vue";
import JuahomeDetail from "./views/JuahomeDetail.vue";
import theMiaDetail from "./views/theMiaDetail.vue";
import HaticeteyzeDetail from "./views/HaticeteyzeDetail.vue";
import TeknosaDetail from "./views/TeknosaDetail.vue";
import KuaybeGiderDetail from "./views/KuaybeGiderDetail.vue";
import BernardoDetail from "./views/BernardoDetail.vue";
import SetreDetail from "./views/SetreDetail.vue";
import TheBodyShopDetail from "./views/TheBodyShopDetail.vue";
import MooiButikDetail from "./views/MooiButikDetail.vue";
import DermokozmetikaDetail from "./views/DermokozmetikaDetail.vue";
import WatsonsDetail from "./views/WatsonsDetail.vue";
import VivenseDetail from "./views/VivenseDetail.vue";
import EvyapShopDetail from "./views/EvyapShopDetail.vue";
import KaracaDetail from "./views/KaracaDetail.vue";
import EnglishHomeDetail from "./views/EnglishHomeDetail.vue";
import CicekSepetiDetail from "./views/CicekSepetiDetail.vue";
import KaracaHomeDetail from "./views/KaracaHomeDetail.vue";
import PeraBulvariDetail from "./views/PeraBulvariDetail.vue";
import JumboDetail from "./views/JumboDetail.vue";
import NewEssentialsDetail from "./views/NewEssentialsDetail.vue";
import EmsanDetail from "./views/EmsanDetail.vue";
import BellaMaisonDetail from "./views/BellaMaisonDetail.vue";
import FlormarDetail from "./views/FlormarDetail.vue";
import RakleDetail from "./views/RakleDetail.vue";
import MarjinDetail from "./views/MarjinDetail.vue";
import ZiyaDetail from "./views/ZiyaDetail.vue";
import AkayevDetail from "./views/AkayevDetail.vue";
import KozmelaDetail from "./views/KozmelaDetail.vue";
import DesaDetail from "./views/DesaDetail.vue";
import SinozDetail from "./views/DesaDetail.vue";
import TrendyolDetail from "./views/TrendyolDetail.vue";

import BankAccountInfo from "./views/BankAccountInfo.vue";
import AccountInfo from "./views/AccountInfo.vue";
import RizaMetni from "./views/RizaMetni.vue";
import TermsOfUse from "./views/TermsOfUse.vue";
import DataPolicy from "./views/DataPolicy.vue";
import PaymentInfo from "./views/PaymentInfo.vue";
import Faq from "./views/Faq.vue";
import LinkTable from "./views/LinkTable.vue";
import LinkTable1 from "./views/LinkTable_v1.vue";
//import HomePage from "./views/HomePage.vue";

Vue.use(Router);
//export default
export const router = new Router({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/linkstatistics",
      components: {
        //sidebarMenu: SideHeader,
        default: LinkStatistics, //AffOffersFirst
      },
    },
    /*{ 
      path: '/home-new', 
      components: {
        header: Header,
        //sidebarMenu: SideHeader,
        default: HomePage, //AffOffersFirst
        footer: Footer
      } 
    },*/
    {
      path: "/bankaccountinfo",
      components: {
        header: Header,
        default: BankAccountInfo,
        footer: Footer,
      },
    },
    {
      path: "/accountinfo",
      components: {
        header: Header,
        default: AccountInfo,
        footer: Footer,
      },
    },
    {
      path: "/iletisim-metni",
      components: {
        header: Header,
        default: RizaMetni,
        footer: Footer,
      },
    },
    {
      path: "/veri-politikasi",
      components: {
        header: Header,
        default: DataPolicy,
        footer: Footer,
      },
    },
    {
      path: "/kullanici-sozlesmesi",
      components: {
        header: Header,
        default: TermsOfUse,
        footer: Footer,
      },
    },
    {
      path: "/signup",
      components: {
        //header: Header,
        //sidebarMenu: SideHeader,
        default: RegisterPagev2,
        footer: Footer,
      },
    },
    {
      path: "/linktable1",
      components: {
        header: Header,
        default: LinkTable1,
        footer: Footer,
      },
    },
    {
      path: "/linktable",
      components: {
        header: Header,
        default: LinkTable,
        footer: Footer,
      },
    },
    {
      path: "/faq",
      components: {
        header: Header,
        default: Faq,
        footer: Footer,
      },
    },
    {
      path: "/payment/payment-info",
      components: {
        header: Header,
        default: PaymentInfo,
        footer: Footer,
      },
    },
    /* Campaign Detail Pages */
    {
      path: "/campaign-detail/trendyol",
      components: {
        header: Header,
        default: TrendyolDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/sinoz",
      components: {
        header: Header,
        default: SinozDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/desa",
      components: {
        header: Header,
        default: DesaDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/kozmela",
      components: {
        header: Header,
        default: KozmelaDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/akayev",
      components: {
        header: Header,
        default: AkayevDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/ziya",
      components: {
        header: Header,
        default: ZiyaDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/marjin",
      components: {
        header: Header,
        default: MarjinDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/rakle",
      components: {
        header: Header,
        default: RakleDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/flormar",
      components: {
        header: Header,
        default: FlormarDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/bellamaison",
      components: {
        header: Header,
        default: BellaMaisonDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/emsan",
      components: {
        header: Header,
        default: EmsanDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/newessentials",
      components: {
        header: Header,
        default: NewEssentialsDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/jumbo",
      components: {
        header: Header,
        default: JumboDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/perabulvari",
      components: {
        header: Header,
        default: PeraBulvariDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/karacahome",
      components: {
        header: Header,
        default: KaracaHomeDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/ciceksepeti",
      components: {
        header: Header,
        default: CicekSepetiDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/englishhome",
      components: {
        header: Header,
        default: EnglishHomeDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/karaca",
      components: {
        header: Header,
        default: KaracaDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/evyapshop",
      components: {
        header: Header,
        default: EvyapShopDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/vivense",
      components: {
        header: Header,
        default: VivenseDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/watsons",
      components: {
        header: Header,
        default: WatsonsDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/dermokozmetika",
      components: {
        header: Header,
        default: DermokozmetikaDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/mooibutik",
      components: {
        header: Header,
        default: MooiButikDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/thebodyshop",
      components: {
        header: Header,
        default: TheBodyShopDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/setre",
      components: {
        header: Header,
        default: SetreDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/bernardo",
      components: {
        header: Header,
        default: BernardoDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/kuaybegider",
      components: {
        header: Header,
        default: KuaybeGiderDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/teknosa",
      components: {
        header: Header,
        default: TeknosaDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/haticeteyze",
      components: {
        header: Header,
        default: HaticeteyzeDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/themia",
      components: {
        header: Header,
        default: theMiaDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/juahome",
      components: {
        header: Header,
        default: JuahomeDetail,
        footer: Footer,
      },
    },
    {
      path: "/campaign-detail/chakra",
      components: {
        header: Header,
        default: ChakraDetail,
        footer: Footer,
      },
    },
    {
      path: "/success-password-reset",
      components: {
        default: SuccessPasswordReset,
        footer: Footer,
      },
    },
    {
      path: "/send-password-reset",
      components: {
        default: SendResetPassword,
        footer: Footer,
      },
    },
    {
      path: "/reset/:id/*",
      components: {
        default: NewPasswordConfirm,
        footer: Footer,
      },
    },
    {
      path: "/begin-password-reset",
      components: {
        default: ForgotPassword,
        footer: Footer,
      },
    },
    {
      path: "/begin-delete-account",
      components: {
        default: BeginDeleteAccount,
        footer: Footer,
      },
    },
    {
      path: "/deleteaccount/:token",
      components: {
        default: DeleteAccountConfirm,
        footer: Footer,
      },
    },
    {
      path: "/send-delete-account",
      components: {
        default: SendDeleteAccount,
        footer: Footer,
      },
    },
    {
      path: "/footer",
      components: {
        //sidebarMenu: SideHeader,
        default: Footer, //AffOffersFirst
      },
    },
    {
      path: "/create-link-container",
      components: {
        //sidebarMenu: SideHeader,
        default: CreateLinkContainer, //AffOffersFirst
      },
    },
    {
      path: "/en",
      components: {
        header: HeaderLandingEng,
        landing: LandingPageEng,
        //sidebarMenu: SideHeader,
      },
    },
    {
      path: "/",
      components: {
        header: HeaderLanding,
        landing: LandingPage,
        //sidebarMenu: SideHeader,
      },
    },
    {
      path: "/business",
      components: {
        header: HeaderLandingBusiness,
        //sidebarMenu: SideHeader,
        default: LandingPageBusiness, //AffOffersFirst
      },
    },
    {
      path: "/home",
      components: {
        header: Header,
        //sidebarMenu: SideHeader,
        default: AffOffersFirstv2, //AffOffersFirst
        footer: Footer,
      },
    },
    {
      path: "/home/dashboard",
      components: {
        header: Header,
        //sidebarMenu: SideHeader,
        default: AffDashboard,
        footer: Footer,
      },
    },
    {
      path: "/home/dashboard/detail",
      components: {
        header: Header,
        //sidebarMenu: SideHeader,
        default: AffDashboardDetails,
        footer: Footer,
      },
    },
    {
      path: "/login",
      components: {
        //header: Header,
        //sidebarMenu: SideHeader,
        default: LoginPage,
        footer: Footer,
      },
    },
    {
      path: "/logout",
      components: {
        //header: Header,
        //sidebarMenu: SideHeader,
        default: LogoutPage,
      },
      name: "logout",
      //redirect: '/login'
    },
    {
      path: "/home/markalar", // CreateLink
      components: {
        header: Header,
        //sidebarMenu: SideHeader,
        default: AffOffersFirstv2,
        footer: Footer,
      },
    },
    {
      path: "/home/markalar/all-categories/:category",
      components: {
        header: Header,
        default: Categories,
        footer: Footer,
      },
    },

    {
      name: "DEMOTRANSACTION",
      path: "/demo_transaction",
      components: {
        header: Header,
        default: DemoTransactionPage,
      },
      props: true,
    },
    {
      name: "DEMOCONVERSION",
      path: "/demo_conversion",
      components: {
        header: Header,
        default: DemoConversionPage,
      },
      props: true,
    },

    // otherwise redirect to home
    { path: "*", redirect: "/home" },
  ],
});

/*router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/vendors/login', '/vendors/signup'];
  const authRequired = !publicPages.includes(to.path);
  //const loggedIn = localStorage.getItem('user');
  if (authRequired){
    next('/vendors/login');
}
  axios({ method: "GET", "url": "https://ulan-gitlab-heroku-env-staging.herokuapp.com/vendors/current", "headers": { "content-type": "application/json" } })
  .then(function (response) {
    if (authRequired && response.status === 204) {
      return next('/vendors/login')
    }
    
  });
 
  return next();
})*/
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/en",
    "/login",
    "/signup",
    "/",
    "/business",
    "/begin-password-reset",
    "/reset",
    "/send-password-reset",
    "/success-password-reset",
    "/faq",
    "/iletisim-metni",
    "/kullanici-sozlesmesi",
    "/veri-politikasi",
    "/send-delete-account",
    "/begin-delete-account",
  ];
  const authRequired = !publicPages.includes(to.path);
  var loggedIn = localStorage.getItem("user");
  const isResetPassword =
    to.path.includes("/reset/") || to.path.includes("/deleteaccount/");
  if (loggedIn) {
    loggedIn = JSON.parse(localStorage.getItem("user")).eligible;
  }

  if (authRequired && !loggedIn && !isResetPassword) {
    return next("/login");
  } else if (
    (to.path === "/login" ||
      to.path === "/signup" ||
      to.path === "/" ||
      to.path === "/business") &&
    loggedIn
  ) {
    //const iseligible = JSON.parse(localStorage.getItem('user')).eligible
    /* if (iseligible){
      return next('/home/markalar');
    }
    else{
      return next('/login');
    }*/
    return next("/home/markalar");
  }

  next();
});

//export default router;
