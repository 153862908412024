<template>
<!-- Footer -->
	<footer class="footer">
        <hr>
		<div class="container ">
			<div class="row text-left">
				<div class="col-xs-12 col-sm-12 col-md-9 offset-md-3 mt-4 mt-lg-4">
					<ul class="list-group list-group-horizontal">
						<li class="list-group-item" style='background: transparent; border:none'><a href="/faq">Sıkça Sorulan Sorular</a></li>
						<li class="list-group-item" style='background: transparent; border:none'><a href="/veri-politikasi">Gizlilik Politikası</a></li>
						<li class="list-group-item" style='background: transparent; border:none'><a href="/kullanici-sozlesmesi">Kullanım Koşulları</a></li>
                    </ul>
				</div>
			</div>

			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 mt-3">
					<ul class="list-unstyled list-inline social text-center">
						<li class="list-inline-item"><a target="_blank" href="javascript:void();"><i class="fa fa-facebook"></i></a></li>
						<li class="list-inline-item"><a target="_blank" href="https://twitter.com/LinkadooMe"><i class="fa fa-twitter"></i></a></li>
						<li class="list-inline-item"><a target="_blank" href="https://www.instagram.com/linkadoo_tr/"><i class="fa fa-instagram"></i></a></li>
						<!--<li class="list-inline-item"><a href="javascript:void();" target="_blank"><i class="fa fa-envelope"></i></a></li>-->
					</ul>
				</div>
				<hr>
			</div>	
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center">
					<p class="h6">Tüm hakları saklıdır. © 2020 </p>
				</div>
				<hr>
			</div>	
		</div>
	</footer>
	<!-- ./Footer -->

</template>

<script>
export default {
    name: "Footer",
    components: {

    }
    
}
</script>

<style scoped>
.list-group-item{
    text-decoration: underline;
}

.footer {
    background: trasparent; /* rgb(7, 164, 175) !important;*/
}

.footer a {
    color: rgb(7, 164, 175); /*#ffffff;*/
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.footer ul.social li{
	padding: 3px 0;
}

.footer ul.social li a i {
    margin-right: 5px;
	font-size:25px;
	-webkit-transition: .5s all ease;
	-moz-transition: .5s all ease;
	transition: .5s all ease;
}

.footer ul.social li:hover a i {
	font-size:30px;
	margin-top:-10px;
}

.footer ul.social li a{
	color: rgb(7, 164, 175); /*ffffff;*/
}

.footer ul.social li a:hover{
	color: rgb(7, 164, 175); /*#eeeeee;*/
}

@media (max-width: 767px) {
    .footer{
        margin-left: 0px;
    }
}

</style>