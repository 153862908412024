import { render, staticRenderFns } from "./TrendyolDetail.vue?vue&type=template&id=0669c705&scoped=true&"
import script from "./TrendyolDetail.vue?vue&type=script&lang=js&"
export * from "./TrendyolDetail.vue?vue&type=script&lang=js&"
import style0 from "./TrendyolDetail.vue?vue&type=style&index=0&id=0669c705&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0669c705",
  null
  
)

export default component.exports