<template>
  <header class="header">
    <div class="col-12 header_container"> <!--col-md-8  offset-md-2-->
    <nav class="navbar navbar-expand-lg bg-light navbar-transparent">
    <a href="/home" class="navbar-brand"><img src="../../assets/linkadoo_logo_trans.png"/></a>
    <button class="navbar-toggler" type="button" style="padding-right: 0px; padding-bottom: 0px;" data-toggle="collapse" data-target="#navbar7" @click="openNav()">
        <span class="navbar-toggler-icon" style="padding-top: 8px;"><i class="fas fa-bars"></i></span>
    </button>
    <div id="mySidenav" class="sidenav">
    
    <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
    
    <router-link to="/home/markalar" class="nav-link" @click.native="closeNav()">Anasayfa</router-link>
    <hr style="margin-top: 0px; margin-bottom: 0px; ">
    <!--<router-link to="/home/markalar/all-categories/a" class="nav-link" @click.native="closeNav()">
      Tüm Kategoriler
    </router-link>

    <router-link to="/home/markalar/all-categories/Moda" class="nav-link side-nav-link" @click.native="closeNav()">Moda</router-link>
    <router-link to="/home/markalar/all-categories/Kozmetik&KisiselBakim" class="nav-link side-nav-link" @click.native="closeNav()">Kozmetik & Kişisel Bakım</router-link>-->
   <!--<router-link to="/home/markalar/all-categories/Seyahat" class="nav-link side-nav-link" @click.native="closeNav()">Seyahat</router-link>-->
    <!--<router-link to="/home/markalar/all-categories/Elektronik" class="nav-link side-nav-link" @click.native="closeNav()">Elektronik</router-link>
    <router-link to="/home/markalar/all-categories/Ev&Yasam" class="nav-link side-nav-link" @click.native="closeNav()">Ev & Yaşam</router-link>
    <router-link to="/home/markalar/all-categories/Yemek&Supermarket" class="nav-link side-nav-link" @click.native="closeNav()">Yemek & Süpermarket</router-link>
    <hr style="margin-top: 0px; margin-bottom: 0px; ">-->
    <router-link to="/home/dashboard" v-if="account.user" class="nav-link" @click.native="closeNav()">Cüzdanım</router-link>
    <router-link to="/home/dashboard" v-if="account.user" class="nav-link" @click.native="scrollToPoint(); closeNav()">Performans Bilgilerim
    <img src="https://firebasestorage.googleapis.com/v0/b/ulan-633c0.appspot.com/o/Other%2Fyeni%20badge%20v2.png?alt=media&token=b060e461-9c6c-4734-b1bc-bbef7df5cd47" />
    </router-link>
    <router-link to="/accountinfo" v-if="account.user" class="nav-link" @click.native="closeNav()">Hesap Ayarlarım</router-link>
    <router-link to="/bankaccountinfo" v-if="account.user" class="nav-link" @click.native="closeNav()">Banka Hesap Bilgilerim</router-link>
    <router-link to="/logout" v-if="account.user" class="dropdown-item" @click.native="closeNav()">Çıkış Yap</router-link>
    <!--<a class="dropdown-item" href="#">Something else here</a>-->
    <router-link to="/login" v-else class="dropdown-item" @click.native="closeNav()">Giriş Yap</router-link> 
    </div>

    <div class="navbar-collapse collapse justify-content-end d-none d-lg-block" id="navbar7">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" v-if="account.user" href="/home"><i class="fas fa-home"></i></a>
            </li>
            <li class="nav-item">
                <router-link to="/home/markalar" v-if="account.user" class="nav-link"><i class="fas fa-tags"></i></router-link>
            </li>
            <li class="nav-item">
                <router-link to="/home/dashboard" v-if="account.user" class="nav-link"><i class="fas fa-lira-sign"></i></router-link>
                <!--<a class="nav-link" href="/">Cüzdanım</a>-->
            </li>
            <li class="nav-item btn-rotate dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="far fa-user-circle "></i>
            </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <!--<a class="dropdown-item" href="#">Hesap Ayarları</a>-->
                    <!--<a class="dropdown-item" href="#">Another action</a>-->
                    <router-link to="/accountinfo" v-if="account.user" class="dropdown-item">Hesap Ayarlarım</router-link>
                    <!--<router-link to="/bankaccountinfo" v-if="account.user" class="dropdown-item">Banka Hesap Bilgilerim</router-link>-->
                    <router-link to="/logout" v-if="account.user" class="dropdown-item">Çıkış Yap</router-link>
                    <!--<a class="dropdown-item" href="#">Something else here</a>-->
                    <router-link to="/login" v-else class="dropdown-item">Giriş Yap</router-link> 
                </div>
            </li>
        </ul>
    </div>
    
</nav>
    </div>
</header>
</template>
 
<script>
import { mapState} from 'vuex'

export default {
  computed: {
        ...mapState({
            account: state => state.account,
        })
    },

  name: "Header",
  methods: {
    openNav() {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("app").style.marginLeft = "250px";
      document.getElementById("app").style.marginRight = "-250px";
      
      var main = document.getElementsByTagName("main")[0];
      main.setAttribute("class", "disable-main");
      
      var body = document.getElementsByTagName("body")[0];
      body.setAttribute("class", "disable-body"); 

      var html = document.getElementsByTagName("html")[0];
      html.setAttribute("class", "disable-html");   
    },
    closeNav() {
      var noError = true;
      try{
        document.getElementById("mySidenav").style.width = "0";
      }
      catch(err){
        noError = true;
      }
      if(noError){
        document.getElementById("app").style.marginLeft= "0";
        document.getElementById("app").style.marginRight = "0px";

        var main = document.getElementsByTagName("main")[0];
        main.setAttribute("class", "");  

        var body = document.getElementsByTagName("body")[0];
        body.setAttribute("class", ""); 

        var html = document.getElementsByTagName("html")[0];
        html.setAttribute("class", "");  
      }
    },
    scrollToPoint() {
                window.scrollTo(0,900);
           }
  },
  mounted() {

  },
  
  
}
</script>

<style>


header{
    margin-left: -15px;
    margin-right: 0px;
    background: white; /*linear-gradient(225deg, #E64769 0%,#1e578d98 37.53%,rgb(7, 164, 175) 100%);*/
    /*z-index: 100;*/
    /*box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.55);*/
}

.header_container{
    padding-left: 15%;
    padding-right: 15%;
}

li a {
    color: black;
    position:relative;   
}

li > a > i{
    font-size: 20px;
}

.navbar-brand img{
    color: rgb(7, 164, 175);
    width: 160px; height: 32px;
}
.nav-link{
    color: rgb(7, 164, 175);
    border-bottom: 2px solid transparent;
}

.nav-link:active, .nav-link:hover, .nav-link.active:focus{
    border-bottom: 2px solid #E64769; /*rgb(7, 164, 175);*/
    color: #E64769; /*rgb(7, 164, 175);*/
}

.navbar-toggler-icon{
  /*background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='https://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(7, 164, 175)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
*/
  
}

.navbar.navbar-transparent {
  min-height: 40px;
  background-color: transparent !important;
  box-shadow: none;
  /*border-bottom: 1px solid #ddd;*/
  /*box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15)*/
}

.navbar-nav .dropdown-menu:before,
.dropdown .dropdown-menu[x-placement="bottom-start"]:before,
.dropdown .dropdown-menu[x-placement="bottom-end"]:before,
.dropdown-btn .dropdown-menu:before{
  border-bottom: 11px solid #F1EAE0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  top: -12px;
}

.navbar-nav .dropdown-menu:after,
.dropdown .dropdown-menu[x-placement="bottom-start"]:after,
.dropdown .dropdown-menu[x-placement="bottom-end"]:after,
.dropdown-btn .dropdown-menu:after {
  border-bottom: 11px solid #FFFFFF;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  top: -11px;
}

.dropdown-menu {
    background-color: 
    #FFFFFF;
    border: 0 none;
    border-radius: 12px;
    margin-top: 10px;
    padding: 0px;
}

.dropdown .dropdown-menu, .dropup .dropdown-menu, .bootstrap-select .dropdown-menu {
    display: block;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-box-shadow: 1px 2px 7px 1px rgba(0, 0, 0, 0.125);
    box-shadow: 1px 2px 7px 1px 
    rgba(0, 0, 0, 0.125);
    visibility: hidden;
    position: absolute;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
}

.dropdown-toggle:after {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}

.dropdown-toggle[aria-expanded="true"]:after {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dropup .dropdown-toggle:after,
.dropdown .dropdown-toggle:after {
  margin-left: 0;
}

.dropdown-menu-right {
  right: -2px;
  left: auto;
}


.dropdown.show .dropdown-menu,
.dropup.show .dropdown-menu {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}

.dropup.show .dropdown-menu,
.dropdown-btn.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown.show .dropdown-menu[x-placement="top-start"],
.dropup.show .dropdown-menu[x-placement="top-start"] {
  -webkit-transform: translate3d(-20px, -50px, 0) !important;
  -moz-transform: translate3d(-20px, -50px, 0) !important;
  -o-transform: translate3d(-20px, -50px, 0) !important;
  -ms-transform: translate3d(-20px, -50px, 0) !important;
  transform: translate3d(-20px, -50px, 0) !important;
  top: auto !important;
  bottom: 0 !important;
}

.dropdown.show .dropdown-menu[x-placement="bottom-start"],
.dropup.show .dropdown-menu[x-placement="bottom-start"] {
  -webkit-transform: translate3d(-20px, 50px, 0) !important;
  -moz-transform: translate3d(-20px, 50px, 0) !important;
  -o-transform: translate3d(-20px, 50px, 0) !important;
  -ms-transform: translate3d(-20px, 50px, 0) !important;
  transform: translate3d(-20px, 50px, 0) !important;
  bottom: auto !important;
  top: 0 !important;
}

.dropup.show .dropdown-menu .dropdown-menu.inner,
.dropdown-btn.show .dropdown-menu .dropdown-menu.inner {
  -webkit-transform: translate3d(0, 0px, 0) !important;
  -moz-transform: translate3d(0, 0px, 0) !important;
  -o-transform: translate3d(0, 0px, 0) !important;
  -ms-transform: translate3d(0, 0px, 0) !important;
  transform: translate3d(0, 0px, 0) !important;
}

.dropdown-menu.show[x-placement="top-start"] {
  -webkit-transform: translate3d(-20px, -60px, 0) !important;
  -moz-transform: translate3d(-20px, -60px, 0) !important;
  -o-transform: translate3d(-20px, -60px, 0) !important;
  -ms-transform: translate3d(-20px, -60px, 0) !important;
  transform: translate3d(-20px, -60px, 0) !important;
  top: auto !important;
  bottom: 0 !important;
}

.dropdown-menu.show li:last-child a:hover {
  border-radius: 0 0 12px 12px;
}

.navbar-toggler{
  color: rgb(7, 164, 175);
}

.side-nav-link{
  line-height: 1 !important;
  padding-left: 50px !important;
  font-size: 100% !important;
}
@media screen and (max-width: 860px) {
.header_container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header_container{
    /*padding-left: 40vw;*/
  }

  .navbar-brand img{
    width: 120px;
    height: 24px;
  }
}


@media screen and (max-width: 991px) {
.navbar .dropdown.show .dropdown-menu {
    display: block;
  }
}

@media screen and (min-width: 768px) {
.navbar-nav .dropdown-item .dropdown-menu,
  .dropdown .dropdown-menu,
  .dropdown-btn .dropdown-menu {
    transform: translate3d(0px, -40px, 0px);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
  }

  .navbar-nav .dropdown-item.show .dropdown-menu,
  .dropdown.show .dropdown-menu,
  .dropdown-btn.show .dropdown-menu {
    transform: translate3d(0px, 0px, 0px);
    visibility: visible !important;
  }
}

.sidenav {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white; /*rgb(253, 196, 44);*/
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: rgb(7, 164, 175); /*#818181;*/
  display: block;
  transition: 0.2s;

}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}


@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
</style>
